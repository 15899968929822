import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslate } from 'react-admin';

const PREFIX = 'FileInput';

const classes = {
  billsDropzone: `${PREFIX}-billsDropzone`,
  activeBillsDropzone: `${PREFIX}-activeBillsDropzone`,
  dropzoneHeadline: `${PREFIX}-dropzoneHeadline`,
  uploadedFiles: `${PREFIX}-uploadedFiles`,
  uploadedFileParagraph: `${PREFIX}-uploadedFileParagraph`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const Root = styled('div')({
  [`& .${classes.billsDropzone}`]: {
    padding: '20px',
    border: '2px dashed #e5e5e5',
    textAlign: 'center',
    marginBottom: '15px',
    minWidth: '350px',
    cursor: 'pointer',
  },
  [`& .${classes.activeBillsDropzone}`]: {
    borderColor: '#5b91f7',
  },
  [`& .${classes.dropzoneHeadline}`]: {
    color: '#b1b1b1',
  },
  [`& .${classes.uploadedFiles}`]: {
    color: '#b1b1b1',
    textAlign: 'right',
    fontSize: '14px',
  },
  [`& .${classes.uploadedFileParagraph}`]: {
    marginBottom: '10px',
  },
  [`& .${classes.deleteIcon}`]: {
    cursor: 'pointer',
  },
});

export type UploadedFile = {
  id: number;
  name: string;
};

const FileInput = ({
  onDrop,
  onDelete,
  files = [],
  dropLabel,
  uploadedLabel,
  isLoading = false,
  multiple = true,
}: {
  onDrop: (acceptedFiles: File[]) => void;
  onDelete: (fileToDelete: UploadedFile) => void;
  files?: UploadedFile[];
  dropLabel?: string;
  uploadedLabel?: string;
  isLoading?: boolean;
  multiple?: boolean;
}) => {
  const t = useTranslate();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
  });

  const dropLabelToShow =
    dropLabel ||
    (multiple
      ? t('ra.input.file.upload_several')
      : t('ra.input.file.upload_single'));
  const uploadedLabelToShow =
    uploadedLabel ||
    t('ra.input.file.upload_count', { fileCount: files.length });

  return (
    <Root>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps({
          className: classNames({
            [classes.billsDropzone]: true,
            [classes.activeBillsDropzone]: isDragActive || isLoading,
          }),
        })}
        data-testid="dropzone-container"
      >
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps()}
        />
        <div className={classes.dropzoneHeadline}>
          {isLoading ? <CircularProgress size={25} /> : dropLabelToShow}
        </div>
      </div>
      <div className={classes.uploadedFiles}>
        <p className={classes.uploadedFileParagraph}>
          {`${uploadedLabelToShow}`}
        </p>
        {files.map((file) => (
          <p className={classes.uploadedFileParagraph} key={file.id}>
            {`${file.name} `}
            <DeleteForeverIcon
              data-testid="uploaded-file-delete-icon"
              className={classes.deleteIcon}
              onClick={() => onDelete(file)}
            />
          </p>
        ))}
      </div>
    </Root>
  );
};

export default FileInput;
