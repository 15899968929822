import { Integration, IntegrationType } from '../enums';
import { IntegrationRecord } from '../types';

const integrations: IntegrationRecord[] = [
  {
    id: Integration.Hotelkit,
    logo: '/images/hotelkit.png',
    systemName: 'Hotelkit',
    vendor: 'Hotelkit',
    website: 'https://hotelkit.net',
    integrationType: IntegrationType.SRS,
  },
  {
    id: Integration.Apaleo,
    logo: '/images/apaleo.png',
    systemName: 'Apaleo Open PMS',
    vendor: 'Apaleo',
    website: 'https://apaleo.com/open-pms',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Hotsoft,
    logo: '/images/hotsoft.png',
    systemName: 'HotSoft PMS',
    vendor: 'Hoist Group',
    website:
      'https://www.hoistgroup.com/products/property-management/hotsoft-pms',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Stayntouch,
    logo: '/images/stayntouch.png',
    systemName: 'Stayntouch Hotel PMS',
    vendor: 'StayNTouch',
    website: 'https://www.stayntouch.com/cloud-pms',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.SimphonyGen1,
    logo: '/images/oracle.png',
    systemName: 'Simphony (Generation 1) BETA',
    vendor: 'Oracle',
    website:
      'https://www.oracle.com/industries/food-beverage/restaurant-pos-systems/simphony-pos',
    integrationType: IntegrationType.POS,
  },
  {
    id: Integration.Suite8,
    logo: '/images/oracle_suite8.png',
    systemName: 'Oracle Hospitality Suite 8',
    vendor: 'Oracle',
    website:
      'https://www.oracle.com/industries/hospitality/products/suite8-property.html',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Mews,
    logo: '/images/mews.png',
    systemName: 'Mews',
    vendor: 'Mews',
    website: 'https://www.mews.com/en',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Infor,
    logo: '/images/infor.png',
    systemName: 'Infor HMS',
    vendor: 'Infor',
    website: 'https://www.infor.com/products/hms',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Sihot,
    logo: '/images/sihot.png',
    systemName: 'SIHOT.PMS',
    vendor: 'SIHOT',
    website: 'https://sihot.com/en/products/sihot-pms',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Protel,
    logo: '/images/protel.png',
    systemName: 'protel.I/O',
    vendor: 'Protel',
    website: 'https://www.protel.io',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Guestline,
    logo: '/images/guestline.png',
    systemName: 'Guestline',
    vendor: 'Guestline',
    website:
      'https://www.guestline.com/products/property-management/property-management-system',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Fias,
    logo: '/images/oracle.png',
    systemName: 'FIAS',
    vendor: 'Oracle',
    website:
      'https://docs.oracle.com/cd/E94145_01/docs/Oracle%20Hospitality-IFC8-FIAS%20Interface%20Specs%202.20.23.pdf',
    integrationType: IntegrationType.PMS,
  },
  {
    id: Integration.Asa,
    logo: '/images/asa.png',
    systemName: 'ASA Hotel',
    vendor: 'ASA',
    website: 'https://www.asahotel.com/en/software/asa-hotel-software',
    integrationType: IntegrationType.PMS,
  },
];

export default integrations;
