import { styled } from '@mui/material/styles';
import { TabContext, TabList } from '@mui/lab';
import { ChangeEvent, memo, ReactNode, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  CategoryRecord,
  CurrencyRecord,
  HotelRecord,
  Timeframe,
} from '../../common/types';
import { useKeenQuery } from '../../utils/keen';
import ChartContainer from './ChartContainer';
import { useIntl } from '../../context/intlContext';
import ThreeDotsProgress from './ThreeDotsProgress';
import OrderTotalsChartRevenue from './OrderTotalsChart/OrderTotalsChartRevenue';
import OrderTotalsChartOrders from './OrderTotalsChart/OrderTotalsChartOrders';
import ChartMainTabPanel from './shared/ChartMainTabPanel';
import ChartTooltip from './shared/ChartTooltip';
import ChartMainTab from './shared/ChartMainTab';

const StyledTabList = styled(TabList)({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-evenly',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const TabLabelContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
});

const TabLabelTitle = styled('div')({
  flex: '0 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '20px',
  whiteSpace: 'nowrap',
});

const TabLabelValue = styled('div')({
  flex: '0 0 auto',
  marginLeft: '12px',
  fontSize: '32px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

const TabLabel = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string | ReactNode;
}) => (
  <ChartTooltip title={title}>
    <TabLabelContainer data-testid="tab-label">
      <TabLabelTitle>{title}</TabLabelTitle>
      <TabLabelValue>{subtitle}</TabLabelValue>
    </TabLabelContainer>
  </ChartTooltip>
);

const OrderTotalsChart = ({
  timeframe,
  hotel,
  categories = [],
  currency,
}: {
  timeframe: Timeframe;
  hotel: HotelRecord;
  categories: CategoryRecord[];
  currency: CurrencyRecord;
}) => {
  const t = useTranslate();
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState('1');

  const sortedCategories = categories.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const [
    { data: totalRevenue, loading: isTotalRevenueLoading },
    queryTotalRevenue,
  ] = useKeenQuery<number>();
  const [
    { data: totalOrders, loading: isTotalOrdersLoading },
    queryTotalOrders,
  ] = useKeenQuery<number>();

  useEffect(() => {
    queryTotalRevenue({
      analysisType: 'sum',
      eventCollection: 'orders',
      targetProperty: 'total',
      filters: [
        {
          propertyName: 'hotel_id',
          operator: 'eq',
          propertyValue: hotel.id,
        },
        {
          property_name: 'test_order',
          operator: 'eq',
          propertyValue: false,
        },
      ],
      timeframe,
    });
  }, [hotel.id, queryTotalRevenue, timeframe]);
  useEffect(() => {
    queryTotalOrders({
      analysisType: 'count',
      eventCollection: 'orders',
      filters: [
        {
          propertyName: 'hotel_id',
          operator: 'eq',
          propertyValue: hotel.id,
        },
        {
          property_name: 'test_order',
          operator: 'eq',
          propertyValue: false,
        },
      ],
      timeframe,
    });
  }, [hotel.id, queryTotalOrders, timeframe]);

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <ChartContainer>
      <TabContext value={currentTab}>
        <StyledTabList onChange={handleChange} centered>
          <ChartMainTab
            value="1"
            label={
              <TabLabel
                title={t('charts.order.revenue.title')}
                subtitle={
                  isTotalRevenueLoading ? (
                    <ThreeDotsProgress />
                  ) : (
                    intl.formatNumber(totalRevenue!, {
                      style: 'currency',
                      currency: currency.code,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  )
                }
              />
            }
          />
          <ChartMainTab
            value="2"
            label={
              <TabLabel
                title={t('charts.order.orders.title')}
                subtitle={
                  isTotalOrdersLoading ? (
                    <ThreeDotsProgress />
                  ) : (
                    intl.formatNumber(totalOrders!)
                  )
                }
              />
            }
          />
        </StyledTabList>
        <ChartMainTabPanel value="1">
          <OrderTotalsChartRevenue
            categories={sortedCategories}
            hotel={hotel}
            currency={currency}
            timeframe={timeframe}
          />
        </ChartMainTabPanel>
        <ChartMainTabPanel value="2">
          <OrderTotalsChartOrders
            categories={sortedCategories}
            hotel={hotel}
            timeframe={timeframe}
          />
        </ChartMainTabPanel>
      </TabContext>
    </ChartContainer>
  );
};

export default memo(OrderTotalsChart);
