import { TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';

const ChartMainTabList = styled(TabList)({
  '&.MuiTabs-root': {
    minHeight: '68px',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export default ChartMainTabList;
