import {
  Edit,
  Loading,
  useDataProvider,
  Identifier,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useCallback, useState, useEffect } from 'react';
import { ItemRecordLocalized, PriceRecord } from '../../common/types';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import ItemViewToolbar from './ItemViewToolbar';
import ItemCustomLayout from './ItemCustomLayout';
import { useGlobalContext } from '../../context/globalContext';
import { IframeResizerParentMessage } from '../../common/enums';
import { sendMessageToParent } from '../../utils/iframe-resizer';

// eslint-disable-next-line react/require-default-props
const ItemTitle = () => {
  const record: ItemRecordLocalized = useRecordContext();
  return <span>Item {record ? `"${record.id}"` : ''}</span>;
};

const ItemEditForm = (props: any) => {
  const record: ItemRecordLocalized = useRecordContext();
  const notify = useNotify();

  const dataProvider = useDataProvider();

  const [pricesLoading, setPricesLoading] = useState(false);
  const [initialPrices, setInitialPrices] = useState<PriceRecord[]>([]);
  const [prices, setPrices] = useState<Partial<PriceRecord>[]>([]);

  const [{ locale }] = useGlobalContext();
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [locale, refresh]);

  useEffect(() => {
    const fetchPrices = async (ids: number[]) => {
      try {
        setPricesLoading(true);

        const {
          data: priceRecords,
        }: { data: PriceRecord[] } = await dataProvider.getMany('prices', {
          ids,
        });
        setPrices(priceRecords);
        setInitialPrices(priceRecords);
      } catch {
        notify('ra.notification.http_error', { type: 'warning' });
      } finally {
        setPricesLoading(false);
      }
    };

    if (record && record.priceIds.length) {
      fetchPrices(record.priceIds);
    }
  }, [dataProvider, notify, record]);

  const afterSave = useCallback(
    async (item: ItemRecordLocalized) => {
      const pricesToCreate = prices.filter(({ id }) => !id);
      const pricesToUpdate = prices.filter(
        (price) => !pricesToCreate.includes(price)
      );
      const pricesToDelete = initialPrices.filter(
        (initialPrice) => !prices.find(({ id }) => id === initialPrice.id)
      );

      const result = await Promise.all([
        ...pricesToCreate.map((price) =>
          dataProvider.create('prices', {
            data: { ...price, itemId: item.id },
          })
        ),
        ...pricesToUpdate.map((price) =>
          dataProvider.update('prices', {
            id: (price as PriceRecord).id,
            data: { ...price },
            previousData: initialPrices.find(
              ({ id }) => id === price.id
            ) as PriceRecord,
          })
        ),
        ...pricesToDelete.map((price) =>
          dataProvider.delete('prices', {
            id: price.id as Identifier,
            previousData: price,
          })
        ),
      ]);

      sendMessageToParent(IframeResizerParentMessage.ItemEditSuccess);

      return result;
    },
    [prices, dataProvider, initialPrices]
  );

  return (
    <SimpleFormWithServerSideFieldValidation
      toolbar={
        <ItemViewToolbar
          message={IframeResizerParentMessage.ItemEditCancel}
          alwaysEnableSaveButton
        />
      }
      type="edit"
      afterSave={afterSave}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <ItemCustomLayout
        pageId={record.pageIds?.[0]}
        pricesLoading={pricesLoading}
        prices={prices}
        setPrices={setPrices}
      />
    </SimpleFormWithServerSideFieldValidation>
  );
};

const ItemEdit = (props: any) => {
  const [{ hotel }] = useGlobalContext();

  return hotel ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Edit title={<ItemTitle />} {...props}>
      <ItemEditForm hotel={hotel} />
    </Edit>
  ) : (
    <Loading />
  );
};

export default ItemEdit;
