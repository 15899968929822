import {
  BooleanInput,
  Edit,
  required,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { AppRecord } from '../../common/types';
import EditViewToolbar from '../EditViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';

// eslint-disable-next-line react/require-default-props
const AppTitle = () => {
  const record: AppRecord = useRecordContext();
  return <span>App {record ? `"${record.name}"` : ''}</span>;
};

const AppEdit = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Edit title={<AppTitle />} {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<EditViewToolbar />}
      type="edit"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput
        source="packageName"
        variant="outlined"
        validate={required()}
      />
      <BooleanInput source="cacheEnabled" />
      <BooleanInput source="mandatory" />
      <TextInput
        source="postInstallationScript"
        variant="outlined"
        rows={3}
        multiline
        fullWidth
      />
    </SimpleFormWithServerSideFieldValidation>
  </Edit>
);

export default AppEdit;
