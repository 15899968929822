import { Box } from '@mui/material';
import {
  InputProps,
  regex,
  required,
  SimpleFormIterator,
  TextInput,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';
import StyledArrayInput from './StyledArrayInput';
import StyledBooleanInput from './StyledBooleanInput';

const MewsInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiBaseUrl"
        label="resources.integrations.fields.mews.apiBaseUrl.label"
        helperText="resources.integrations.fields.mews.apiBaseUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.mews.apiBaseUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.clientToken"
        label="resources.integrations.fields.mews.clientToken.label"
        helperText="resources.integrations.fields.mews.clientToken.helperText"
        validate={required(
          'resources.integrations.fields.mews.clientToken.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.accessToken"
        label="resources.integrations.fields.mews.accessToken.label"
        helperText="resources.integrations.fields.mews.accessToken.helperText"
        validate={required(
          'resources.integrations.fields.mews.accessToken.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.languageTag"
        label="resources.integrations.fields.mews.languageTag.label"
        helperText="resources.integrations.fields.mews.languageTag.helperText"
        validate={[
          required('resources.integrations.fields.mews.languageTag.helperText'),
          regex(
            /[a-z][a-z]-[A-Z][A-Z]/,
            'resources.integrations.fields.mews.languageTag.helperText'
          ),
        ]}
        variant="outlined"
        fullWidth
      />
      <StyledArrayInput
        source="currentConfig.service.reservationServiceIds"
        label="resources.integrations.fields.mews.reservationServiceIds.label"
        helperText="resources.integrations.fields.mews.reservationServiceIds.helperText"
        variant="outlined"
        validate={required(
          'resources.integrations.fields.mews.reservationServiceIds.helperText'
        )}
        fullWidth
      >
        <SimpleFormIterator disableReordering>
          <TextInput
            source=""
            label="resources.integrations.fields.mews.reservationServiceId.label"
            helperText="resources.integrations.fields.mews.reservationServiceId.helperText"
            variant="outlined"
            fullWidth
          />
        </SimpleFormIterator>
      </StyledArrayInput>
      <StyledBooleanInput
        source="currentConfig.service.useGrossAmount"
        label="resources.integrations.fields.mews.useGrossAmount.label"
        helperText="resources.integrations.fields.mews.useGrossAmount.helperText"
        variant="outlined"
      />
      <TextInput
        source="currentConfig.service.enterpriseId"
        label="resources.integrations.fields.mews.enterpriseId.label"
        helperText="resources.integrations.fields.mews.enterpriseId.helperText"
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default MewsInputs;
