import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';
import StyledBooleanInput from './StyledBooleanInput';

const ProtelInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiEndpoint"
        label="resources.integrations.fields.protel.apiEndpoint.label"
        helperText="resources.integrations.fields.protel.apiEndpoint.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.protel.apiEndpoint.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.partnerId"
        label="resources.integrations.fields.protel.partnerId.label"
        helperText="resources.integrations.fields.protel.partnerId.helperText"
        validate={required(
          'resources.integrations.fields.protel.partnerId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.token"
        label="resources.integrations.fields.protel.token.label"
        helperText="resources.integrations.fields.protel.token.helperText"
        validate={required(
          'resources.integrations.fields.protel.token.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <StyledBooleanInput
        source="currentConfig.service.includeForecastedCharges"
        label="resources.integrations.fields.protel.includeForecastedCharges.label"
        helperText="resources.integrations.fields.protel.includeForecastedCharges.helperText"
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default ProtelInputs;
