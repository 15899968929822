import { useWatch } from 'react-hook-form';
import { InputProps } from 'react-admin';
import HotelkitInputs from './integration-inputs/HotelkitInputs';
import ApaleoInputs from './integration-inputs/ApaleoInputs';
import HotsoftInputs from './integration-inputs/HotsoftInputs';
import StayntouchInputs from './integration-inputs/StayntouchInputs';
import SimphonyGen1Inputs from './integration-inputs/SimphonyGen1Inputs';
import Suite8Inputs from './integration-inputs/Suite8Inputs';
import MewsInputs from './integration-inputs/MewsInputs';
import InforInputs from './integration-inputs/InforInputs';
import SihotInputs from './integration-inputs/SihotInputs';
import ProtelInputs from './integration-inputs/ProtelInputs';
import GuestlineInputs from './integration-inputs/GuestlineInputs';
import FiasInputs from './integration-inputs/FiasInputs';
import AsaInputs from './integration-inputs/AsaInputs';
import { Integration } from '../../common/enums';
import { AVAILABLE_INTEGRATIONS } from '../../common/constants';

const IntegrationToComponentMap = {
  [Integration.Hotelkit]: HotelkitInputs,
  [Integration.Apaleo]: ApaleoInputs,
  [Integration.Hotsoft]: HotsoftInputs,
  [Integration.Stayntouch]: StayntouchInputs,
  [Integration.SimphonyGen1]: SimphonyGen1Inputs,
  [Integration.Suite8]: Suite8Inputs,
  [Integration.Mews]: MewsInputs,
  [Integration.Infor]: InforInputs,
  [Integration.Sihot]: SihotInputs,
  [Integration.Protel]: ProtelInputs,
  [Integration.Guestline]: GuestlineInputs,
  [Integration.Fias]: FiasInputs,
  [Integration.Asa]: AsaInputs,
};

const ServiceSpecificInputs = (props: Omit<InputProps, 'source'>) => {
  const serviceName: Integration = useWatch({
    name: 'serviceName',
  });
  const integration = AVAILABLE_INTEGRATIONS.find(
    ({ id }) => id === serviceName
  );

  if (
    !serviceName ||
    !(serviceName in IntegrationToComponentMap) ||
    !integration
  ) {
    return <></>;
  }

  const IntegrationInputsComponent = IntegrationToComponentMap[serviceName];

  return (
    <IntegrationInputsComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      integration={integration}
    />
  );
};

export default ServiceSpecificInputs;
