import {
  BooleanInput,
  Create,
  FormDataConsumer,
  Loading,
  required,
  TextInput,
  useTranslate,
} from 'react-admin';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import CreateViewToolbar from '../CreateViewToolbar';
import CategoryConfirmationMethodInputs from './CategoryConfirmationMethodInputs';
import { useGlobalContext } from '../../context/globalContext';
import Typography from '../../components/Typography';

const CategoryCreate = (props: any) => {
  const t = useTranslate();
  const [{ hotel }] = useGlobalContext();

  if (!hotel) return <Loading />;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Create {...props}>
      <SimpleFormWithServerSideFieldValidation
        toolbar={<CreateViewToolbar />}
        type="create"
        defaultValues={{
          confirmationMethods: 'call_to_room,sms,email,push',
          enableAsap: true,
          datetimeSelectable: true,
          hotelId: hotel.id,
        }}
      >
        <Typography variant="h5" gutterBottom underline>
          {t('resources.categories.sections.general')}
        </Typography>
        <TextInput source="title" variant="outlined" validate={required()} />
        <TextInput source="code" variant="outlined" validate={required()} />
        <Typography variant="h5" gutterBottom underline>
          {t('resources.categories.sections.workflow-options')}
        </Typography>
        <BooleanInput source="enableAsap" variant="outlined" />
        <BooleanInput source="datetimeSelectable" variant="outlined" />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.datetimeSelectable && (
              <TextInput
                source="cron"
                variant="outlined"
                placeholder={t(
                  'resources.categories.fields.datetimeSelectablePlaceholder'
                )}
                helperText={t(
                  'resources.categories.fields.datetimeSelectableHelperText'
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <CategoryConfirmationMethodInputs />
      </SimpleFormWithServerSideFieldValidation>
    </Create>
  );
};

export default CategoryCreate;
