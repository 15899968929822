import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useReducer,
} from 'react';
import { HotelRecord } from '../common/types';
import Reducer, { GlobalState, GlobalAction } from '../reducers/globalReducer';

export const getInitialState = (): GlobalState => {
  const storedDefaultHotel = localStorage.getItem('defaultHotel');
  const storedSelectedHotel = localStorage.getItem('selectedHotel');
  const storedHotel = storedSelectedHotel || storedDefaultHotel;
  const storedLocale = localStorage.getItem('locale');

  return {
    hotel: storedHotel ? (JSON.parse(storedHotel) as HotelRecord) : null,
    locale: storedLocale,
  };
};

export const GlobalContext = createContext(
  (getInitialState() as unknown) as [GlobalState, Dispatch<GlobalAction>]
);

export const GlobalContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useReducer(Reducer, getInitialState());
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error(
      'useGlobalContext must be used within a GlobalContextProvider'
    );
  }

  return context;
};
