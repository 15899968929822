import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import { INTL_COUNT } from '../../../../common/constants';
import { getDaysFromTimeframe, useKeenQuery } from '../../../../utils/keen';
import { useIntl } from '../../../../context/intlContext';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useSessionCountWeekdayAverageData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const intl = useIntl();
  const t = useTranslate();
  const daysInTimeFrame = getDaysFromTimeframe(timeframe);

  const [
    { data: sessionCountWeekday = [], loading: isSessionCountWeekdayLoading },
    querySessionCountWeekdayAverage,
  ] = useKeenQuery<{ dateDayOfWeek: number; result: number }[]>();

  useEffect(() => {
    querySessionCountWeekdayAverage({
      analysisType: 'count',
      eventCollection: 'sessions',
      groupBy: 'date.day_of_week',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, querySessionCountWeekdayAverage, timeframe]);

  const dayToDataMap = sessionCountWeekday.reduce(
    (
      acc: { [key: number]: { dateDayOfWeek: number; result: number } },
      val: { dateDayOfWeek: number; result: number }
    ) => {
      acc[val.dateDayOfWeek] = val;
      return acc;
    },
    {}
  );
  const zeroFilledSessionCountWeekday = [...Array(7)].map(
    (_, index) => dayToDataMap[index] || { dateDayOfWeek: index, result: 0 }
  );

  const sessionCountWeekdayAverage = zeroFilledSessionCountWeekday.map(
    ({ dateDayOfWeek, result }) => ({
      dateDayOfWeek,
      result: +(result / daysInTimeFrame).toFixed(2),
    })
  );

  const sessionCountWeekdayAverageData = sessionCountWeekdayAverage.map(
    ({ dateDayOfWeek, result }) => ({
      id: t(`settings.day-of-week.${dateDayOfWeek}`, {
        count: INTL_COUNT.OTHER,
      }),
      value: result,
      tooltip: {
        title: t(`settings.day-of-week.${dateDayOfWeek}`, {
          count: INTL_COUNT.OTHER,
        }),
        subtitle: intl.formatNumber(result, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const chartProps: UsageTotalsKeenChartProps = {
    data: sessionCountWeekdayAverageData,
    skipEvenBottomLabels: false,
  };

  return {
    chartProps,
    loading: isSessionCountWeekdayLoading,
  };
};

export default useSessionCountWeekdayAverageData;
