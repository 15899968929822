import { ResourceProps } from 'react-admin';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { DataResourceOptions } from '../../common/types';
import IntegrationsConfigurationList from './IntegrationsConfigurationList';
import IntegrationsConfigurationCreate from './IntegrationsConfigurationCreate';
import IntegrationsConfigurationEdit from './IntegrationsConfigurationEdit';

export const resourceProps: ResourceProps = {
  name: 'integrations-configurations',
  icon: IntegrationInstructionsIcon,
  list: IntegrationsConfigurationList,
  create: IntegrationsConfigurationCreate,
  edit: IntegrationsConfigurationEdit,
};

export default {
  name: 'integrations-configurations',
  singularName: 'integrations-configuration',
  payloadKey: 'integrations/configurations',
  singularPayloadKey: 'integrations/configuration',
  localized: false,
} as DataResourceOptions;
