import { useEffect } from 'react';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { useKeenQuery } from '../../../../utils/keen';

const usePageViewsByPageIdData = ({
  hotel,
  timeframe,
  selectedCategory,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}) => {
  const [
    { data: pageViewsByPage = [], loading: isPageViewsByPageLoading },
    queryPageViewsByPage,
  ] = useKeenQuery<{ pageId: string; result: number }[]>();

  useEffect(() => {
    queryPageViewsByPage({
      analysisType: 'count',
      eventCollection: 'page_views',
      groupBy: 'page_id',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
          ],
      timeframe,
    });
  }, [hotel.uuid, queryPageViewsByPage, selectedCategory, timeframe]);

  return {
    data: pageViewsByPage,
    loading: isPageViewsByPageLoading,
  };
};

export default usePageViewsByPageIdData;
