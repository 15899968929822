import { useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import {
  getKeenRequestType,
  PageViewsRequest,
  useKeenQuery,
} from '../../../../utils/keen';
import { useIntl } from '../../../../context/intlContext';
import {
  CategoryRecord,
  CurrencyRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import {
  UsageTotalsChartData,
  UsageTotalsKeenChartProps,
} from '../../UsageTotalsKeenChart';

interface UseRevenueTotalDataProps {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
  currency: CurrencyRecord;
}

const useRevenueTotalHourlyData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
  isEnabled = false,
}: UseRevenueTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [totalRevenueData, setTotalRevenueData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: revenueHourlyAverage = [], loading: isRevenueHourlyAverageLoading },
    queryRevenueHourlyAverage,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    if (isEnabled) {
      queryRevenueHourlyAverage({
        analysisType: 'sum',
        eventCollection: 'orders',
        targetProperty: 'total',
        groupBy: 'date.hour_of_day',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'test_order',
                operator: 'eq',
                propertyValue: false,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'test_order',
                operator: 'eq',
                propertyValue: false,
              },
            ],
        timeframe,
      });
    }
  }, [
    hotel.id,
    isEnabled,
    queryRevenueHourlyAverage,
    selectedCategory,
    timeframe,
  ]);

  useEffect(() => {
    if (isEnabled && !isRevenueHourlyAverageLoading) {
      const hourToDataMap = revenueHourlyAverage.reduce(
        (
          acc: { [key: number]: { dateHourOfDay: number; result: number } },
          val: { dateHourOfDay: number; result: number }
        ) => {
          acc[val.dateHourOfDay] = val;
          return acc;
        },
        {}
      );
      const zeroFilledRevenueHourlyAverage = [...Array(24)].map(
        (_, index) =>
          hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
      );

      const data = zeroFilledRevenueHourlyAverage.map(
        ({ dateHourOfDay, result }) => ({
          id: formatTime(dateHourOfDay, intl),
          value: result,
          tooltip: {
            title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
            subtitle: intl.formatNumber(result, {
              style: 'currency',
              currency: currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
        })
      );

      setTotalRevenueData(data);
    }
  }, [
    currency.code,
    intl,
    isEnabled,
    isRevenueHourlyAverageLoading,
    revenueHourlyAverage,
  ]);

  return {
    data: totalRevenueData,
    loading: isRevenueHourlyAverageLoading,
  };
};

const useRevenueTotalDailyData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
  isEnabled = false,
}: UseRevenueTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [totalRevenueData, setTotalRevenueData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: totalRevenue = [], loading: isTotalRevenueLoading },
    queryTotalRevenue,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryTotalRevenue({
        analysisType: 'sum',
        eventCollection: 'orders',
        targetProperty: 'total',
        interval: 'daily',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.id, isEnabled, queryTotalRevenue, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isTotalRevenueLoading) {
      const data = totalRevenue.map(
        ({ value, timeframe: revenueTimeframe }) => ({
          id: new Date(revenueTimeframe.start).getDate(),
          value,
          tooltip: {
            title: intl.formatDate(new Date(revenueTimeframe.start), {
              weekday: 'long',
              day: 'numeric',
              year: 'numeric',
              month: 'long',
            }),
            subtitle: intl.formatNumber(value, {
              style: 'currency',
              currency: currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
        })
      );

      setTotalRevenueData(data);
    }
  }, [currency.code, intl, isEnabled, isTotalRevenueLoading, totalRevenue]);

  return { data: totalRevenueData, loading: isTotalRevenueLoading };
};

const useRevenueTotalWeeklyData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
  isEnabled = false,
}: UseRevenueTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const t = useTranslate();
  const [totalRevenueData, setTotalRevenueData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: totalRevenue = [], loading: isTotalRevenueLoading },
    queryTotalRevenue,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryTotalRevenue({
        analysisType: 'sum',
        eventCollection: 'orders',
        targetProperty: 'total',
        interval: 'weekly',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.id, isEnabled, queryTotalRevenue, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isTotalRevenueLoading) {
      const data = totalRevenue.map(({ value }, index) => ({
        id: index + 1,
        value,
        tooltip: {
          title: `${t('settings.week')} ${index + 1}`,
          subtitle: intl.formatNumber(value, {
            style: 'currency',
            currency: currency.code,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        },
      }));

      setTotalRevenueData(data);
    }
  }, [currency.code, intl, isEnabled, isTotalRevenueLoading, t, totalRevenue]);

  return { data: totalRevenueData, loading: isTotalRevenueLoading };
};

const useRevenueTotalData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
}: UseRevenueTotalDataProps) => {
  const intl = useIntl();
  const t = useTranslate();
  const requestType = getKeenRequestType(timeframe);

  const {
    data: revenueTotalHourlyData,
    loading: isRevenueTotalHourlyDataLoading,
  } = useRevenueTotalHourlyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Hourly,
    currency,
  });
  const {
    data: revenueTotalDailyData,
    loading: isRevenueTotalDailyDataLoading,
  } = useRevenueTotalDailyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Daily,
    currency,
  });
  const {
    data: revenueTotalWeeklyData,
    loading: isRevenueTotalWeeklyDataLoading,
  } = useRevenueTotalWeeklyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Weekly,
    currency,
  });

  const requestTypeToDataMap = {
    [PageViewsRequest.Hourly]: revenueTotalHourlyData,
    [PageViewsRequest.Daily]: revenueTotalDailyData,
    [PageViewsRequest.Weekly]: revenueTotalWeeklyData,
  };
  const requestTypeToLoadingMap = {
    [PageViewsRequest.Hourly]: isRevenueTotalHourlyDataLoading,
    [PageViewsRequest.Daily]: isRevenueTotalDailyDataLoading,
    [PageViewsRequest.Weekly]: isRevenueTotalWeeklyDataLoading,
  };
  const requestTypeToLabelMap = {
    [PageViewsRequest.Hourly]: t('charts.usage.legend.hours'),
    [PageViewsRequest.Daily]: t('charts.usage.legend.days'),
    [PageViewsRequest.Weekly]: t('charts.usage.legend.weeks'),
  };

  const data = requestTypeToDataMap[requestType];
  const loading = requestTypeToLoadingMap[requestType];
  const legend = requestTypeToLabelMap[requestType];
  const formatAxisRight = (value: number) =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: currency.code,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const chartProps: UsageTotalsKeenChartProps = {
    data,
    legend,
    color: '#7fa6db',
    formatAxisRight,
  };

  return { loading, chartProps };
};

export default useRevenueTotalData;
