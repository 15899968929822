import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';
import CloneHotelDialog from './CloneHotelDialog';

const HotelFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="name_or_theme_or_timezone_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const HotelList = (props: any) => {
  const [parentScrollTop, setParentScrollTop] = useState<number | null>(null);
  const t = useTranslate();

  useEffect(() => {
    window.parentIFrame?.getPageInfo(({ scrollTop }) =>
      setParentScrollTop(scrollTop)
    );

    return () => window.parentIFrame?.getPageInfo(false);
  });

  return (
    <List
      exporter={false}
      filters={<HotelFilter />}
      pagination={<ListPagination />}
      perPage={50}
      actions={<ListViewTopToolbar />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid isRowSelectable={(record) => record.type !== 'HotelMixin'}>
        <TextField source="name" />
        <FunctionField
          source="type"
          label={t('resources.hotels.fields.sharedInstallation')}
          render={(record: any) => (
            <Checkbox checked={record.type === 'HotelMixin'} />
          )}
        />
        <TextField source="timezone" />
        <TextField source="uuid" />
        <>
          <CloneHotelDialog scrollTop={parentScrollTop} />
          <EditButton className="text-list-button" />
        </>
      </Datagrid>
    </List>
  );
};

export default HotelList;
