import { cloneElement, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  useListContext,
  useNotify,
  TopToolbar,
  Button,
  sanitizeListRestProps,
  useTranslate,
  useDataProvider,
} from 'react-admin';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import GetAppIcon from '@mui/icons-material/GetApp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { initWebSocketClient } from '../../utils/init-web-socket-client';
import { OrderRecord } from '../../common/types';
import { SubscriptionAction, SubscriptionChannel } from '../../common/enums';

const alarmSound = new Audio('/assets/audio/alarm.mp3');

const OrdersTopToolbar = ({ hotel, ...props }: any) => {
  const { filters } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    refetch,
  } = useListContext();
  const notify = useNotify();
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const client = useMemo(() => initWebSocketClient(), []);
  const [isTerminalMode, setTerminalMode] = useState(
    localStorage.getItem('terminalMode') === 'true'
  );

  const notifyOfOrderChange = async ({
    id,
    action,
  }: {
    id: string;
    action: SubscriptionAction;
  }) => {
    try {
      const { data } = await dataProvider.getOne<OrderRecord>('orders', {
        id,
      });
      const { orderNumber } = data;
      if (action === SubscriptionAction.NewOrder) {
        alarmSound.play();

        notify(`ra.notification.new_order`, {
          type: 'info',
          messageArgs: {
            orderNumber,
          },
        });
      }
      if (action === SubscriptionAction.OrderConfirmed) {
        notify(`ra.notification.order_confirmed`, {
          type: 'info',
          messageArgs: {
            orderNumber,
          },
        });
      }
      if (action === SubscriptionAction.OrderDeclined) {
        notify(`ra.notification.order_declined`, {
          type: 'info',
          messageArgs: {
            orderNumber,
          },
        });
      }
    } catch (error: any) {
      notify(error?.message || 'ra.page.error', { type: 'warning' });
    }
  };

  const onNewMessageReceived = ({
    id,
    action,
  }: {
    id: string;
    action: SubscriptionAction;
  }) => {
    notifyOfOrderChange({
      id,
      action,
    });
    refetch();
  };

  client.onConnect = () => {
    const callback = (message: any) => {
      // called when the client receives a STOMP message from the server
      if (message.body) {
        const messageBody = JSON.parse(message.body);

        onNewMessageReceived({
          id: messageBody.order_id,
          action: message.headers.subscription,
        });
      } else {
        notify('ra.page.error', { type: 'warning' });
      }
    };
    const subscribe = (hotelUuid: string, key: string, id: string) =>
      client.subscribe(
        `/exchange/internal/notifications.${hotelUuid}.${key}`,
        callback,
        { id }
      );

    subscribe(
      hotel.uuid,
      SubscriptionChannel.NewOrder,
      SubscriptionAction.NewOrder
    );
    subscribe(
      hotel.uuid,
      SubscriptionChannel.OrderConfirmed,
      SubscriptionAction.OrderConfirmed
    );
    subscribe(
      hotel.uuid,
      SubscriptionChannel.OrderDeclined,
      SubscriptionAction.OrderDeclined
    );
  };

  client.onStompError = (error: any) => {
    // eslint-disable-next-line no-console
    console.error(error);
    Sentry.captureException(error);
  };

  const handleGetRevenueClick = async () => {
    try {
      const { data }: { data: Blob } = await dataProvider.getRevenue(hotel.id);

      const url = URL.createObjectURL(
        new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const date = new Date();
      const formattedDate = date.toISOString().split('T')[0];
      const link = document.createElement('a');
      link.href = url;
      link.download = `${formattedDate}_Revenue-report.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error: any) {
      notify(error?.message || 'ra.page.error', { type: 'warning' });
    }
  };

  const activateTerminalMode = () => {
    client.activate();
    localStorage.setItem('terminalMode', 'true');
  };

  const deactivateTerminalMode = () => {
    client.deactivate();
    localStorage.setItem('terminalMode', 'false');
  };

  const handleTerminalModeButtonClick = () => {
    if (!isTerminalMode) {
      activateTerminalMode();
      setTerminalMode(true);
      alarmSound.play();
    } else {
      deactivateTerminalMode();
      setTerminalMode(false);
    }
  };

  // Activate the terminal mode on init
  useEffect(() => {
    if (isTerminalMode) {
      activateTerminalMode();
    }
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <Button onClick={handleGetRevenueClick} variant="contained" size="medium">
        <GetAppIcon />
      </Button>
      <Button
        variant="contained"
        size="medium"
        onClick={handleTerminalModeButtonClick}
        // https://github.com/mui/material-ui/issues/16846
        component={(Link as unknown) as ReactNode}
        to={{
          pathname: '/orders',
          search: `filter=${JSON.stringify(
            isTerminalMode ? {} : { state_in: ['purchased', 'requested'] }
          )}`,
        }}
      >
        {isTerminalMode ? (
          <>
            <FiberManualRecordIcon
              sx={{
                fontSize: '1.2em',
                paddingRight: '10px',
                color: '#35aa47',
              }}
            />
            {t('ra.action.deactivate_terminal')}
          </>
        ) : (
          <>
            <FiberManualRecordIcon
              sx={{
                fontSize: '1.2em',
                paddingRight: '10px',
                color: '#d84a38',
              }}
            />
            {t('ra.action.activate_terminal')}
          </>
        )}
      </Button>
    </TopToolbar>
  );
};

export default OrdersTopToolbar;
