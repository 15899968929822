import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import {
  Translate as LanguageIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { MouseEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../common/constants';
import { Language } from '../common/enums';

const LANGUAGES_LABEL = SUPPORTED_LANGUAGES.map((supportedLanguage) => ({
  id: supportedLanguage,
  name: `settings.languages.${supportedLanguage}`,
}));

const LanguageMenuButton = ({
  selectedLanguage = DEFAULT_LANGUAGE,
  onChange,
}: {
  selectedLanguage: Language;
  onChange: Function;
}) => {
  const t = useTranslate();
  const [languageMenu, setLanguageMenu] = useState<Element | null>(null);

  const handleLanguageIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageMenuClick = (event: any) => {
    onChange(event);
    setLanguageMenu(null);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenu(null);
  };

  return (
    <>
      <Tooltip title="Change Language" enterDelay={300}>
        <Button
          color="inherit"
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup="true"
          onClick={handleLanguageIconClick}
        >
          <LanguageIcon />
          <span
            style={{ margin: '0px 4px 0px 8px' }}
            data-testid="language-menu-button"
          >
            {t(
              LANGUAGES_LABEL.find(
                (language) => language.id === selectedLanguage
              )!.name
            )}
          </span>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Menu
        id="language-menu"
        anchorEl={languageMenu}
        open={!!languageMenu}
        onClose={handleLanguageMenuClose}
      >
        {LANGUAGES_LABEL.map((language) => (
          <MenuItem
            sx={{
              minWidth: '150px',
            }}
            component="a"
            data-no-link="true"
            key={language.id}
            selected={selectedLanguage === language.id}
            onClick={handleLanguageMenuClick}
            lang={language.id}
            hrefLang={language.id}
            data-testid={`language-menu-item-${language.id}`}
          >
            {t(language.name)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageMenuButton;
