import { TabContext } from '@mui/lab';
import { ChangeEvent, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useTranslate, useGetMany } from 'react-admin';
import {
  CategoryRecord,
  CurrencyRecord,
  HotelRecord,
  ItemRecordLocalized,
  Timeframe,
} from '../../../common/types';
import UsageTotalsKeenChart from '../UsageTotalsKeenChart';
import { useIntl } from '../../../context/intlContext';
import {
  useRevenueOrderItemsByCategoryData,
  useRevenueHourlyAverageData,
  useRevenueWeekdayAverageData,
  useRevenueTotalData,
  useTotalRevenueByCategoryData,
  useAverageRevenueByCategoryData,
} from '../data-hooks';
import ChartTab from '../shared/ChartTab';
import ChartListTab from '../shared/ChartListTab';
import ChartsCircularProgress from '../shared/ChartsCircularProgress';
import ChartListTabList from '../shared/ChartListTabList';
import ChartTabPanel from '../shared/ChartTabPanel';
import ChartList from '../shared/ChartList';
import ChartMainTabList from '../shared/ChartMainTabList';
import ChartTooltip from '../shared/ChartTooltip';
import ListItemProgressBar from '../shared/ListItemProgressBar';
import ChartListItem from '../shared/ChartListItem';
import ChartListItemTitle from '../shared/ChartListItemTitle';
import ChartListCategoryTitle from '../shared/ChartListCategoryTitle';
import ChartListItemValue from '../shared/ChartListItemValue';
import VerticalDivider from '../shared/VerticalDivider';
import ListSectionContainer from '../shared/ListSectionContainer';
import ChartContainer from '../shared/ChartContainer';
import ChartSideList from '../shared/ChartSideList';
import ChartContent from '../shared/ChartContent';

const RevenueItemList = ({
  selectedCategory,
  hotel,
  timeframe,
  categories = [],
  withCategoryTitle = false,
  currency,
}: {
  selectedCategory: CategoryRecord | null;
  hotel: HotelRecord;
  timeframe: Timeframe;
  currency: CurrencyRecord;
  categories?: CategoryRecord[];
  withCategoryTitle?: boolean;
}) => {
  const intl = useIntl();

  const {
    data: orderItemsByCategoryData = [],
    loading: isOrderItemsByCategoryLoading,
  } = useRevenueOrderItemsByCategoryData({
    hotel,
    timeframe,
    selectedCategory,
  });

  const totalResultByItemId = orderItemsByCategoryData
    .flatMap(({ orderItems }) => orderItems)
    .reduce((acc: { [key: number]: number }, val) => {
      if (!acc[val.item_id]) {
        acc[val.item_id] = 0;
      }

      acc[val.item_id] += val.total_value;

      return acc;
    }, {});
  const itemIds = Object.keys(totalResultByItemId);

  const {
    data: items = [],
    isLoading: itemsLoading,
  } = useGetMany<ItemRecordLocalized>(
    'items',
    { ids: itemIds },
    {
      enabled: !!itemIds.length,
    }
  );

  const isLoading = itemsLoading || isOrderItemsByCategoryLoading;

  // See https://github.com/marmelab/react-admin/issues/5054
  const itemData = items
    .filter((item) => item !== undefined)
    .map(({ id, title, categoryId }) => {
      const categoryRecord = categories.find(
        ({ id: catId }) => catId === categoryId
      );

      return {
        id,
        title,
        categoryTitle: categoryRecord?.title,
        total: totalResultByItemId[id as number],
      };
    });

  const sortedItems = itemData.sort((a, b) => b.total - a.total);
  const maxOrder = Math.max(...sortedItems.map(({ total }) => total));

  if (isLoading) {
    return <ChartsCircularProgress />;
  }

  return (
    <ChartList>
      {sortedItems.map((orderItem) => (
        <ChartListItem key={orderItem.id}>
          <ChartTooltip title={orderItem.title}>
            <ChartListItemTitle>{orderItem.title}</ChartListItemTitle>
          </ChartTooltip>
          {withCategoryTitle && (
            <ChartTooltip title={orderItem.categoryTitle || ''}>
              <ChartListCategoryTitle>
                {orderItem.categoryTitle}
              </ChartListCategoryTitle>
            </ChartTooltip>
          )}
          <ChartListItemValue>
            {intl.formatNumber(orderItem.total, {
              style: 'currency',
              currency: currency.code,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </ChartListItemValue>
          <ListItemProgressBar max={maxOrder} value={orderItem.total} />
        </ChartListItem>
      ))}
    </ChartList>
  );
};

const RevenueCategoryList = ({
  categories,
  hotel,
  timeframe,
  currency,
  onClick,
}: {
  categories: CategoryRecord[];
  hotel: HotelRecord;
  timeframe: Timeframe;
  currency: CurrencyRecord;
  onClick: Function;
}) => {
  const intl = useIntl();
  const t = useTranslate();

  const {
    data: totalRevenueByCategoryData,
    loading: isTotalRevenueByCategoryDataLoading,
  } = useTotalRevenueByCategoryData({ hotel, timeframe });
  const {
    data: averageRevenueByCategoryData,
    loading: isAverageRevenueByCategoryDataLoading,
  } = useAverageRevenueByCategoryData({ hotel, timeframe });

  const isDataPresent =
    totalRevenueByCategoryData.length && averageRevenueByCategoryData.length;
  const isLoading =
    isTotalRevenueByCategoryDataLoading ||
    isAverageRevenueByCategoryDataLoading;

  const folders =
    isDataPresent && !isLoading
      ? totalRevenueByCategoryData
          .map(({ categoryId, result }) => {
            const categoryRecord = categories.find(
              ({ id }) => +categoryId === id
            );
            const averageData = averageRevenueByCategoryData.find(
              ({ categoryId: totalRevenueCategoryId }) =>
                categoryId === totalRevenueCategoryId
            );

            return {
              id: categoryId,
              title: categoryRecord
                ? categoryRecord.title
                : t('charts.deleted-folder', { id: categoryId }),
              average: averageData ? averageData.result : 0,
              total: result,
              category: categoryRecord,
            };
          })
          .sort((a, b) => b.total - a.total)
      : [];

  const maxRevenue = Math.max(...folders.map(({ total }) => total));

  if (isLoading) {
    return <ChartsCircularProgress />;
  }

  return (
    <ChartList>
      {folders.map((folder) => (
        <ChartListItem
          clickable
          key={folder.id}
          onClick={() => onClick(folder.category)}
          data-testid="revenue-list-item"
        >
          <ChartTooltip title={folder.title}>
            <ChartListItemTitle>{folder.title}</ChartListItemTitle>
          </ChartTooltip>
          <div
            style={{
              width: '60px',
              minWidth: '60px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'end',
              whiteSpace: 'nowrap',
              fontSize: '10px',
              color: '#787582',
              fontWeight: 500,
            }}
          >
            {t('charts.order.revenue.average-revenue')}
            &nbsp;
            <span
              style={{
                fontSize: '12px',
              }}
            >
              {intl.formatNumber(folder.average, {
                style: 'currency',
                currency: currency.code,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
          <ChartListItemValue>
            {intl.formatNumber(folder.total, {
              style: 'currency',
              currency: currency.code,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </ChartListItemValue>
          <KeyboardArrowRightIcon fontSize="small" />
          <ListItemProgressBar max={maxRevenue} value={folder.total} />
        </ChartListItem>
      ))}
    </ChartList>
  );
};

const ListSection = ({
  categories,
  hotel,
  timeframe,
  selectedCategory,
  onClick,
  currency,
}: {
  categories: CategoryRecord[];
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
  onClick: Function;
  currency: CurrencyRecord;
}) => {
  const t = useTranslate();
  const [currentListTab, setCurrentListTab] = useState('1');

  const onCurrentListTabChange = (
    _event: ChangeEvent<{}>,
    newValue: string
  ) => {
    setCurrentListTab(newValue);
  };

  return (
    <ListSectionContainer>
      <TabContext value={currentListTab}>
        <ChartListTabList onChange={onCurrentListTabChange}>
          {selectedCategory ? (
            <ChartListTab
              value="1"
              label={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <KeyboardArrowLeftIcon fontSize="small" />
                  <VerticalDivider style={{ margin: '6px 10px 6px 5px' }} />
                  {selectedCategory.title}
                </div>
              }
              onClick={() => onClick(null)}
            />
          ) : (
            [
              <ChartListTab
                key={0}
                value="1"
                label={t('charts.category-options.all-folders')}
              />,
              <VerticalDivider key={1} style={{ margin: '24px 16px' }} />,
              <ChartListTab
                key={2}
                value="2"
                label={t('charts.category-options.all-items')}
              />,
            ]
          )}
        </ChartListTabList>
        <ChartTabPanel value="1">
          {selectedCategory ? (
            <RevenueItemList
              selectedCategory={selectedCategory}
              hotel={hotel}
              currency={currency}
              timeframe={timeframe}
            />
          ) : (
            <RevenueCategoryList
              categories={categories}
              currency={currency}
              hotel={hotel}
              timeframe={timeframe}
              onClick={onClick}
            />
          )}
        </ChartTabPanel>
        <ChartTabPanel value="2">
          <RevenueItemList
            withCategoryTitle
            selectedCategory={null}
            categories={categories}
            hotel={hotel}
            currency={currency}
            timeframe={timeframe}
          />
        </ChartTabPanel>
      </TabContext>
    </ListSectionContainer>
  );
};

const OrderTotalsChartRevenue = ({
  categories,
  hotel,
  timeframe,
  currency,
}: {
  categories: CategoryRecord[];
  hotel: HotelRecord;
  timeframe: Timeframe;
  currency: CurrencyRecord;
}) => {
  const t = useTranslate();
  const [currentChartTab, setCurrentChartTab] = useState('1');

  const [
    selectedCategory,
    setSelectedCategory,
  ] = useState<CategoryRecord | null>(null);

  const {
    chartProps: revenueTotalDataChartProps,
    loading: isRevenueTotalDataLoading,
  } = useRevenueTotalData({ hotel, timeframe, selectedCategory, currency });

  const {
    chartProps: revenueWeekdayAverageDataChartProps,
    loading: isRevenueWeekdayAverageLoading,
  } = useRevenueWeekdayAverageData({
    hotel,
    timeframe,
    selectedCategory,
    currency,
  });

  const {
    chartProps: revenueHourlyAverageDataChartProps,
    loading: isRevenueHourlyAverageLoading,
  } = useRevenueHourlyAverageData({
    hotel,
    timeframe,
    selectedCategory,
    currency,
  });

  const onCurrentChartTabChange = (
    _event: ChangeEvent<{}>,
    newValue: string
  ) => {
    setCurrentChartTab(newValue);
  };

  const handleClick = (category: CategoryRecord | null) =>
    setSelectedCategory(category);

  return (
    <ChartContainer>
      <ChartSideList>
        <ListSection
          categories={categories}
          currency={currency}
          timeframe={timeframe}
          hotel={hotel}
          selectedCategory={selectedCategory}
          onClick={handleClick}
        />
      </ChartSideList>
      <ChartContent>
        <TabContext value={currentChartTab}>
          <ChartMainTabList onChange={onCurrentChartTabChange}>
            <ChartTab value="1" label={t('charts.order.chart-types.totals')} />
            <ChartTab
              value="2"
              label={t('charts.order.chart-types.weekday-average')}
            />
            <ChartTab
              value="3"
              label={t('charts.order.chart-types.hourly-average')}
            />
          </ChartMainTabList>
          <ChartTabPanel value="1">
            {isRevenueTotalDataLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...revenueTotalDataChartProps}
              />
            )}
          </ChartTabPanel>
          <ChartTabPanel value="2">
            {isRevenueWeekdayAverageLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...revenueWeekdayAverageDataChartProps}
              />
            )}
          </ChartTabPanel>
          <ChartTabPanel value="3">
            {isRevenueHourlyAverageLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...revenueHourlyAverageDataChartProps}
              />
            )}
          </ChartTabPanel>
        </TabContext>
      </ChartContent>
    </ChartContainer>
  );
};

export default OrderTotalsChartRevenue;
