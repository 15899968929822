import {
  Button,
  EditButtonProps,
  useGetList,
  useRecordContext,
} from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { CategoryRecord } from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';

const defaultIcon = <FileCopyIcon />;

const stopPropagation = (e: { stopPropagation: () => any }) =>
  e.stopPropagation();

const SelectCopyTargetDialog = ({
  isOpen,
  onClose,
  sourceCategory,
}: {
  isOpen: boolean;
  onClose: (event: SyntheticEvent<{}, Event>) => void;
  sourceCategory: CategoryRecord;
}) => {
  const [{ hotel }] = useGlobalContext();
  const { data: options = [], isLoading } = useGetList<CategoryRecord>(
    'categories',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        hotel_id_in: hotel?.id,
      },
    }
  );
  const [targetCategory, setTargetCategory] = useState<CategoryRecord | null>(
    null
  );

  const handleChange = (
    _event: ChangeEvent<{}>,
    category: CategoryRecord | null
  ) => {
    setTargetCategory(category);
  };

  const handleSubmit = () => {
    // eslint-disable-next-line no-console
    console.log('Not implemented yet!', targetCategory);
  };

  return (
    <Dialog
      onClose={onClose}
      onBackdropClick={onClose}
      open={isOpen}
      onClick={stopPropagation}
      aria-labelledby="copy-category-dialog-title"
    >
      <DialogTitle id="copy-category-dialog-title">
        Source: &quot;{sourceCategory.title}&quot;
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <Autocomplete
            id="copy-category-autocomplete"
            style={{ minWidth: 300 }}
            size="small"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={isLoading}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Target"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <FormHelperText>Select target category</FormHelperText>
        </FormControl>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="medium"
            variant="contained"
            label="ra.action.confirm"
          />
          <Button
            onClick={onClose}
            color="secondary"
            size="medium"
            variant="contained"
            label="ra.action.cancel"
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const CopyCategoryButton = (props: EditButtonProps) => {
  const record = useRecordContext(props);
  const [isOpen, setIsOpen] = useState(false);

  const {
    icon = defaultIcon,
    label = 'resources.categories.controls.copy-content',
  } = props;

  const handleDialogOpen = (event: SyntheticEvent<{}, Event>) => {
    event.stopPropagation();

    setIsOpen(true);
  };

  const handleDialogClose = (event: SyntheticEvent<{}, Event>) => {
    event.stopPropagation();

    setIsOpen(false);
  };

  return (
    <>
      <Button
        label={label}
        onClick={handleDialogOpen}
        className="text-list-button"
      >
        {icon}
      </Button>
      <SelectCopyTargetDialog
        sourceCategory={record as CategoryRecord}
        isOpen={isOpen}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default CopyCategoryButton;
