import { ElementType } from 'react';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { InputProps } from 'react-admin';
import { styled } from '@mui/material/styles';

interface StyledMuiTypographyProps extends TypographyProps {
  underline?: boolean;
}

const StyledMuiTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'underline',
})<StyledMuiTypographyProps>(({ underline }) => ({
  '&.MuiTypography-root': {
    borderBottom: underline ? '1px solid #eeeeee' : 'none',
  },
}));

const Typography = <C extends ElementType>(
  props: TypographyProps<C, { component?: C }> &
    Partial<InputProps> & { underline?: boolean }
) => {
  const {
    align,
    children,
    color,
    display,
    gutterBottom,
    noWrap,
    paragraph,
    variant,
    variantMapping,
    underline = false,
  } = props;

  return children ? (
    <StyledMuiTypography
      align={align}
      color={color}
      display={display}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
      variantMapping={variantMapping}
      underline={underline}
    >
      {children}
    </StyledMuiTypography>
  ) : (
    <StyledMuiTypography
      align={align}
      color={color}
      display={display}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
      variantMapping={variantMapping}
      underline={underline}
    />
  );
};

export default Typography;
