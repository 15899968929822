import { ReactNode, useEffect } from 'react';
import { useLocaleState as useLanguage } from 'react-admin';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HotelRecord } from '../common/types';
import { useGlobalContext } from '../context/globalContext';
import dataProvider from '../providers/dataProvider';
import { GlobalActionType } from '../reducers/globalReducer';

const getStored = (key: string): any => {
  let result = localStorage.getItem(key);

  if (result) {
    // try to parse
    try {
      result = JSON.parse(result);
    } catch {
      // ignore error
    }
  }

  return result;
};

const WithCustomQueryParams = ({ children }: { children: ReactNode }) => {
  const [{ hotel, locale }, dispatch] = useGlobalContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [language, setLanguage] = useLanguage();
  const location = useLocation();

  // There is a bug when a query parameter is set while we're on
  // the login route: you will not get redirected back to the route
  // you tried navigating to and/or index route on first login.
  // Simply ignoring QP's on /login route solves the issue.
  const isLoginPage = location.pathname === '/login';

  const langFromParams = searchParams.get('lang');
  const hotelIdFromParams = searchParams.get('hotel_id');
  const localeFromParams = searchParams.get('locale');

  const langFromStorage = getStored('selectedLanguage');
  const hotelFromStorage: HotelRecord | null = getStored('hotel');
  const localeFromStorage = getStored('locale');

  useEffect(() => {
    const onLanguageChange = (lang: string) => {
      localStorage.setItem('selectedLanguage', lang);
      setLanguage(lang);
      searchParams.set('lang', lang);
      setSearchParams(searchParams, { replace: true });
    };

    const langIntent = langFromParams || langFromStorage;

    if (langIntent && langIntent !== language) {
      onLanguageChange(langIntent);
    } else if (!isLoginPage) {
      searchParams.set('lang', language);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    langFromParams,
    langFromStorage,
    language,
    setLanguage,
    isLoginPage,
    setSearchParams,
    searchParams,
  ]);

  useEffect(() => {
    const onHotelIdChange = async (id: number) => {
      try {
        const selectedHotel = (
          await dataProvider.getOne<HotelRecord>('hotels', { id })
        ).data;

        localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));
        dispatch({
          type: GlobalActionType.SetHotel,
          payload: selectedHotel,
        });
        searchParams.set('hotel_id', `${selectedHotel.id}`);
        setSearchParams(searchParams, { replace: true });
      } catch {
        if (hotel) {
          searchParams.set('hotel_id', `${hotel.id}`);
          setSearchParams(searchParams, { replace: true });
        }
      }
    };

    const hotelIdIntent = hotelIdFromParams || hotelFromStorage?.id;

    if (hotelIdIntent && +hotelIdIntent !== hotel?.id) {
      onHotelIdChange(+hotelIdIntent);
    } else if (hotel && !isLoginPage) {
      searchParams.set('hotel_id', `${hotel.id}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    dispatch,
    hotel,
    hotelFromStorage?.id,
    hotelIdFromParams,
    isLoginPage,
    setSearchParams,
    searchParams,
  ]);

  useEffect(() => {
    const onLocaleChange = (key: string) => {
      localStorage.setItem('locale', key);
      dispatch({
        type: GlobalActionType.SetLocale,
        payload: key,
      });

      searchParams.set('locale', key);
      setSearchParams(searchParams, { replace: true });
    };

    const localeIntent = localeFromParams || localeFromStorage;

    if (localeIntent && localeIntent !== locale) {
      onLocaleChange(localeIntent);
    } else if (locale && !isLoginPage) {
      searchParams.set('locale', locale);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    dispatch,
    locale,
    localeFromParams,
    localeFromStorage,
    isLoginPage,
    setSearchParams,
    searchParams,
  ]);

  return <>{children}</>;
};

export default WithCustomQueryParams;
