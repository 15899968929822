import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { getDaysFromTimeframe, useKeenQuery } from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useSessionCountHourlyAverageData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const t = useTranslate();
  const intl = useIntl();
  const daysInTimeFrame = getDaysFromTimeframe(timeframe);

  const [
    { data: sessionCountHourly = [], loading: isSessionCountHourlyLoading },
    querySessionCountHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    querySessionCountHourly({
      analysisType: 'count',
      eventCollection: 'sessions',
      groupBy: 'date.hour_of_day',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, querySessionCountHourly, timeframe]);

  const hourToDataMap = sessionCountHourly.reduce(
    (
      acc: { [key: number]: { dateHourOfDay: number; result: number } },
      val: { dateHourOfDay: number; result: number }
    ) => {
      acc[val.dateHourOfDay] = val;
      return acc;
    },
    {}
  );
  const zeroFilledSessionCountHourly = [...Array(24)].map(
    (_, index) => hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
  );

  const sessionCountHourlyAverage = zeroFilledSessionCountHourly.map(
    ({ dateHourOfDay, result }) => ({
      dateHourOfDay,
      result: +(result / daysInTimeFrame).toFixed(2),
    })
  );

  const sessionCountHourlyData = sessionCountHourlyAverage.map(
    ({ dateHourOfDay, result }) => ({
      id: formatTime(dateHourOfDay, intl),
      value: result,
      tooltip: {
        title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
        subtitle: intl.formatNumber(result, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const chartProps: UsageTotalsKeenChartProps = {
    data: sessionCountHourlyData,
    legend: t('charts.usage.legend.hours'),
  };

  return { chartProps, loading: isSessionCountHourlyLoading };
};

export default useSessionCountHourlyAverageData;
