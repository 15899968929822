import {
  BooleanInput,
  Edit,
  ReferenceInput,
  required,
  TextInput,
  AutocompleteInput,
  usePermissions,
  SelectInput,
  useRecordContext,
} from 'react-admin';
import { AppVersionChannel, Role } from '../../common/enums';
import { DeviceRecord } from '../../common/types';
import EditViewToolbar from '../EditViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import DeviceTypeSelectInput from './DeviceTypeSelectInput';

const channelChoices = Object.values(
  AppVersionChannel
).map((channel, index) => ({ id: index, name: channel }));

// eslint-disable-next-line react/require-default-props
const DeviceTitle = () => {
  const record: DeviceRecord = useRecordContext();
  return <span>Device {record ? `"${record.uuid}"` : ''}</span>;
};

const DeviceEdit = (props: any) => {
  const { permissions = [], isLoading } = usePermissions();
  const isAdmin = !isLoading && permissions.includes(Role.Admin);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Edit title={<DeviceTitle />} {...props}>
      <SimpleFormWithServerSideFieldValidation
        toolbar={<EditViewToolbar />}
        type="edit"
      >
        <BooleanInput
          source="active"
          variant="outlined"
          validate={required()}
        />
        {isAdmin && (
          <SelectInput
            source="channelApk"
            variant="outlined"
            optionValue="name"
            validate={required()}
            options={{ fullWidth: true }}
            choices={channelChoices}
            translateChoice={false}
          />
        )}
        {isAdmin && (
          <BooleanInput
            source="demoDevice"
            variant="outlined"
            validate={required()}
          />
        )}
        <BooleanInput
          source="warningsEnabled"
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="uuid"
          variant="outlined"
          validate={required()}
          disabled
        />
        <DeviceTypeSelectInput isAdmin={isAdmin} isNew={false} />
        <ReferenceInput
          label="resources.rooms.name"
          source="roomId"
          reference="rooms"
        >
          <AutocompleteInput
            optionText="code"
            filterToQuery={(text) => ({ code_cont: text })}
          />
        </ReferenceInput>
      </SimpleFormWithServerSideFieldValidation>
    </Edit>
  );
};

export default DeviceEdit;
