const declareGlobalWindowProperties = () => {
  if (!window.AdminPanel) {
    window.AdminPanel = {
      get version() {
        return (
          document
            .querySelector('meta[name="release:version"]')
            ?.getAttribute('content') || ''
        );
      },
    };
  }
};

export default declareGlobalWindowProperties;
