import { Box } from '@mui/material';
import {
  InputProps,
  NumberInput,
  regex,
  required,
  SimpleFormIterator,
  TextInput,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';
import StyledArrayInput from './StyledArrayInput';

const SimphonyGen1Inputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiEndpointUrl"
        label="resources.integrations.fields.simphony_gen1.apiEndpointUrl.label"
        helperText="resources.integrations.fields.simphony_gen1.apiEndpointUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.simphony_gen1.apiEndpointUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <NumberInput
        source="currentConfig.service.employeeRef"
        label="resources.integrations.fields.simphony_gen1.employeeRef.label"
        helperText="resources.integrations.fields.simphony_gen1.employeeRef.helperText"
        variant="outlined"
        fullWidth
      />
      <StyledArrayInput
        source="currentConfig.service.revenueCenterRefs"
        label="resources.integrations.fields.simphony_gen1.revenueCenterRefs.label"
        helperText="resources.integrations.fields.simphony_gen1.revenueCenterRefs.helperText"
        variant="outlined"
        validate={required(
          'resources.integrations.fields.simphony_gen1.revenueCenterRefs.helperText'
        )}
        fullWidth
      >
        <SimpleFormIterator disableReordering>
          <NumberInput
            source=""
            label="resources.integrations.fields.simphony_gen1.revenueCenterRef.label"
            helperText="resources.integrations.fields.simphony_gen1.revenueCenterRef.helperText"
            variant="outlined"
            fullWidth
          />
        </SimpleFormIterator>
      </StyledArrayInput>
      <TextInput
        source="currentConfig.service.menuLanguage"
        label="resources.integrations.fields.simphony_gen1.menuLanguage.label"
        helperText="resources.integrations.fields.simphony_gen1.menuLanguage.helperText"
        validate={[
          required(
            'resources.integrations.fields.simphony_gen1.menuLanguage.helperText'
          ),
          regex(
            /[a-z][a-z]-[A-Z][A-Z]/,
            'resources.integrations.fields.simphony_gen1.menuLanguage.helperText'
          ),
        ]}
        variant="outlined"
        fullWidth
      />
      <NumberInput
        source="currentConfig.service.orderTypeRef"
        label="resources.integrations.fields.simphony_gen1.orderTypeRef.label"
        helperText="resources.integrations.fields.simphony_gen1.orderTypeRef.helperText"
        validate={required(
          'resources.integrations.fields.simphony_gen1.orderTypeRef.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.simphonyVersion"
        label="resources.integrations.fields.simphony_gen1.simphonyVersion.label"
        helperText="resources.integrations.fields.simphony_gen1.simphonyVersion.helperText"
        validate={required(
          'resources.integrations.fields.simphony_gen1.simphonyVersion.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <NumberInput
        source="currentConfig.service.tenderMediaRef"
        label="resources.integrations.fields.simphony_gen1.tenderMediaRef.label"
        helperText="resources.integrations.fields.simphony_gen1.tenderMediaRef.helperText"
        validate={required(
          'resources.integrations.fields.simphony_gen1.tenderMediaRef.helperText'
        )}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default SimphonyGen1Inputs;
