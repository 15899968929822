import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  Create,
  CreateProps,
  Loading,
  useCreate,
  usePermissions,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Integration, Role } from '../../common/enums';
import { useGlobalContext } from '../../context/globalContext';
import CreateViewToolbar from '../CreateViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import ServiceSpecificInputs from './ServiceSpecificInputs';
import AvailableIntegrationsList from './AvailableIntegrationsList';
import {
  IntegrationConfigurationRevisionRecord,
  IntegrationsConfigurationRecord,
} from '../../common/types';

const IntegrationsConfigurationStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { setValue } = useFormContext();

  return (
    <Box width="100%">
      {activeStep === 0 && (
        <AvailableIntegrationsList
          onSelect={(value: Integration) => {
            setValue('serviceName', value);

            switch (value) {
              case Integration.Suite8:
                setValue('currentConfig.cron', [
                  {
                    name: 'full',
                    task: 'full_reservation_sync',
                  },
                ]);
                break;
              case Integration.Hotelkit:
                setValue('currentConfig.webhooks', [
                  {
                    enabled: true,
                    name: 'processor',
                  },
                ]);
                break;
              case Integration.Mews:
                setValue('currentConfig.webhooks', [
                  {
                    enabled: true,
                    name: 'processor',
                  },
                ]);
                setValue(
                  'currentConfig.service.apiBaseUrl',
                  'https://api.mews.com'
                );
                break;
              case Integration.Guestline:
                setValue('currentConfig.cron', [
                  {
                    name: 'guestline_reservation_sync',
                    task: 'full_reservation_sync',
                  },
                ]);
                setValue(
                  'currentConfig.service.apiEndpointUrl',
                  'https://pmsws.eu.guestline.net/rlxsoaprouter/rlxsoap.asmx'
                );
                break;
              case Integration.Asa:
                setValue('currentConfig.webhooks', [
                  {
                    name: 'standard',
                    enabled: true,
                  },
                ]);
                break;
              case Integration.Sihot:
                setValue('currentConfig.webhooks', [
                  {
                    name: 'reservations',
                    enabled: true,
                  },
                ]);
                break;
              default:
            }

            setActiveStep(1);
          }}
        />
      )}
      {activeStep === 1 && <ServiceSpecificInputs />}
    </Box>
  );
};

const IntegrationsConfigurationCreate = (props: CreateProps) => {
  const [{ hotel }] = useGlobalContext();
  const [create] = useCreate<IntegrationConfigurationRevisionRecord>();
  const { permissions = [], isLoading } = usePermissions();
  const isAdmin = !isLoading && permissions.includes(Role.Admin);

  const createConfigurationRevision = useCallback(
    async (
      configuration: IntegrationsConfigurationRecord,
      data: { currentConfig: object }
    ) =>
      create(
        'integrations-configuration-revisions',
        {
          data: {
            integrationsConfigurationId: configuration.id,
            version: 1,
            config: data.currentConfig,
          },
        },
        { returnPromise: true }
      ),
    [create]
  );

  if (!hotel || isLoading) {
    return <Loading />;
  }

  return (
    <Create
      title="resources.integrations-configurations.controls.create-title"
      redirect="list"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SimpleFormWithServerSideFieldValidation
        sx={{
          '& .MuiFormControl-root': {
            marginBottom: '20px',
          },
        }}
        toolbar={<CreateViewToolbar />}
        defaultValues={{
          hotelId: hotel.id,
          integrationUuid: window.crypto.randomUUID(),
          enabled: isAdmin,
          currentConfig: {
            cron: [],
            webhooks: [],
          },
        }}
        afterSave={createConfigurationRevision}
        type="create"
      >
        <IntegrationsConfigurationStepper />
      </SimpleFormWithServerSideFieldValidation>
    </Create>
  );
};

export default IntegrationsConfigurationCreate;
