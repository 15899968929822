import { Toolbar, ListButton, SaveButton, DeleteButton } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';

const EditViewToolbar = ({
  alwaysEnableSaveButton = false,
}: {
  alwaysEnableSaveButton?: boolean;
}) => (
  <Toolbar
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <DeleteButton size="medium" variant="contained" />
    <div>
      <ListButton
        label="ra.action.cancel"
        icon={<ClearIcon />}
        size="medium"
        variant="contained"
        color="inherit"
      />
      <SaveButton
        sx={{
          marginLeft: '10px',
        }}
        size="medium"
        variant="contained"
        color="primary"
        alwaysEnable={alwaysEnableSaveButton}
      />
    </div>
  </Toolbar>
);

export default EditViewToolbar;
