import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'genres',
};

export default {
  name: 'genres',
  singularName: 'genre',
  payloadKey: 'genres',
  singularPayloadKey: 'genre',
  localized: true,
} as DataResourceOptions;
