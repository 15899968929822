import { useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
  TimeframeAsObject,
} from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import {
  convertToLocalAdjustedUTC,
  getKeenRequestType,
  PageViewsRequest,
  useKeenQuery,
} from '../../../../utils/keen';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import {
  UsageTotalsChartData,
  UsageTotalsKeenChartProps,
} from '../../UsageTotalsKeenChart';

interface UsePageViewsTotalDataProps {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}

type PageViewsTotalDailyResponse = {
  value: number;
  timeframe: TimeframeAsObject;
};

type PageViewsTotalWeeklyResponse = {
  value: number;
  timeframe: TimeframeAsObject;
};

type PageViewsTotalHourlyResponse = {
  dateHourOfDay: number;
  result: number;
};

const usePageViewsTotalHourlyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled = false,
}: UsePageViewsTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [pageViewsData, setPageViewsData] = useState<UsageTotalsChartData[]>(
    []
  );
  const [
    { data: pageViews = [], loading: isPageViewsLoading },
    queryPageViews,
  ] = useKeenQuery<PageViewsTotalHourlyResponse[]>();

  useEffect(() => {
    if (isEnabled) {
      queryPageViews({
        analysisType: 'count',
        eventCollection: 'page_views',
        groupBy: 'date.hour_of_day',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, queryPageViews, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isPageViewsLoading) {
      const currentHour = new Date().getHours();
      const relevantPageViews = pageViews.filter(
        ({ dateHourOfDay }) => dateHourOfDay <= currentHour
      );
      const hourToDataMap = relevantPageViews.reduce(
        (
          acc: { [key: number]: { dateHourOfDay: number; result: number } },
          val: { dateHourOfDay: number; result: number }
        ) => {
          acc[val.dateHourOfDay] = val;
          return acc;
        },
        {}
      );
      const zeroFilledPageViewsHourly = [...Array(24)].map(
        (_, index) =>
          hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
      );

      const data = zeroFilledPageViewsHourly.map(
        ({ dateHourOfDay, result }) => ({
          id: formatTime(dateHourOfDay, intl),
          value: result,
          tooltip: {
            title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
            subtitle: intl.formatNumber(result, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
        })
      );

      setPageViewsData(data);
    }
  }, [intl, isEnabled, isPageViewsLoading, pageViews]);

  return { data: pageViewsData, loading: isPageViewsLoading };
};

const usePageViewsTotalDailyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled = false,
}: UsePageViewsTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [pageViewsData, setPageViewsData] = useState<UsageTotalsChartData[]>(
    []
  );

  const [
    { data: pageViews = [], loading: isPageViewsLoading },
    queryPageViews,
  ] = useKeenQuery<PageViewsTotalDailyResponse[]>();

  useEffect(() => {
    if (isEnabled) {
      queryPageViews({
        analysisType: 'count',
        eventCollection: 'page_views',
        interval: 'daily',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
            ],
        timeframe:
          typeof timeframe === 'string'
            ? timeframe
            : {
                start: convertToLocalAdjustedUTC(timeframe.start),
                end: convertToLocalAdjustedUTC(timeframe.end),
              },
      });
    }
  }, [hotel.uuid, isEnabled, queryPageViews, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isPageViewsLoading) {
      const data = pageViews.map(({ value, timeframe: pageViewsTimeframe }) => {
        const localAdjustedUTC = convertToLocalAdjustedUTC(
          pageViewsTimeframe.start
        );

        return {
          id: new Date(localAdjustedUTC).getDate(),
          value,
          tooltip: {
            title: intl.formatDate(new Date(localAdjustedUTC), {
              weekday: 'long',
              day: 'numeric',
              year: 'numeric',
              month: 'long',
            }),
            subtitle: intl.formatNumber(value as number),
          },
        };
      });

      setPageViewsData(data);
    }
  }, [intl, isEnabled, isPageViewsLoading, pageViews]);

  return { data: pageViewsData, loading: isPageViewsLoading };
};

const usePageViewsTotalWeeklyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled = false,
}: UsePageViewsTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const t = useTranslate();
  const [pageViewsData, setPageViewsData] = useState<UsageTotalsChartData[]>(
    []
  );

  const [
    { data: pageViews = [], loading: isPageViewsLoading },
    queryPageViews,
  ] = useKeenQuery<PageViewsTotalWeeklyResponse[]>();

  useEffect(() => {
    if (isEnabled) {
      queryPageViews({
        analysisType: 'count',
        eventCollection: 'page_views',
        interval: 'weekly',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_uuid',
                operator: 'eq',
                propertyValue: hotel.uuid,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, queryPageViews, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isPageViewsLoading) {
      const data = pageViews.map(({ value }, index) => ({
        id: index + 1,
        value,
        tooltip: {
          title: `${t('settings.week')} ${index + 1}`,
          subtitle: intl.formatNumber(value as number),
        },
      }));

      setPageViewsData(data);
    }
  }, [intl, isEnabled, isPageViewsLoading, pageViews, t]);

  return { data: pageViewsData, loading: isPageViewsLoading };
};

const usePageViewsTotalData = ({
  hotel,
  timeframe,
  selectedCategory,
}: UsePageViewsTotalDataProps) => {
  const t = useTranslate();
  const pageViewsRequestType = getKeenRequestType(timeframe);

  const {
    data: pageViewsTotalHourlyData,
    loading: isPageViewsTotalHourlyDataLoading,
  } = usePageViewsTotalHourlyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: pageViewsRequestType === PageViewsRequest.Hourly,
  });
  const {
    data: pageViewsTotalDailyData,
    loading: isPageViewsTotalDailyDataLoading,
  } = usePageViewsTotalDailyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: pageViewsRequestType === PageViewsRequest.Daily,
  });
  const {
    data: pageViewsTotalWeeklyData,
    loading: isPageViewsTotalWeeklyDataLoading,
  } = usePageViewsTotalWeeklyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: pageViewsRequestType === PageViewsRequest.Weekly,
  });

  const requestTypeToDataMap = {
    [PageViewsRequest.Hourly]: pageViewsTotalHourlyData,
    [PageViewsRequest.Daily]: pageViewsTotalDailyData,
    [PageViewsRequest.Weekly]: pageViewsTotalWeeklyData,
  };
  const requestTypeToLoadingMap = {
    [PageViewsRequest.Hourly]: isPageViewsTotalHourlyDataLoading,
    [PageViewsRequest.Daily]: isPageViewsTotalDailyDataLoading,
    [PageViewsRequest.Weekly]: isPageViewsTotalWeeklyDataLoading,
  };
  const requestTypeToLabelMap = {
    [PageViewsRequest.Hourly]: t('charts.usage.legend.hours'),
    [PageViewsRequest.Daily]: t('charts.usage.legend.days'),
    [PageViewsRequest.Weekly]: t('charts.usage.legend.weeks'),
  };

  const data = requestTypeToDataMap[pageViewsRequestType];
  const loading = requestTypeToLoadingMap[pageViewsRequestType];
  const legend = requestTypeToLabelMap[pageViewsRequestType];

  const chartProps: UsageTotalsKeenChartProps = {
    data,
    legend,
  };

  return { loading, chartProps };
};

export default usePageViewsTotalData;
