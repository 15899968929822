import { DateField, useRecordContext } from 'react-admin';

const FulfillmentTimeField = (props: any) => {
  const record = useRecordContext(props);
  return record && record.fulfillmentTime ? (
    <DateField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <span>ASAP</span>
  );
};

export default FulfillmentTimeField;
