import {
  Autocomplete,
  CircularProgress,
  TextField as MuiTextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, useCallback } from 'react';
import {
  InjectedFieldProps,
  PublicFieldProps,
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { ContentGroupRecord, PageRecordLocalized } from '../common/types';

const StyledAutocomplete = styled(Autocomplete)({
  '&.MuiAutocomplete-popper': { width: 'fit-content !important' },
}) as typeof Autocomplete;

const ContentGroupEditableField = (
  props: PublicFieldProps &
    InjectedFieldProps<PageRecordLocalized> & {
      loading: boolean;
      options: ContentGroupRecord[];
      translationKey?: string;
      resourceName?: string;
    }
) => {
  const {
    loading,
    options,
    translationKey = 'resources.page-cgs',
    resourceName = 'page-cgs',
    ...rest
  } = props;
  const notify = useNotify();
  const t = useTranslate();
  const [update, { isLoading: isRecordUpdateInProgress }] = useUpdate();
  const record = useRecordContext(rest);
  const contentGroup =
    options.find(({ id }) => id === record.contentGroupId) || null;

  const handleChange = useCallback(
    async (
      _event: ChangeEvent<{}>,
      selectedContentGroup: ContentGroupRecord | null
    ) => {
      try {
        await update(resourceName, {
          id: record!.id,
          data: { contentGroupId: selectedContentGroup?.id || null },
          previousData: record,
        });

        notify(`${translationKey}.notifications.update.success`, {
          type: 'success',
          messageArgs: {
            title: record!.title,
          },
        });
      } catch (error: any) {
        notify(
          error?.message || `${translationKey}.notifications.update.fail`,
          {
            type: 'warning',
            messageArgs: {
              title: record!.title,
            },
          }
        );
      }
    },
    [notify, record, resourceName, translationKey, update]
  );

  return (
    <StyledAutocomplete
      id="content-group-list-autocomplete"
      value={contentGroup}
      style={{ minWidth: 200 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading || isRecordUpdateInProgress}
      onChange={handleChange}
      renderInput={(params) => (
        <MuiTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          label={t(`${translationKey}.fields.contentGroup`)}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading || isRecordUpdateInProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ContentGroupEditableField;
