import { useEffect } from 'react';
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  Loading,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  CurrencyRecord,
  ItemRecordLocalized,
  PriceRecord,
} from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';

const ItemFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="translations_title_or_translations_teaser_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const PriceWithCurrencyField = (props: any) => {
  const priceRecord: PriceRecord = useRecordContext(props);

  return (
    <ReferenceField
      source="currencyId"
      reference="currencies"
      link={false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FunctionField<CurrencyRecord>
        render={(currencyRecord) => (
          <NumberField
            source="value"
            record={{ ...priceRecord }}
            options={{ style: 'currency', currency: currencyRecord!.code }}
          />
        )}
      />
    </ReferenceField>
  );
};

const ItemListRowControls = (props: any) => (
  <>
    <EditButton
      className="text-list-button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    <DeleteButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="small"
    />
  </>
);

const ItemList = (props: any) => {
  const [{ hotel, locale }] = useGlobalContext();
  const refresh = useRefresh();

  // Since the get request url includes current locale in its path e.g. `de/items`
  // and there's no way to trigger the reload in dataProvider
  // we're triggering the list refresh here every time the locale changes
  useEffect(() => {
    refresh();
  }, [locale, refresh]);

  return hotel && locale ? (
    <List
      exporter={false}
      filter={{ hotel_id_in: hotel.id }}
      filters={<ItemFilter />}
      pagination={<ListPagination />}
      perPage={50}
      actions={<ListViewTopToolbar />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="teaser" />
        <BooleanField source="active" />
        <BooleanField source="visible" />
        <FunctionField<ItemRecordLocalized>
          label="resources.items.fields.image"
          render={(record?) => (
            <BooleanField
              source="hasImage"
              record={{
                ...record!,
                hasImage: !!record!.imageId,
              }}
            />
          )}
        />
        <FunctionField<ItemRecordLocalized>
          label="resources.items.fields.link"
          render={(record?) => (
            <BooleanField
              source="hasLink"
              record={{
                ...record!,
                hasLink: !!record?.linkText && !!record?.linkUrl,
              }}
            />
          )}
        />
        <ReferenceArrayField
          source="priceIds"
          reference="prices"
          label="resources.items.fields.price"
        >
          <SingleFieldList linkType={false}>
            <PriceWithCurrencyField />
          </SingleFieldList>
        </ReferenceArrayField>
        <ItemListRowControls />
      </Datagrid>
    </List>
  ) : (
    <Loading />
  );
};

export default ItemList;
