import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { getDaysFromTimeframe, useKeenQuery } from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useOrderHourlyAverageData = ({
  hotel,
  timeframe,
  selectedCategory,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}) => {
  const t = useTranslate();
  const intl = useIntl();
  const daysInTimeFrame = getDaysFromTimeframe(timeframe);

  const [
    { data: ordersHourly = [], loading: isOrdersHourlyLoading },
    queryOrdersHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    queryOrdersHourly({
      analysisType: 'count',
      eventCollection: 'orders',
      groupBy: 'date.hour_of_day',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
          ],
      timeframe,
    });
  }, [hotel.id, queryOrdersHourly, timeframe, selectedCategory]);

  const hourToDataMap = ordersHourly.reduce(
    (
      acc: { [key: number]: { dateHourOfDay: number; result: number } },
      val: { dateHourOfDay: number; result: number }
    ) => {
      acc[val.dateHourOfDay] = val;
      return acc;
    },
    {}
  );
  const zeroFilledOrdersHourly = [...Array(24)].map(
    (_, index) => hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
  );

  const ordersHourlyAverage = zeroFilledOrdersHourly.map(
    ({ dateHourOfDay, result }) => ({
      dateHourOfDay,
      result: +(result / daysInTimeFrame).toFixed(2),
    })
  );

  const ordersHourlyAverageData = ordersHourlyAverage.map(
    ({ dateHourOfDay, result }) => ({
      id: formatTime(dateHourOfDay, intl),
      value: result,
      tooltip: {
        title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
        subtitle: intl.formatNumber(result, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const chartProps: UsageTotalsKeenChartProps = {
    data: ordersHourlyAverageData,
    legend: t('charts.order.legend.hours'),
    color: '#7fa6db',
  };

  return {
    chartProps,
    loading: isOrdersHourlyLoading,
  };
};

export default useOrderHourlyAverageData;
