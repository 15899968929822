import { styled } from '@mui/material/styles';

const ProgressBarContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
});

const ProgressBarIndicator = styled('div')({
  height: '100%',
  backgroundColor: 'rgba(122, 155, 223, 0.16);',
});

const ListItemProgressBar = ({
  max = 100,
  value = 0,
  color,
}: {
  max: string | number;
  value: string | number;
  color?: string;
}) => {
  const progress = (+value / +max) * 100;

  return (
    <ProgressBarContainer>
      <ProgressBarIndicator
        sx={{ width: `${progress}%`, backgroundColor: color }}
      />
    </ProgressBarContainer>
  );
};

export default ListItemProgressBar;
