import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  Loading,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  sanitizeFieldRestProps,
  SingleFieldList,
  SelectInput,
  TextField,
  useRecordContext,
  useTranslate,
  useUpdate,
  useRefresh,
  Button,
  useCreatePath,
  useListContext,
} from 'react-admin';

import Checkbox from '@mui/material/Checkbox';
import { Chip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link, useLocation } from 'react-router-dom';
import ImageEye from '@mui/icons-material/RemoveRedEye';

import ListPagination from '../ListPagination';
import OrdersTopToolbar from './OrdersTopToolbar';

import ColoredStatusField from './shared-components/ColoredStatusField';
import FulfillmentTimeField from './shared-components/FulfillmentTimeField';
import PriceWithCurrency from './shared-components/PriceWithCurrency';

import { useGlobalContext } from '../../context/globalContext';

const QuickFilter = ({ label }: any) => {
  const translate = useTranslate();

  return (
    <Chip
      sx={{
        marginBottom: 1,
      }}
      label={translate(label)}
    />
  );
};

const OrderFilter = ({ hotel, ...props }: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <ReferenceInput
      label="resources.orders.fields.category"
      source="category_id_eq"
      reference="categories"
      perPage={100}
      sort={{ field: 'title', order: 'ASC' }}
      filter={{ hotel_id_eq: hotel.id }}
      variant="outlined"
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
    <SelectInput
      label="resources.orders.fields.state"
      source="state_eq"
      choices={[
        { id: 'requested', name: `resources.orders.states.requested` },
        { id: 'purchased', name: 'resources.orders.states.purchased' },
        { id: 'confirmed', name: 'resources.orders.states.confirmed' },
        { id: 'declined', name: 'resources.orders.states.declined' },
        {
          id: 'payment_pending',
          name: 'resources.orders.states.payment_pending',
        },
      ]}
      variant="outlined"
    />
    <QuickFilter
      source="test_order_eq"
      label="resources.orders.testOrders"
      defaultValue="true"
    />
  </Filter>
);

const OrderItemsField = (props: any) => {
  const currentRecord = useRecordContext(props);
  const [showAllItems, setShowAllItems] = useState(false);
  const toggleSetShowAllItems = () => {
    setShowAllItems(!showAllItems);
  };
  const numberOfItems =
    currentRecord.orderItemIds.length > 1 && !showAllItems ? 1 : 1000;
  return (
    <ReferenceArrayField
      reference="order-items"
      perPage={numberOfItems}
      onMouseEnter={toggleSetShowAllItems}
      onMouseLeave={toggleSetShowAllItems}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SingleFieldList linkType={false}>
        <ReferenceField source="itemId" reference="items" link={false}>
          <FunctionField
            render={(record: any) => {
              const { title } = record;
              const filteredTitle = title || '';
              if (currentRecord.orderItemIds.length > 1) {
                return showAllItems
                  ? `${filteredTitle}`
                  : `${filteredTitle} (...)`;
              }
              return `${filteredTitle}`;
            }}
          />
        </ReferenceField>
      </SingleFieldList>
    </ReferenceArrayField>
  );
};

const TestOrderField = (props: any) => {
  const record = useRecordContext(props);
  const [checked, setChecked] = useState(record.testOrder);
  const [update] = useUpdate('orders', {
    id: record.id,
    data: { testOrder: !record.testOrder },
    previousData: record,
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    update();
  };
  const translate = useTranslate();
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={translate(`ra.boolean.${checked}`)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...sanitizeFieldRestProps(props)}
    />
  );
};

const CustomShowButton = (props: any) => {
  const { resource } = useListContext();
  const record = useRecordContext(props);
  const createPath = useCreatePath();
  const location = useLocation();
  const t = useTranslate();
  const pathName = createPath({ resource, id: record.id, type: 'show' });

  return (
    <Button
      // https://github.com/mui/material-ui/issues/16846
      component={(Link as unknown) as ReactNode}
      to={`${pathName}/${location.search}`}
      label={t('ra.action.show')}
      className="text-list-button"
    >
      <ImageEye />
    </Button>
  );
};

const OrderList = (props: any) => {
  const [{ hotel, locale }] = useGlobalContext();
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [locale, refresh]);

  return hotel && locale ? (
    <List
      exporter={false}
      filter={{ hotel_id_in: hotel.id }}
      filters={<OrderFilter hotel={hotel} />}
      pagination={<ListPagination />}
      perPage={50}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<OrdersTopToolbar hotel={hotel} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="resources.orders.fields.room"
          source="roomId"
          reference="rooms"
          link={false}
          sortable={false}
        >
          <TextField source="code" />
        </ReferenceField>
        <ReferenceField
          label="resources.orders.fields.category"
          source="categoryId"
          reference="categories"
          link={false}
          sortable={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <OrderItemsField source="orderItemIds" sortable={false} />
        <TestOrderField source="testOrder" />
        <ColoredStatusField source="state" />
        <PriceWithCurrency source="total" />
        <DateField
          source="createdAt"
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <FulfillmentTimeField
          source="fulfillmentTime"
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <CustomShowButton />
      </Datagrid>
    </List>
  ) : (
    <Loading />
  );
};

export default OrderList;
