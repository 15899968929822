import { useCallback, useState } from 'react';
import {
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { AppVersionChannel } from '../../common/enums';
import { TempUploadRecord } from '../../common/types';
import CreateViewToolbar from '../CreateViewToolbar';
import FileInput, { UploadedFile } from '../FileInput';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';

const AppVersionFileInput = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { setValue } = useFormContext();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setIsUploading(true);

        if (!acceptedFiles.length) return;

        const [fileToUpload] = acceptedFiles;

        const {
          data: tempFile,
        }: { data: TempUploadRecord } = await dataProvider.tempUpload(
          fileToUpload
        );

        setValue('file', tempFile.uuid);
        setUploadedFiles([{ ...tempFile, name: fileToUpload.name }]);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);

        notify(error?.message || 'ra.page.error', { type: 'warning' });
      } finally {
        setIsUploading(false);
      }
    },
    [setValue, dataProvider, notify]
  );

  const onDelete = useCallback(() => {
    setUploadedFiles([]);
    setValue('file', null);
  }, [setValue]);

  return (
    <FileInput
      multiple={false}
      onDrop={onDrop}
      onDelete={onDelete}
      isLoading={isUploading}
      files={uploadedFiles}
    />
  );
};

const channelChoices = Object.values(
  AppVersionChannel
).map((channel, index) => ({ id: index, name: channel }));

const AppVersionCreate = (props: CreateProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Create {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<CreateViewToolbar />}
      type="create"
    >
      <ReferenceInput
        label="resources.apps.name"
        source="appId"
        reference="apps"
      >
        <SelectInput
          optionText="name"
          variant="outlined"
          validate={required()}
        />
      </ReferenceInput>
      <AppVersionFileInput />
      <TextInput
        source="versionName"
        variant="outlined"
        validate={required()}
      />
      <NumberInput
        min={0}
        source="versionCode"
        variant="outlined"
        validate={required()}
      />
      <TextInput source="mainClass" variant="outlined" validate={required()} />
      <SelectInput
        source="channel"
        variant="outlined"
        optionValue="name"
        defaultValue={AppVersionChannel.Production}
        validate={required()}
        options={{ fullWidth: true }}
        choices={channelChoices}
        translateChoice={false}
      />
    </SimpleFormWithServerSideFieldValidation>
  </Create>
);

export default AppVersionCreate;
