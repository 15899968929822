import {
  CreateButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ListProps,
  TextField,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  ToolbarProps,
} from 'react-admin';
import ListPagination from '../ListPagination';
import ReleaseChannelChip from '../ReleaseChannelChip';

const AppFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="version_name_or_version_code_or_id_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const AppVersionListViewTopToolbar = (props: ToolbarProps) => {
  const { filterValues, resource } = useListContext();
  const appId = (filterValues as any).app_id_in || null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TopToolbar {...sanitizeListRestProps(props)}>
      <CreateButton
        to={{ pathname: `/${resource}/create`, state: { record: { appId } } }}
        variant="contained"
        size="medium"
      />
    </TopToolbar>
  );
};

const AppList = (props: ListProps) => (
  <List
    exporter={false}
    filters={<AppFilter />}
    pagination={<ListPagination />}
    perPage={50}
    actions={<AppVersionListViewTopToolbar />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="versionName" />
      <TextField source="versionCode" />
      <ReleaseChannelChip source="channel" />
      <EditButton className="text-list-button" />
    </Datagrid>
  </List>
);

export default AppList;
