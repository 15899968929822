import { MouseEvent, FocusEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Button,
  List,
  ListItem,
  TextField,
  Box,
  LinearProgress,
} from '@mui/material';
import { useGetOne, useTranslate } from 'react-admin';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CloseIcon from '@mui/icons-material/Close';
import { PriceRecord } from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import { useIntlDecimalSeparator } from '../../context/intlContext';

const PriceInputRow = ({
  price,
  onDelete,
  onChange,
}: {
  price: Partial<PriceRecord>;
  onDelete: () => void;
  onChange: (price: Partial<PriceRecord>) => void;
}) => {
  const [{ hotel }] = useGlobalContext();
  const [showPriceInputError, setPriceInputError] = useState(false);

  const t = useTranslate();
  const decimalSeparator = useIntlDecimalSeparator();

  const { isLoading, data: currency = null } = useGetOne('currencies', {
    id: hotel?.currencyId,
  });

  const handlePriceValueChange = (values: {
    formattedValue: string;
    value: string;
    floatValue: number | undefined;
  }) => {
    setPriceInputError(!values.value);

    // eslint-disable-next-line no-param-reassign
    price.value = values.floatValue;
    // eslint-disable-next-line no-param-reassign
    price.currencyId = currency?.id;
    onChange({
      ...price,
      value: values.floatValue,
    });
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) =>
    event.target.value === '0' && event.target.select();

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    onDelete();
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: { md: 'grid' },
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: '2em',
        alignItems: 'baseline',
      }}
    >
      <NumberFormat
        value={price.value}
        onValueChange={handlePriceValueChange}
        allowedDecimalSeparators={[',', '.']}
        decimalSeparator={decimalSeparator}
        decimalScale={2}
        allowNegative={false}
        isNumericString
        customInput={TextField}
        label={t('resources.items.fields.itemPrice', {
          currency: currency?.code,
        })}
        error={showPriceInputError}
        helperText={showPriceInputError ? t('ra.validation.number') : null}
        onFocus={handleInputFocus}
        variant="filled"
        required
        fullWidth
      />

      <Box>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<CloseIcon />}
          onClick={handleDelete}
          sx={{ padding: '6px 20px', marginBottom: '10px' }}
        >
          {t('resources.items.price-field.remove')}
        </Button>
      </Box>
    </Box>
  );
};

const createPrice = (): Partial<PriceRecord> => ({
  value: 0,
  currencyId: 1,
});

const ItemPriceInput = ({
  prices,
  onChange,
}: {
  prices: Partial<PriceRecord>[];
  onChange: (newPrices: Partial<PriceRecord>[]) => void;
}) => {
  const t = useTranslate();

  const onAddPrice = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    onChange([...prices, createPrice()]);
  };

  const onRemovePrice = (priceToRemove: Partial<PriceRecord>) => {
    onChange(prices.filter((price) => price !== priceToRemove));
  };

  return (
    <Box width="50%" mt="1em">
      <List component="ul">
        {prices.map((price, index) => (
          <ListItem
            component="li"
            alignItems="flex-start"
            style={{ marginLeft: '0', paddingLeft: '0' }}
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}`}
          >
            <PriceInputRow
              price={price}
              onDelete={() => onRemovePrice(price)}
              onChange={() => onChange([...prices])}
            />
          </ListItem>
        ))}
      </List>
      {prices.length === 0 ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LocalOfferIcon />}
          onClick={onAddPrice}
          sx={{ padding: '6px 20px' }}
        >
          {t('resources.items.price-field.add')}
        </Button>
      ) : null}
    </Box>
  );
};

export default ItemPriceInput;
