import { useGetList, useTranslate, useGetMany, useInput } from 'react-admin';
import { useEffect, useState } from 'react';
import { Box, LinearProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useGlobalContext } from '../../context/globalContext';
import {
  IntegrationsConfigurationRecord,
  IntegrationsRemoteReferenceRecord,
} from '../../common/types';

const ItemRemoteReferenceInput = ({
  configuration,
}: {
  configuration: IntegrationsConfigurationRecord;
}) => {
  const [{ hotel }] = useGlobalContext();
  const t = useTranslate();

  const [hasRemoteReferenceOptions, setHasRemoteReferenceOptions] = useState(
    false
  );
  const { total, isLoading } = useGetList('integrations-remote-references', {
    filter: { hotel_id_eq: hotel?.id, configuration_id_eq: configuration.id },
  });
  const [searchQuery, setSearchQuery] = useState('');
  const {
    field: { onChange, value: remoteReferenceIds },
  } = useInput({
    source: 'integrationsRemoteReferenceIds',
    defaultValue: [],
  });

  const { data: references = null } = useGetList(
    'integrations-remote-references',
    {
      filter: {
        hotel_id_eq: hotel?.id,
        configuration_id_eq: configuration.id,
        name_cont: searchQuery,
      },
    }
  );
  const { data: selectedReferences = null } = useGetMany(
    'integrations-remote-references',
    {
      ids: remoteReferenceIds,
    }
  );

  const [
    referenceValue,
    setReferenceValue,
  ] = useState<IntegrationsRemoteReferenceRecord | null>(null);

  useEffect(() => {
    if (total) {
      setHasRemoteReferenceOptions(true);
    }
  }, [total]);

  useEffect(() => {
    if (selectedReferences) {
      setReferenceValue(
        selectedReferences.find(
          (value) => value.configurationId === configuration.id
        )
      );
    }
  }, [configuration.id, selectedReferences]);

  if (isLoading) return <LinearProgress />;
  return hasRemoteReferenceOptions ? (
    <Autocomplete
      noOptionsText={
        searchQuery
          ? t('resources.items.integrations.messages.no-matches')
          : t('resources.items.integrations.messages.start-typing')
      }
      options={searchQuery && references ? references : []}
      getOptionLabel={(option) =>
        `${option?.name} (${option?.remoteIdentifier})`
      }
      value={referenceValue || null}
      onChange={(_event, newValue) => {
        setReferenceValue(newValue);
        onChange(
          newValue
            ? [newValue.id, ...remoteReferenceIds]
            : remoteReferenceIds.filter(
                (referenceId: number) => referenceId !== referenceValue?.id
              )
        );
      }}
      inputValue={searchQuery}
      onInputChange={(_event, newInputValue) => {
        setSearchQuery(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={`${t(
            'resources.items.integrations.remote-item-reference'
          )} — ${configuration.serviceName}`}
          variant="standard"
        />
      )}
    />
  ) : null;
};

const ItemRemoteReferences = () => {
  const [{ hotel }] = useGlobalContext();
  const { data, isLoading } = useGetList('integrations-configurations', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'ASC' },
    filter: { hotel_id_eq: hotel?.id },
  });

  if (isLoading) return <LinearProgress />;
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: '5em',
        rowGap: '1em',
      }}
    >
      {data?.map((configuration: IntegrationsConfigurationRecord) => (
        <ItemRemoteReferenceInput
          configuration={configuration}
          key={configuration.id}
        />
      ))}
    </Box>
  );
};

export default ItemRemoteReferences;
