import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  required,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import EditViewToolbar from '../EditViewToolbar';
import { CategoryRecord } from '../../common/types';
import CategoryConfirmationMethodInputs from './CategoryConfirmationMethodInputs';

const CategoryTitle = () => {
  const record: CategoryRecord = useRecordContext();
  return <span>Category {record ? `"${record.title}"` : ''}</span>;
};

const CategoryEdit = (props: any) => {
  const t = useTranslate();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Edit title={<CategoryTitle />} {...props}>
      <SimpleFormWithServerSideFieldValidation
        toolbar={<EditViewToolbar />}
        type="edit"
      >
        <TextInput source="title" variant="outlined" validate={required()} />
        <TextInput source="code" variant="outlined" validate={required()} />
        <BooleanInput source="enableAsap" variant="outlined" />
        <BooleanInput source="datetimeSelectable" variant="outlined" />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.datetimeSelectable && (
              <TextInput
                source="cron"
                variant="outlined"
                placeholder={t(
                  'resources.categories.fields.datetimeSelectablePlaceholder'
                )}
                helperText={t(
                  'resources.categories.fields.datetimeSelectableHelperText'
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <CategoryConfirmationMethodInputs />
      </SimpleFormWithServerSideFieldValidation>
    </Edit>
  );
};

export default CategoryEdit;
