import { useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import { getMinutesAndSecondsFromSeconds } from '../../../../utils/intl/relative-date-time';
import {
  convertToLocalAdjustedUTC,
  getAverageDurationTickValues,
  getKeenRequestType,
  PageViewsRequest,
  useKeenQuery,
} from '../../../../utils/keen';
import { useIntl } from '../../../../context/intlContext';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { SECONDS_IN_MINUTE } from '../../../../common/constants';
import {
  UsageTotalsChartData,
  UsageTotalsKeenChartProps,
} from '../../UsageTotalsKeenChart';

interface UseAverageDurationTotalDataProps {
  hotel: HotelRecord;
  timeframe: Timeframe;
}

const useAverageDurationTotalHourlyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseAverageDurationTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [averageDurationData, setAverageDurationData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    {
      data: averageDurationHourly = [],
      loading: isAverageDurationHourlyLoading,
    },
    queryAverageDurationHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    if (isEnabled) {
      queryAverageDurationHourly({
        analysisType: 'average',
        eventCollection: 'sessions',
        targetProperty: 'duration',
        groupBy: 'date.hour_of_day',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
          {
            propertyName: 'duration',
            operator: 'lte',
            propertyValue: 3600,
          },
        ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, queryAverageDurationHourly, timeframe]);

  useEffect(() => {
    if (isEnabled && !isAverageDurationHourlyLoading) {
      const hourToDataMap = averageDurationHourly.reduce(
        (
          acc: { [key: number]: { dateHourOfDay: number; result: number } },
          val: { dateHourOfDay: number; result: number }
        ) => {
          acc[val.dateHourOfDay] = val;
          return acc;
        },
        {}
      );
      const zeroFilledSessionCountHourly = [...Array(24)].map(
        (_, index) =>
          hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
      );

      const data = zeroFilledSessionCountHourly.map(
        ({ dateHourOfDay, result }) => {
          const [min, sec] = getMinutesAndSecondsFromSeconds(result);

          return {
            id: formatTime(dateHourOfDay, intl),
            value: result,
            tooltip: {
              title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
              subtitle: `${min} min ${sec} sec`,
            },
          };
        }
      );

      setAverageDurationData(data);
    }
  }, [averageDurationHourly, intl, isAverageDurationHourlyLoading, isEnabled]);

  return {
    data: averageDurationData,
    loading: isAverageDurationHourlyLoading,
  };
};
const useAverageDurationTotalDailyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseAverageDurationTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [averageDurationData, setAverageDurationData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: averageDuration = [], loading: isAverageDurationLoading },
    queryAverageDuration,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryAverageDuration({
        analysisType: 'average',
        eventCollection: 'sessions',
        targetProperty: 'duration',
        interval: 'daily',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
          {
            propertyName: 'duration',
            operator: 'lte',
            propertyValue: 3600,
          },
        ],
        timeframe:
          typeof timeframe === 'string'
            ? timeframe
            : {
                start: convertToLocalAdjustedUTC(timeframe.start),
                end: convertToLocalAdjustedUTC(timeframe.end),
              },
      });
    }
  }, [hotel.uuid, isEnabled, queryAverageDuration, timeframe]);

  useEffect(() => {
    if (isEnabled && !isAverageDurationLoading) {
      const data = averageDuration.map(
        ({ value, timeframe: averageDurationTimeframe }) => {
          const [min, sec] = getMinutesAndSecondsFromSeconds(value);
          const localAdjustedUTC = convertToLocalAdjustedUTC(
            averageDurationTimeframe.start
          );

          return {
            id: new Date(localAdjustedUTC).getDate(),
            value,
            tooltip: {
              title: intl.formatDate(new Date(localAdjustedUTC), {
                weekday: 'long',
                day: 'numeric',
                year: 'numeric',
                month: 'long',
              }),
              subtitle: `${min} min ${sec} sec`,
            },
          };
        }
      );

      setAverageDurationData(data);
    }
  }, [averageDuration, intl, isAverageDurationLoading, isEnabled]);

  return { data: averageDurationData, loading: isAverageDurationLoading };
};
const useAverageDurationTotalWeeklyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseAverageDurationTotalDataProps & { isEnabled: boolean }) => {
  const t = useTranslate();
  const [averageDurationData, setAverageDurationData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: averageDuration = [], loading: isAverageDurationLoading },
    queryAverageDuration,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryAverageDuration({
        analysisType: 'average',
        eventCollection: 'sessions',
        targetProperty: 'duration',
        interval: 'weekly',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
          {
            propertyName: 'duration',
            operator: 'lte',
            propertyValue: 3600,
          },
        ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, queryAverageDuration, timeframe]);

  useEffect(() => {
    if (isEnabled && !isAverageDurationLoading) {
      const data = averageDuration.map(({ value }, index) => {
        const [min, sec] = getMinutesAndSecondsFromSeconds(value);

        return {
          id: index + 1,
          value,
          tooltip: {
            title: `${t('settings.week')} ${index + 1}`,
            subtitle: `${min} min ${sec} sec`,
          },
        };
      });

      setAverageDurationData(data);
    }
  }, [averageDuration, isAverageDurationLoading, isEnabled, t]);

  return { data: averageDurationData, loading: isAverageDurationLoading };
};

const useAverageDurationTotalData = ({
  hotel,
  timeframe,
}: UseAverageDurationTotalDataProps) => {
  const t = useTranslate();
  const requestType = getKeenRequestType(timeframe);

  const {
    data: averageDurationTotalHourlyData,
    loading: isAverageDurationTotalHourlyDataLoading,
  } = useAverageDurationTotalHourlyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Hourly,
  });
  const {
    data: averageDurationTotalDailyData,
    loading: isAverageDurationTotalDailyDataLoading,
  } = useAverageDurationTotalDailyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Daily,
  });
  const {
    data: averageDurationTotalWeeklyData,
    loading: isAverageDurationTotalWeeklyDataLoading,
  } = useAverageDurationTotalWeeklyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Weekly,
  });

  const requestTypeToDataMap = {
    [PageViewsRequest.Hourly]: averageDurationTotalHourlyData,
    [PageViewsRequest.Daily]: averageDurationTotalDailyData,
    [PageViewsRequest.Weekly]: averageDurationTotalWeeklyData,
  };
  const requestTypeToLoadingMap = {
    [PageViewsRequest.Hourly]: isAverageDurationTotalHourlyDataLoading,
    [PageViewsRequest.Daily]: isAverageDurationTotalDailyDataLoading,
    [PageViewsRequest.Weekly]: isAverageDurationTotalWeeklyDataLoading,
  };
  const requestTypeToLabelMap = {
    [PageViewsRequest.Hourly]: t('charts.usage.legend.hours'),
    [PageViewsRequest.Daily]: t('charts.usage.legend.days'),
    [PageViewsRequest.Weekly]: t('charts.usage.legend.weeks'),
  };

  const data = requestTypeToDataMap[requestType];
  const loading = requestTypeToLoadingMap[requestType];
  const legend = requestTypeToLabelMap[requestType];
  const tickValues = getAverageDurationTickValues(
    data.map(({ value }) => +value)
  );
  const formatAxisRight = (value: number) => `${value / SECONDS_IN_MINUTE} min`;

  const chartProps: UsageTotalsKeenChartProps = {
    data,
    legend,
    tickValuesAxisRight: tickValues,
    gridYValues: tickValues,
    formatAxisRight,
  };

  return { loading, chartProps };
};

export default useAverageDurationTotalData;
