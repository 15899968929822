import { ReactChild } from 'react';
import { useLocaleState as useLanguage } from 'react-admin';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import { Language } from './common/enums';

const dateFnsLanguageMap = {
  [Language.EN]: enLocale,
  [Language.DE]: deLocale,
  [Language.ES]: esLocale,
  [Language.FR]: frLocale,
};

const MuiPickersUtilsProviderLocalized = ({
  children,
}: {
  children: ReactChild;
}) => {
  const [language] = useLanguage();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={dateFnsLanguageMap[language as Language]}
    >
      {children}
    </LocalizationProvider>
  );
};

export default MuiPickersUtilsProviderLocalized;
