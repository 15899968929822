import {
  required,
  SelectInput,
  useRecordContext,
  InputProps,
} from 'react-admin';
import { DeviceType } from '../../common/enums';
import { DeviceRecord } from '../../common/types';

const availableDeviceTypes = Object.values(DeviceType);
const limitedDeviceTypes = [DeviceType.Tablet, DeviceType.LobbyScreen];

const availableDeviceTypeChoices = availableDeviceTypes.map((type) => ({
  id: type,
  name: type,
}));
const limitedDeviceTypeChoices = [
  DeviceType.Tablet,
  DeviceType.LobbyScreen,
].map((type) => ({
  id: type,
  name: type,
}));

const DeviceTypeSelectInput = ({
  isAdmin,
  isNew,
  ...props
}: Omit<InputProps, 'source'> & { isAdmin: boolean; isNew: boolean }) => {
  const record: DeviceRecord = useRecordContext();

  const isDeviceTypeAvailable = !!record?.deviceType;
  const isLimitedDeviceType = limitedDeviceTypes.includes(record?.deviceType);
  const isOnlyAdminType = isDeviceTypeAvailable && !isLimitedDeviceType;

  if (isAdmin) {
    return (
      <SelectInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        source="deviceType"
        optionValue="name"
        choices={availableDeviceTypeChoices}
        validate={required()}
      />
    );
  }

  if (isOnlyAdminType) {
    return (
      <SelectInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        source="deviceType"
        optionValue="name"
        choices={availableDeviceTypeChoices}
        validate={required()}
        disabled={!isNew}
      />
    );
  }

  return (
    <SelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      source="deviceType"
      optionValue="name"
      choices={limitedDeviceTypeChoices}
      validate={required()}
    />
  );
};

export default DeviceTypeSelectInput;
