import {
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { AppVersionChannel } from '../../common/enums';
import { AppVersionRecord } from '../../common/types';
import EditViewToolbar from '../EditViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';

const channelChoices = Object.values(
  AppVersionChannel
).map((channel, index) => ({ id: index, name: channel }));

// eslint-disable-next-line react/require-default-props
const AppVersionTitle = () => {
  const record: AppVersionRecord = useRecordContext();
  return <span>App Version {record ? `"${record.versionName}"` : ''}</span>;
};

const AppEdit = (props: EditProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Edit title={<AppVersionTitle />} {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<EditViewToolbar />}
      type="edit"
    >
      <ReferenceInput
        label="resources.apps.name"
        source="appId"
        reference="apps"
      >
        <SelectInput optionText="name" variant="outlined" disabled />
      </ReferenceInput>
      <TextInput
        source="versionName"
        variant="outlined"
        validate={required()}
      />
      <NumberInput
        min={0}
        source="versionCode"
        variant="outlined"
        validate={required()}
      />
      <TextInput source="mainClass" variant="outlined" validate={required()} />
      <SelectInput
        source="channel"
        variant="outlined"
        optionValue="name"
        validate={required()}
        options={{ fullWidth: true }}
        choices={channelChoices}
        translateChoice={false}
      />
    </SimpleFormWithServerSideFieldValidation>
  </Edit>
);

export default AppEdit;
