import { Toolbar, ListButton, SaveButton, useCreateContext } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';

const CreateViewToolbar = () => {
  const { resource } = useCreateContext();

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <ListButton
        resource={resource}
        label="ra.action.cancel"
        icon={<ClearIcon />}
        size="medium"
        variant="contained"
        color="inherit"
      />
      <SaveButton
        size="medium"
        sx={{
          marginLeft: '10px',
        }}
        variant="contained"
        color="primary"
      />
    </Toolbar>
  );
};

export default CreateViewToolbar;
