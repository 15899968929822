import { I18nContext } from 'react-admin';
import { IntlShape } from 'react-intl';
import { useContext } from 'react';

export const useIntl = () => {
  const context = useContext(I18nContext);

  if (context === undefined) {
    throw new Error(
      'useIntl must be used within a TranslationContext provided by react-admin'
    );
  }

  return context.intl as IntlShape;
};

export const useIntlDecimalSeparator = () => {
  const intl = useIntl();

  return (intl as IntlShape)
    .formatNumberToParts(666.6)
    .find((part) => part.type === 'decimal')!.value as ',' | '.';
};
