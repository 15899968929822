import { styled } from '@mui/material/styles';

const ChartContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
});

export default ChartContainer;
