import { TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';

const ChartTabPanel = styled(TabPanel)({
  '&.MuiTabPanel-root': {
    padding: 0,
    height: 'calc(100% - 68px)',
  },
});

export default ChartTabPanel;
