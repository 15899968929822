import {
  Loading,
  required,
  TextInput,
  useGetOne,
  useRecordContext,
} from 'react-admin';

const ConfirmationMessageField = ({ response, ...props }: any) => {
  const record = useRecordContext(props);
  const { isLoading, data } = useGetOne('hotels', {
    id: record?.hotelId,
  });
  const channel = ['email', 'push'].includes(record?.confirmationType)
    ? 'Email'
    : 'Sms';
  const defaultMessage = data ? data[`default${channel}${response}`] : '';

  return isLoading ? (
    <Loading />
  ) : (
    <TextInput
      label="resources.orders.confirmationMessage"
      defaultValue={defaultMessage}
      validate={required()}
      fullWidth
      multiline
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default ConfirmationMessageField;
