import { Chip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import {
  BulkActionProps,
  BulkDeleteButton,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useDataProvider,
  useGetList,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { PageCleanType } from '../../common/enums';
import {
  ContentGroupRecord,
  DeviceRecord,
  HotelRecord,
  PageRecordLocalized,
} from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import ListPagination from '../ListPagination';
import BulkAssignContentGroupsInput from '../BulkAssignContentGroupsInput';
import ContentGroupEditableField from '../ContentGroupEditableField';
import ExternalLinkField from '../ExternalLinkField';
import ContentGroupListActions from '../ContentGroupListActions';
import { CONTENT_GROUPS_LEADER_IDS } from '../../common/constants';

const {
  REACT_APP_LEGACY_ADMIN_PANEL_HOST: LEGACY_ADMIN_PANEL_HOST,
  REACT_APP_LEGACY_FE_HOST: LEGACY_FE_HOST,
  REACT_APP_TED_HOST: TED_HOST,
} = process.env;

const PAGE_CG_CONTENT_GROUP_FILTER_KEY = 'PAGE_CG_CONTENT_GROUP_FILTER';

const PREFIX = 'StyledPageTypeChip';
const classes = {
  [PageCleanType.App]: `${PREFIX}-${PageCleanType.App}`,
  [PageCleanType.Information]: `${PREFIX}-${PageCleanType.Information}`,
  [PageCleanType.Link]: `${PREFIX}-${PageCleanType.Link}`,
  [PageCleanType.Shop]: `${PREFIX}-${PageCleanType.Shop}`,
  [PageCleanType.Survey]: `${PREFIX}-${PageCleanType.Survey}`,
  [PageCleanType.Weather]: `${PREFIX}-${PageCleanType.Weather}`,
};

const StyledPageTypeChip = styled(Chip)({
  [`&.${classes[PageCleanType.App]}`]: {
    backgroundColor: '#2B2D42',
    color: '#ffffff',
  },
  [`&.${classes[PageCleanType.Information]}`]: {
    backgroundColor: '#92DCE5',
  },
  [`&.${classes[PageCleanType.Link]}`]: {
    backgroundColor: '#F8E8C9',
  },
  [`&.${classes[PageCleanType.Shop]}`]: {
    backgroundColor: '#F7EC59',
  },
  [`&.${classes[PageCleanType.Survey]}`]: {
    backgroundColor: '#FF66D8',
  },
  [`&.${classes[PageCleanType.Weather]}`]: {
    backgroundColor: '#9860E5',
    color: '#ffffff',
  },
});

const PostBulkActionButtons = (
  props: BulkActionProps & { contentGroups: ContentGroupRecord[] }
) => {
  const { contentGroups, ...rest } = props;
  const { selectedIds } = rest;

  return (
    <Grid
      container
      spacing={2}
      style={{ marginBottom: 0 }}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <BulkAssignContentGroupsInput
          contentGroups={contentGroups}
          selectedIds={selectedIds}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </Grid>
      <Grid item>
        <BulkDeleteButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

const PageTypeChip = ({ type }: { type: PageCleanType }) => {
  const t = useTranslate();

  return (
    <StyledPageTypeChip
      className={classes[type]}
      label={t(`resources.page-cgs.types.${type}`)}
    />
  );
};

const PageList = (props: any) => {
  const t = useTranslate();
  const [{ locale }] = useGlobalContext();
  const refresh = useRefresh();
  const {
    data: contentGroupsArray = [],
    isLoading,
  } = useGetList<ContentGroupRecord>('content-groups', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
    filter: {
      id_not_in: CONTENT_GROUPS_LEADER_IDS,
    },
  });
  const dataProvider = useDataProvider();

  useEffect(() => {
    refresh();
  }, [refresh, locale]);

  const getApUrl = (record: PageRecordLocalized) =>
    `${LEGACY_ADMIN_PANEL_HOST}/#/admin/${record.hotelId}/content/categories/${record.categoryId}/pages/edit/${record.id}`;

  const getFeUrl = async (record: PageRecordLocalized) => {
    // eslint-disable-next-line prefer-const
    let [{ data: hotel }, { data: devices }] = await Promise.all([
      dataProvider.getOne<HotelRecord>('hotels', {
        id: record.hotelId,
      }),
      dataProvider.getList<DeviceRecord>('devices', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          hotel_id_in: record.hotelId,
          uuid_cont: 'SUITEPAD_TESTING',
          uuid_not_cont: 'BYOD',
        },
      }),
    ]);

    if (!devices?.length) {
      const { data: anyDevices } = await dataProvider.getList<DeviceRecord>(
        'devices',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            hotel_id_in: hotel.id,
            uuid_not_cont: 'BYOD',
          },
        }
      );

      devices = anyDevices;
    }

    if (!devices?.length) {
      return '';
    }

    const { uuid } = devices[0];

    if (hotel.frontendVersion === 'v2') {
      return `${TED_HOST}/#/${locale}/category/${record.categoryId}/page/${record.id}?uuid=${uuid}`;
    }

    return `${LEGACY_FE_HOST}/${hotel.theme}/?${uuid}#`;
  };

  const maybeStoredPageCgContentFilterValue = localStorage.getItem(
    PAGE_CG_CONTENT_GROUP_FILTER_KEY
  );
  const filterDefaultValues = maybeStoredPageCgContentFilterValue
    ? JSON.parse(maybeStoredPageCgContentFilterValue)
    : undefined;

  return (
    <List
      exporter={false}
      actions={
        <ContentGroupListActions
          localStorageKey={PAGE_CG_CONTENT_GROUP_FILTER_KEY}
          contentGroups={contentGroupsArray}
        />
      }
      filterDefaultValues={filterDefaultValues}
      pagination={<ListPagination />}
      bulkActionButtons={
        <PostBulkActionButtons contentGroups={contentGroupsArray} />
      }
      perPage={50}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField<PageRecordLocalized>
          label="resources.page-cgs.fields.cleanType"
          source="cleanType"
          sortable
          render={(record?) =>
            record?.cleanType && <PageTypeChip type={record.cleanType} />
          }
        />
        <ReferenceField
          label="resources.page-cgs.fields.category"
          source="categoryId"
          reference="categories"
          link={false}
          sortable
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" />
        <ContentGroupEditableField
          label="resources.page-cgs.fields.contentGroup"
          loading={isLoading}
          options={contentGroupsArray}
          source="contentGroupId"
          sortable
        />
        <ReferenceField
          label="resources.page-cgs.fields.hotel"
          source="hotelId"
          reference="hotels"
          link={false}
          sortable
        >
          <TextField source="name" />
        </ReferenceField>
        <ExternalLinkField<PageRecordLocalized>
          getUrl={getApUrl}
          linkText={t('resources.page-cgs.external-link.admin-panel.name')}
        />
        <ExternalLinkField<PageRecordLocalized>
          getUrl={getFeUrl}
          linkText={t('resources.page-cgs.external-link.front-end.name')}
        />
      </Datagrid>
    </List>
  );
};

export default PageList;
