import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'rooms',
};

export default {
  name: 'rooms',
  singularName: 'room',
  payloadKey: 'rooms',
  singularPayloadKey: 'room',
  localized: false,
} as DataResourceOptions;
