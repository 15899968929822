import { MouseEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ArrowDropDown,
  ArrowDropUp,
  Delete,
  Description,
  DeviceHub,
  FlashOn,
  History,
  PowerSettingsNew,
  TabletAndroid,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useDataProvider, useNotify } from 'react-admin';

const StyledDivider = styled(Divider)({
  '&.MuiDivider-root': {
    margin: '12px 0',
  },
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))({
  '& .MuiMenu-paper': {
    border: '1px solid #d3d4d5',
  },
});

const DeviceListRowMenuButton = ({ isAdmin }: { isAdmin: boolean }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const sendAMQPAction = async (actionName: string) => {
    try {
      await dataProvider.sendAMQPMessage('apk', { action: actionName });
      notify(`resources.devices.amqp.success`, {
        type: 'info',
        messageArgs: { action: actionName },
      });
      setAnchorEl(null);
    } catch {
      notify(`resources.devices.amqp.error`, {
        type: 'warning',
        messageArgs: { action: actionName },
      });
    }
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleClick}
        startIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        More
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          key="update-manifest"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            sendAMQPAction('updateManifest');
          }}
        >
          <ListItemIcon>
            <Description fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Update manifest" />
        </MenuItem>
        <MenuItem
          key="reset-manifest"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            sendAMQPAction('resetManifest');
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Reset manifest" />
        </MenuItem>
        <MenuItem
          key="run-device-manager"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            sendAMQPAction('runDeviceManager');
          }}
        >
          <ListItemIcon>
            <DeviceHub fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Run device manager" />
        </MenuItem>
        <MenuItem
          key="reboot"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            sendAMQPAction('reboot');
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Reboot" />
        </MenuItem>
        {isAdmin && [
          <StyledDivider key="divider" />,
          <MenuItem key="history">
            <ListItemIcon>
              <History fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="History" />
          </MenuItem>,
          <MenuItem key="administer-device">
            <ListItemIcon>
              <TabletAndroid fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Administer device" />
          </MenuItem>,
          <MenuItem key="pings">
            <ListItemIcon>
              <FlashOn fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Pings" />
          </MenuItem>,
        ]}
      </StyledMenu>
    </div>
  );
};

export default DeviceListRowMenuButton;
