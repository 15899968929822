import {
  CreateParams,
  DataProvider,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyParams,
  GetOneParams,
  HttpError,
  Identifier,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import { HotelRecord, TempUploadRecord } from '../common/types';
import { resourceConfig } from '../resources';
import { camelizeObjectKeys, decamelizeObjectKeys } from '../utils/object';
import DataResource from './dataResource';

export interface CloneParams<T = any> {
  id: string | number;
  data: T;
}

const {
  REACT_APP_API_HOST: apiHost,
  REACT_APP_API_NAMESPACE: apiNamespace,
} = process.env;

export class DataAdapter {
  host = apiHost;

  namespace = apiNamespace;

  httpClient = fetchUtils.fetchJson;

  resources: { [key: string]: DataResource };

  // eslint-disable-next-line class-methods-use-this
  get headers() {
    const authToken = localStorage.getItem('authToken');
    const clientId = localStorage.getItem('clientId');

    // We need to apply `x-hotel-id` for the API v3 to work properly,
    // otherwise, the response times for /pages endpoint are too long.
    // See https://jaws.suitepad.systems/youtrack/issue/apan-329/Send-the-currently-selected-hotel-ID-as-header-with-every-request
    const rawHotel = localStorage.getItem('hotel');
    const storedHotelId = rawHotel
      ? (JSON.parse(rawHotel) as HotelRecord).id
      : '';

    const matches = window.location.hash.matchAll(
      /(hotel_id=)(?<hotel_id_match>.*?)(?=&|$)/gi
    );
    const match = Array.from(matches)[0];
    const { hotel_id_match: hotelIdMatch = '' } = match?.groups || {};

    const hotelIdIntent = hotelIdMatch || '';

    const hotelId = hotelIdIntent || storedHotelId;

    return new Headers({
      Accept: 'application/json',
      Authorization: `Token ${authToken};client_id=${clientId}`,
      'x-hotel-id': `${hotelId}`,
    });
  }

  constructor() {
    this.resources = Object.values(resourceConfig).reduce(
      (acc: { [key: string]: DataResource }, config) => {
        acc[config.name] = new DataResource(this, config);

        return acc;
      },
      {}
    );
  }

  buildUrl(
    options: {
      resource?: string;
      localized?: boolean;
      id?: Identifier;
    } = {}
  ) {
    const { resource, localized, id } = options;
    const locale = localStorage.getItem('locale');

    const orderedParts = {
      apiUrl: `${this.host}/${this.namespace}`,
      locale: localized ? locale : undefined,
      model: resource,
      id,
    };

    const orderedUrlParts = Object.values(orderedParts).filter((part) => part);

    return orderedUrlParts.join('/');
  }

  fetchRequest(url: string, options: fetchUtils.Options = {}) {
    return this.httpClient(url, { headers: this.headers, ...options });
  }

  getResource(pluralKebabCasedResourceName: string) {
    if (!(pluralKebabCasedResourceName in this.resources)) {
      throw new Error(
        `You tried to access nonexisting "${pluralKebabCasedResourceName}" resource! Did you forget to add it to the data provider configuration?`
      );
    }

    return this.resources[pluralKebabCasedResourceName];
  }

  async getList(resource: string, params: GetListParams) {
    return this.getResource(resource).getList(params);
  }

  async getOne(resource: string, params: GetOneParams) {
    return this.getResource(resource).getOne(params);
  }

  async create(resource: string, params: CreateParams) {
    return this.getResource(resource).create(params);
  }

  async clone(resource: string, params: CloneParams) {
    return this.getResource(resource).clone(params);
  }

  async update(resource: string, params: UpdateParams) {
    return this.getResource(resource).update(params);
  }

  async delete(resource: string, params: DeleteParams) {
    return this.getResource(resource).delete(params);
  }

  async getMany(resource: string, params: GetManyParams) {
    return this.getResource(resource).getMany(params);
  }

  async updateMany(resource: string, params: UpdateManyParams) {
    return this.getResource(resource).updateMany(params);
  }

  async deleteMany(resource: string, params: GetManyParams) {
    return this.getResource(resource).deleteMany(params);
  }

  async getRevenue(selectedHotelId: number) {
    const url = `${this.buildUrl()}/revenue?hotel_id=${selectedHotelId}`;
    const headers = new Headers({
      Authorization: this.headers.get('Authorization') as string,
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const response = await fetch(url, { headers });
    const { status, statusText } = response;

    if (status < 200 || status >= 300) {
      return Promise.reject(new HttpError(statusText, status));
    }

    const blob = await response.blob();

    return { data: blob };
  }

  // TODO: replace with real implementation after the BE is ready
  // eslint-disable-next-line class-methods-use-this
  async sendAMQPMessage(type: string, payload: object) {
    return Promise.resolve({ data: { type, payload } });
  }

  async orderResponse(response: string, id: number, data: any) {
    const url = `${this.buildUrl()}/orders/${id}/${response}`;
    const { json: responseData } = await this.fetchRequest(url, {
      method: 'PATCH',
      body: JSON.stringify(decamelizeObjectKeys(data)),
    });

    return { order: responseData.order };
  }

  async silentOrderResponse(response: string, id: number) {
    const url = `${this.buildUrl()}/orders/${id}/${response}`;
    const { json: responseData } = await this.fetchRequest(url, {
      method: 'PATCH',
      body: JSON.stringify(
        decamelizeObjectKeys({
          order: { confirmationMessage: null },
          skipGuestNotification: true,
        })
      ),
    });

    return { order: responseData.order };
  }

  async confirmCheckoutWithAttachments(
    id: number,
    confirmationMessage: string,
    attachments: any
  ) {
    const url = `${this.buildUrl()}/orders/${id}/confirm`;
    const { json: responseData } = await this.fetchRequest(url, {
      method: 'PATCH',
      body: JSON.stringify(
        decamelizeObjectKeys({
          order: { confirmationMessage },
          attachments,
        })
      ),
    });

    return { data: responseData.order };
  }

  async sendPaymentEmail(id: number, hotelId: number, data: any) {
    const url = `${this.buildUrl()}/orders/${id}/request_payment?hotel_id=${hotelId}`;
    await this.fetchRequest(url, {
      method: 'PATCH',
      body: JSON.stringify(decamelizeObjectKeys(data)),
    });

    return {
      data: {},
    };
  }

  async tempUpload(file: File) {
    const url = `${this.buildUrl()}/temp_uploads`;
    const formData = new FormData();
    formData.append('temp_upload[file]', file, file.name);

    const { json: responseData } = await this.fetchRequest(url, {
      method: 'POST',
      body: formData,
    });

    return {
      data: camelizeObjectKeys(responseData.temp_upload) as TempUploadRecord,
    };
  }

  async logoUpload(id: number, title: string, file: File) {
    const url = `${this.buildUrl()}/room_control/tv_channels/${id}`;
    const formData = new FormData();

    formData.set('room_control/tv_channel[title]', title);
    formData.set('room_control/tv_channel[logo]', file, file.name);

    const { json: responseData } = await this.fetchRequest(url, {
      method: 'PATCH',
      body: formData,
    });

    return {
      data: camelizeObjectKeys(responseData['room_control/tv_channel']),
    };
  }

  async resourceUsage(resource: string, id: number) {
    const url = `${this.buildUrl()}/${resource}/${id}/usage`;
    const headers = new Headers({
      Authorization: this.headers.get('Authorization') as string,
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const responseData = await this.fetchRequest(url, { headers });

    return { data: camelizeObjectKeys(responseData.json) };
  }
}

export default (new DataAdapter() as unknown) as DataProvider;
