import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { getDaysFromTimeframe, useKeenQuery } from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const usePageViewsHourlyAverageData = ({
  hotel,
  timeframe,
  selectedCategory,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}) => {
  const t = useTranslate();
  const intl = useIntl();
  const daysInTimeFrame = getDaysFromTimeframe(timeframe);

  const [
    { data: pageViewsHourly = [], loading: isPageViewsHourlyLoading },
    queryPageViewsHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    queryPageViewsHourly({
      analysisType: 'count',
      eventCollection: 'page_views',
      groupBy: 'date.hour_of_day',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
          ],
      timeframe,
    });
  }, [hotel.uuid, queryPageViewsHourly, timeframe, selectedCategory]);

  const hourToDataMap = pageViewsHourly.reduce(
    (
      acc: { [key: number]: { dateHourOfDay: number; result: number } },
      val: { dateHourOfDay: number; result: number }
    ) => {
      acc[val.dateHourOfDay] = val;
      return acc;
    },
    {}
  );
  const zeroFilledPageViewsHourly = [...Array(24)].map(
    (_, index) => hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
  );

  const pageViewsHourlyAverage = zeroFilledPageViewsHourly.map(
    ({ dateHourOfDay, result }) => ({
      dateHourOfDay,
      result: +(result / daysInTimeFrame).toFixed(2),
    })
  );

  const pageViewsHourlyData = pageViewsHourlyAverage.map(
    ({ dateHourOfDay, result }) => ({
      id: formatTime(dateHourOfDay, intl),
      value: result,
      tooltip: {
        title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
        subtitle: intl.formatNumber(result, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const chartProps: UsageTotalsKeenChartProps = {
    data: pageViewsHourlyData,
    legend: t('charts.usage.legend.hours'),
  };

  return { chartProps, loading: isPageViewsHourlyLoading };
};

export default usePageViewsHourlyAverageData;
