import {
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';

const ListViewTopToolbar = (props: any) => {
  const { resource } = useListContext();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TopToolbar {...sanitizeListRestProps(props)}>
      <CreateButton resource={resource} variant="contained" size="medium" />
    </TopToolbar>
  );
};

export default ListViewTopToolbar;
