import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import {
  Identifier,
  useNotify,
  useRefresh,
  useResourceContext,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin';
import { ContentGroupRecord } from '../common/types';

const StyledAutocomplete = styled(Autocomplete)({
  '&.MuiAutocomplete-popper': { width: 'fit-content !important' },
}) as typeof Autocomplete;

const BulkAssignContentGroupsInput = ({
  contentGroups,
  selectedIds = [],
  translationKey = 'resources.page-cgs',
  ...props
}: {
  contentGroups: ContentGroupRecord[];
  selectedIds?: Identifier[];
  translationKey?: string;
}) => {
  const t = useTranslate();
  const resource = useResourceContext(props);
  const unselectAll = useUnselectAll(resource);
  const notify = useNotify();
  const refresh = useRefresh();
  const [updateMany, { isLoading }] = useUpdateMany();

  const handleChange = (
    _event: ChangeEvent<{}>,
    selectedContentGroup: ContentGroupRecord | null
  ) => {
    updateMany(
      resource,
      {
        ids: selectedIds,
        data: {
          contentGroupId: selectedContentGroup?.id || null,
        },
      },
      {
        onSuccess: () => {
          notify('ra.notification.updated', {
            type: 'success',
            messageArgs: { smart_count: selectedIds.length },
            undoable: true,
          });
          unselectAll();
          refresh();
        },
        onError: (error: string | unknown) => {
          notify(
            typeof error === 'string'
              ? error
              : (error as any)?.message || 'ra.notification.http_error',
            { type: 'warning' }
          );
        },
        mutationMode: 'undoable',
      }
    );
  };

  const noContentGroupOption = ({
    id: null,
    name: 'No content group',
  } as unknown) as ContentGroupRecord;

  const options = [noContentGroupOption, ...contentGroups];

  return (
    <StyledAutocomplete
      id="content-group-bulk-assign-autocomplete"
      style={{ minWidth: 200 }}
      size="small"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={isLoading}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          label={t(`${translationKey}.fields.contentGroup`)}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default BulkAssignContentGroupsInput;
