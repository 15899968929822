import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCircularProgress = styled(CircularProgress)({
  '&.MuiCircularProgress-colorPrimary': {
    color: '#3d3a46',
  },
});

const ChartsCircularProgress = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      height: '100%',
      width: '100%',
    }}
  >
    <StyledCircularProgress />
  </div>
);

export default ChartsCircularProgress;
