import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const {
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_DEPLOY_TARGET: DEPLOY_TARGET,
  NODE_ENV,
} = process.env;

const initSentry = () =>
  Sentry.init({
    autoSessionTracking: true,
    dsn: SENTRY_DSN,
    enabled: NODE_ENV !== 'development',
    environment: DEPLOY_TARGET,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

export default initSentry;
