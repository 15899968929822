import { useTranslate } from 'react-admin';

const GuestInfo = (props: any) => {
  const { name, value } = props;
  const t = useTranslate();

  return (
    <div
      style={{
        marginRight: '10px',
        marginBottom: '15px',
        fontSize: '14px',
      }}
    >
      <div
        style={{
          fontWeight: 600,
          marginBottom: '5px',
        }}
      >
        {t(name)}:
      </div>
      <div
        style={{
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
          hyphens: 'auto',
        }}
      >
        {value}
      </div>
    </div>
  );
};

export default GuestInfo;
