import { styled } from '@mui/material/styles';

const ChartSideList = styled('div')(({ theme }) => ({
  maxHeight: '100%',
  width: '35%',
  minWidth: '375px',
  marginBottom: '20px',
  marginRight: '30px',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: '0',
  },
}));

export default ChartSideList;
