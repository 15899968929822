import { useNotify, useTranslate } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  FormHelperText,
  ImageListItemBar,
  ImageListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

const { REACT_APP_API_HOST: API_HOST } = process.env;

const RemoveTitle = () => {
  const t = useTranslate();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }}
    >
      <CloseIcon fontSize="small" />
      <span>{t('resources.tv-channels.remove-logo')}</span>
    </Box>
  );
};

const ImageUploadInput = (props: { source: string }) => {
  const t = useTranslate();
  const notify = useNotify();
  const [isUploading, setIsUploading] = useState(false);

  const { getValues, setValue } = useFormContext();
  const logoUrl = getValues('logoUrl');

  const [fileToPreview, setFileToPreview] = useState('');
  const [highlightError, setHighlightError] = useState(false);

  useEffect(() => {
    if (logoUrl) {
      setFileToPreview(API_HOST + logoUrl);
    }
  }, [logoUrl]);

  const dimensionsValidator = (
    file: File & { width: number; height: number }
  ) => {
    if (file.width < 88 && file.height < 88) {
      setHighlightError(true);
      return {
        code: 'dimensions-incorrect',
        message: t('resources.tv-channels.logo-requirements'),
      };
    }
    setHighlightError(false);
    return null;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    // needs to be ignored because of react-dropzone types
    // @ts-ignore
    getFilesFromEvent: async (event) => {
      const files =
        (event.target as HTMLInputElement)?.files ||
        (event as DragEvent).dataTransfer?.files;
      const file = files?.[0];
      const filePromise = new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          if (image.width && image.height) {
            Object.defineProperties(file, {
              width: {
                value: image.width,
              },
              height: {
                value: image.height,
              },
            });
            resolve(file);
          }
          reject(new Error('Failed to get file dimensions from event'));
        };
        if (file) {
          image.src = URL.createObjectURL(file);
        }
      });
      return Promise.all([filePromise]);
    },
    // needs to be ignored because of react-dropzone types
    // @ts-ignore
    validator: dimensionsValidator,
    onDrop: async (acceptedFiles: File[]) => {
      try {
        setIsUploading(true);

        if (!acceptedFiles.length) return;

        const [fileToUpload] = acceptedFiles;
        setValue('logo', fileToUpload);
        setFileToPreview(URL.createObjectURL(fileToUpload));
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);

        notify(error?.message || 'ra.page.error', { type: 'warning' });
      } finally {
        setIsUploading(false);
      }
    },
  });

  const handleClick = () => {
    open();
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
      >
        <ImageListItem
          sx={{
            '& .MuiImageListItemBar-root': {
              visibility: 'hidden',
            },
            '&:hover .MuiImageListItemBar-root': {
              visibility: 'initial',
            },
          }}
        >
          <Box
            sx={{
              width: '250px',
              height: '187px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {fileToPreview && (
              <ImageListItemBar
                sx={{
                  height: '25%',
                  width: '250px',
                  cursor: 'pointer',
                  '& .MuiImageListItemBar-title': {
                    fontSize: '.9rem',
                    textAlign: 'center',
                    fontWeight: 600,
                  },
                }}
                title={<RemoveTitle />}
                onClick={() => {
                  setValue('logo', null);
                  setFileToPreview('');
                }}
              />
            )}
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getInputProps()}
            />
            {fileToPreview ? (
              <img src={fileToPreview} width="88px" alt="" />
            ) : (
              <LoadingButton
                size="small"
                color="primary"
                loading={isUploading}
                variant="contained"
                onClick={handleClick}
                sx={{ padding: '6px 20px' }}
              >
                {t('resources.tv-channels.choose-logo')}
              </LoadingButton>
            )}
          </Box>
        </ImageListItem>
      </Box>
      <FormHelperText
        sx={{ textAlign: 'center', width: '250px' }}
        error={highlightError}
      >
        {t('resources.tv-channels.logo-requirements')}
      </FormHelperText>
    </>
  );
};

export default ImageUploadInput;
