import { useEffect, useState } from 'react';
import { Loading, TextInput, useGetOne, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const FulfillmentTimeSelector = () => {
  const { getValues } = useFormContext();
  const t = useTranslate();

  const [categoryAvailability, setCategoryAvailability] = useState(
    '*/10 * * * *'
  );
  const { data, isLoading } = useGetOne('categories', {
    id: getValues('categoryId'),
  });

  useEffect(() => {
    if (data?.cron) {
      setCategoryAvailability(data.cron);
    }
  }, [data, categoryAvailability]);

  if (isLoading) return <Loading />;
  return (
    <TextInput
      source="workflowOptions.cron"
      label={t('resources.items.workflow-options.availabilityTimes')}
      helperText={t(
        'resources.items.workflow-options.descriptions.availabilityTimes',
        { cron: categoryAvailability }
      )}
      fullWidth
    />
  );
};

export default FulfillmentTimeSelector;
