import { Card, CardContent } from '@mui/material';
import { ReactNode } from 'react';

const ChartContainer = ({ children }: { children: ReactNode }) => (
  <Card
    elevation={3}
    sx={{
      height: '100%',
      width: '100%',
      borderRadius: '8px',
    }}
  >
    <CardContent
      sx={{
        padding: '0px 43px',
        '&:last-child': {
          paddingBottom: 0,
        },
      }}
    >
      {children}
    </CardContent>
  </Card>
);

export default ChartContainer;
