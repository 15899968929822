import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'prices',
};

export default {
  name: 'prices',
  singularName: 'price',
  payloadKey: 'prices',
  singularPayloadKey: 'price',
  localized: false,
} as DataResourceOptions;
