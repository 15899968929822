import { snakeCase } from 'snake-case';
import {
  GetListParams,
  PaginationPayload,
  SortPayload,
  GetOneParams,
  UpdateParams,
  DeleteParams,
  GetManyParams,
  CreateParams,
  DeleteManyParams,
  UpdateManyParams,
} from 'react-admin';
import { camelizeObjectKeys, decamelizeObjectKeys } from '../utils/object';
import type { DataAdapter, CloneParams } from './dataProvider';
import { DataResourceOptions } from '../common/types';

const sortOrderStringToPostfixMap = {
  ASC: '',
  DESC: ' desc',
};

interface DataProviderSortPayload extends SortPayload {
  order: 'ASC' | 'DESC';
}

const formatFilterParams = (params = {}): string[][] =>
  Object.entries(params).reduce((acc: string[][], [param, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map((itemValue) => [
          `filter[${snakeCase(param)}][]`,
          `${itemValue}`,
        ]),
      ];
    }
    return [...acc, [`filter[${snakeCase(param)}]`, `${value}`]];
  }, []);

const formatSortParams = ({
  field,
  order,
}: DataProviderSortPayload): string[][] => [
  [`filter[s]`, `${snakeCase(field)}${sortOrderStringToPostfixMap[order]}`],
];

const formatPaginationParams = ({
  page,
  perPage: limit,
}: PaginationPayload): string[][] => [
  ['limit', `${limit}`],
  ['offset', `${(page - 1) * limit}`],
];

export const parseParams = (params: GetListParams): URLSearchParams => {
  const { filter, pagination, sort } = params;

  const filterParams = formatFilterParams(filter);
  const paginationParams = formatPaginationParams(pagination);
  const sortParams = formatSortParams(sort as DataProviderSortPayload);

  return new URLSearchParams([
    ...filterParams,
    ...paginationParams,
    ...sortParams,
  ]);
};

export default class DataResource {
  adapter: DataAdapter;

  name: string;

  singularName: string;

  payloadKey: string;

  singularPayloadKey: string;

  localized: boolean;

  constructor(context: DataAdapter, options: DataResourceOptions) {
    const {
      name,
      singularName,
      payloadKey,
      singularPayloadKey,
      localized,
    } = options;

    this.adapter = context;
    this.name = name;
    this.singularName = singularName;
    this.payloadKey = payloadKey;
    this.singularPayloadKey = singularPayloadKey;
    this.localized = localized;
  }

  async getList(params: GetListParams) {
    const queryParams = parseParams(params);
    const url = `${this.adapter.buildUrl({
      resource: this.payloadKey,
      localized: this.localized,
    })}?${queryParams}`;
    const { json: responseData } = await this.adapter.fetchRequest(url);

    return {
      data: responseData[this.payloadKey].map(camelizeObjectKeys),
      total: responseData.meta?.total,
    };
  }

  async getOne(params: GetOneParams) {
    const url = this.adapter.buildUrl({
      resource: this.payloadKey,
      id: params.id,
      localized: this.localized,
    });
    const { json: responseData } = await this.adapter.fetchRequest(url);

    return {
      data: camelizeObjectKeys(responseData[this.singularPayloadKey]),
    };
  }

  async create(params: CreateParams) {
    const url = this.adapter.buildUrl({
      resource: this.payloadKey,
      localized: this.localized,
    });
    const { json: responseData } = await this.adapter.fetchRequest(url, {
      method: 'POST',
      body: JSON.stringify({
        [this.singularPayloadKey]: decamelizeObjectKeys(params.data),
      }),
    });

    return { data: camelizeObjectKeys(responseData[this.singularPayloadKey]) };
  }

  async clone(params: CloneParams) {
    const url = `${this.adapter.buildUrl({
      resource: this.payloadKey,
      id: params.id,
    })}/clone`;

    await this.adapter.fetchRequest(url, {
      method: 'POST',
      body: JSON.stringify(decamelizeObjectKeys(params.data)),
    });

    return { data: {} };
  }

  async update({ id, data }: UpdateParams) {
    const url = this.adapter.buildUrl({
      resource: this.payloadKey,
      localized: this.localized,
      id,
    });
    const { json: responseData } = await this.adapter.fetchRequest(url, {
      method: 'PATCH',
      body: JSON.stringify({
        [this.singularPayloadKey]: decamelizeObjectKeys(data),
      }),
    });

    return {
      data: camelizeObjectKeys(responseData[this.singularPayloadKey]),
    };
  }

  //
  // Having troubles with item being requested
  // right after it got deleted?
  //
  // Check out issue
  // https://github.com/marmelab/react-admin/issues/5541
  async delete({ id }: DeleteParams) {
    const url = this.adapter.buildUrl({ resource: this.payloadKey, id });
    const { json: responseData } = await this.adapter.fetchRequest(url, {
      method: 'DELETE',
    });

    return {
      data: { ...responseData },
    };
  }

  async getMany(params: GetManyParams) {
    const queryParams = new URLSearchParams();
    params.ids.forEach((id) => queryParams.append('filter[id_in][]', `${id}`));
    const url = `${this.adapter.buildUrl({
      resource: this.payloadKey,
      localized: this.localized,
    })}?${queryParams}`;

    const { json: responseData } = await this.adapter.fetchRequest(url);

    return {
      data: responseData[this.payloadKey].map(camelizeObjectKeys),
    };
  }

  async deleteMany(params: DeleteManyParams) {
    await Promise.all(
      params.ids.map((id) => {
        const url = this.adapter.buildUrl({
          resource: this.payloadKey,
          id,
        });

        return this.adapter.fetchRequest(url, { method: 'DELETE' });
      })
    );
    return {
      data: [],
    };
  }

  async updateMany({ ids, data }: UpdateManyParams) {
    const result = await Promise.all(
      ids.map(async (id) => {
        const { data: updateOneData } = await this.update({
          id,
          data,
          previousData: { id },
        });

        return updateOneData;
      })
    );

    return {
      data: result,
    };
  }
}
