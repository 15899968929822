import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  BooleanInput,
  AutocompleteInput,
  TextInput,
  required,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, LinearProgress } from '@mui/material';
import ItemLinkInput from './ItemLinkInput';
import ItemImageInput from './ItemImageInput';
import ItemPriceInput from './ItemPriceInput';
import ItemWorkflowOptionsInput from './ItemWorkflowOptionsInput';
import ItemRemoteReferences from './ItemRemoteReferences';
import { WorkflowCode } from '../../common/enums';
import { PriceRecord } from '../../common/types';

const ItemCustomLayout = ({
  pageId,
  pricesLoading,
  prices,
  setPrices,
}: {
  pageId: number;
  pricesLoading?: boolean;
  prices: Partial<PriceRecord>[];
  setPrices: Dispatch<SetStateAction<Partial<PriceRecord>[]>>;
}) => {
  const t = useTranslate();
  const commonWorkflowTypes = [
    WorkflowCode.InformationItem,
    WorkflowCode.RequestItem,
    WorkflowCode.ReservationItem,
  ];
  const sortedWorkflowTypes = [
    ...commonWorkflowTypes,
    ...Object.values(WorkflowCode).filter(
      (code) => !commonWorkflowTypes.includes(code)
    ),
  ];
  const workflowCodeChoices = sortedWorkflowTypes.map((code) => ({
    id: code,
    name: `resources.items.workflow.${code}`,
    type: commonWorkflowTypes.includes(code)
      ? t('resources.items.workflow-groups.common')
      : t('resources.items.workflow-groups.special'),
  }));

  const { getValues } = useFormContext();

  const [isShopPage, setIsShopPage] = useState(false);
  const { data: page = null } = useGetOne('pages', { id: pageId });

  const isRequestItem = getValues('workflow') === WorkflowCode.RequestItem;

  useEffect(() => {
    setIsShopPage(page?.cleanType === 'shop');
  }, [page, isShopPage]);

  return (
    <Box width="100%">
      <Box
        sx={{
          width: '100%',
          display: { md: 'grid' },
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: '5em',
          alignItems: 'flex-end',
        }}
      >
        <AutocompleteInput
          source="workflow"
          choices={workflowCodeChoices}
          validate={required()}
          defaultValue={WorkflowCode.InformationItem}
          filterSelectedOptions={false}
          disabled={isShopPage}
          groupBy={(option) => option.type}
          // @ts-ignore
          disableClearable
          fullWidth
        />
        <Box sx={{ display: 'flex' }}>
          <BooleanInput
            source="active"
            defaultValue
            sx={{
              '&.MuiFormGroup-root': {
                marginRight: '30px',
              },
            }}
          />
          <BooleanInput source="visible" defaultValue />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: { md: 'grid' },
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: '5em',
        }}
        mt="2em"
        mb="2em"
      >
        <Box>
          <TextInput source="title" validate={required()} fullWidth />
          <TextInput source="teaser" fullWidth />
        </Box>
        <ItemImageInput />
      </Box>
      {!isShopPage && (
        <TextInput
          source="content"
          variant="outlined"
          rows={9}
          multiline
          fullWidth
        />
      )}
      {!isRequestItem && !isShopPage && <ItemLinkInput />}
      {pricesLoading ? (
        <LinearProgress />
      ) : (
        <ItemPriceInput
          prices={prices}
          onChange={(newPrices) => setPrices(newPrices)}
        />
      )}
      <ItemWorkflowOptionsInput isShopPage={isShopPage} />
      <ItemRemoteReferences />
    </Box>
  );
};

export default ItemCustomLayout;
