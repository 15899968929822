import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'order-items',
};

export default {
  name: 'order-items',
  singularName: 'order-item',
  payloadKey: 'order_items',
  singularPayloadKey: 'order_item',
  localized: false,
} as DataResourceOptions;
