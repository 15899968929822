import {
  ChipField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';

const TvChannelFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="translations_title_or_translations_description_or_abbreviation_or_logo_ref_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const TvChannelList = (props: any) => (
  <List
    exporter={false}
    filters={<TvChannelFilter />}
    pagination={<ListPagination />}
    perPage={50}
    actions={<ListViewTopToolbar />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="abbreviation" />
      <ReferenceArrayField
        label="resources.locales.name"
        source="localeIds"
        reference="locales"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="resources.genres.name"
        source="genreIds"
        reference="genres"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField
        label="resources.tv-channels.fields.imageName"
        source="logoRef"
      />
      <EditButton className="text-list-button" />
    </Datagrid>
  </List>
);

export default TvChannelList;
