import { MouseEvent, useState } from 'react';
import {
  required,
  Button,
  TextInput,
  useNotify,
  Form,
  useRecordContext,
  FormDataConsumer,
  useDataProvider,
} from 'react-admin';

import ClearIcon from '@mui/icons-material/Clear';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HotelRecord } from '../../common/types';

const CloneHotelDialog = (props: any) => {
  const { scrollTop } = props;
  const dataProvider = useDataProvider();

  const [showDialog, setShowDialog] = useState(false);
  const toggleModal = (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();

    setShowDialog(!showDialog);
  };

  const record: HotelRecord = useRecordContext(props);
  const notify = useNotify();

  const handleSubmit = ({ name }: { name: string }) => {
    dataProvider
      .clone('hotels', { id: record.id, data: { hotel: { name } } })
      .then(() => {
        setShowDialog(false);
        notify('ra.notification.cloned', { type: 'info' });
      })
      .catch((error: any) => {
        const errorMessage = error?.message || 'ra.page.error';
        notify(errorMessage, { type: 'warning' });
      });
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={toggleModal}
        label="ra.action.clone"
        className="text-list-button"
      >
        <FileCopyOutlinedIcon />
      </Button>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            top: scrollTop,
          },
        }}
        fullWidth
        open={showDialog}
        onClose={toggleModal}
        onBackdropClick={toggleModal}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="form-dialog-title"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <DialogTitle>
          Clone {record.name} (ID: {record.id})
        </DialogTitle>
        <Form>
          <>
            <DialogContent>
              <DialogContentText>
                Please enter a new hotel name and submit the form. Once the
                cloning process is completed, you will receive an e-mail
                notification.
              </DialogContentText>
              <TextInput source="name" validate={required()} fullWidth />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={toggleModal}
                color="inherit"
                size="medium"
                variant="contained"
                label="ra.action.cancel"
              >
                <ClearIcon />
              </Button>
              <FormDataConsumer>
                {({ formData }) => (
                  <Button
                    onClick={() => handleSubmit(formData)}
                    color="primary"
                    size="medium"
                    variant="contained"
                    label="ra.action.clone"
                  >
                    <FileCopyOutlinedIcon />
                  </Button>
                )}
              </FormDataConsumer>
            </DialogActions>
          </>
        </Form>
      </Dialog>
    </>
  );
};

export default CloneHotelDialog;
