import { Typography } from '@mui/material';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import { useIntl } from '../../context/intlContext';
import { useRelativeIntlUnits } from '../../utils/intl';

const RelativeDateTimeField = (props: any) => {
  const { source, emptyText, className, options, ...rest } = props;
  const intl = useIntl();
  const record = useRecordContext(props);
  const value = record[source] || null;
  const date = value instanceof Date ? value : new Date(value);
  const [unitValue, unit] = useRelativeIntlUnits(date);

  if (!record) {
    return null;
  }

  if (!value) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : null;
  }

  const dateString = intl.formatRelativeTime(unitValue, unit, {
    style: 'long',
    ...options,
  });

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...sanitizeFieldRestProps(rest)}
    >
      {dateString}
    </Typography>
  );
};

export default RelativeDateTimeField;
