import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import { SECONDS_IN_MINUTE } from '../../../../common/constants';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { getMinutesAndSecondsFromSeconds } from '../../../../utils/intl/relative-date-time';
import {
  getAverageDurationTickValues,
  useKeenQuery,
} from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useAverageDurationHourlyAverageData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const t = useTranslate();
  const intl = useIntl();

  const [
    {
      data: averageDurationHourly = [],
      loading: isAverageDurationHourlyLoading,
    },
    queryAverageDurationHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    queryAverageDurationHourly({
      analysisType: 'average',
      eventCollection: 'sessions',
      targetProperty: 'duration',
      groupBy: 'date.hour_of_day',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
        {
          propertyName: 'duration',
          operator: 'lte',
          propertyValue: 3600,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryAverageDurationHourly, timeframe]);

  const hourToDataMap = averageDurationHourly.reduce(
    (
      acc: { [key: number]: { dateHourOfDay: number; result: number } },
      val: { dateHourOfDay: number; result: number }
    ) => {
      acc[val.dateHourOfDay] = val;
      return acc;
    },
    {}
  );
  const zeroFilledSessionCountHourly = [...Array(24)].map(
    (_, index) => hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
  );

  const averageDurationHourlyData = zeroFilledSessionCountHourly.map(
    ({ dateHourOfDay, result }) => {
      const [min, sec] = getMinutesAndSecondsFromSeconds(result);

      return {
        id: formatTime(dateHourOfDay, intl),
        value: result,
        tooltip: {
          title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
          subtitle: `${min} min ${sec} sec`,
        },
      };
    }
  );

  const tickValues = getAverageDurationTickValues(
    averageDurationHourlyData.map(({ value }) => value)
  );

  const formatAxisRight = (value: number) => `${value / SECONDS_IN_MINUTE} min`;

  const chartProps: UsageTotalsKeenChartProps = {
    data: averageDurationHourlyData,
    legend: t('charts.usage.legend.hours'),
    tickValuesAxisRight: tickValues,
    gridYValues: tickValues,
    formatAxisRight,
  };

  return {
    chartProps,
    loading: isAverageDurationHourlyLoading,
  };
};

export default useAverageDurationHourlyAverageData;
