import { Button, useTranslate } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

const SuccessState = (props: any) => {
  const {
    checkoutButtonClass,
    dialogContentClass,
    dialogMessageClass,
    toggleCheckoutModal,
  } = props;
  const t = useTranslate();
  return (
    <DialogContent className={dialogContentClass}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <DoneIcon style={{ fontSize: 40 }} />
        </Grid>
        <Grid item>
          <div className={dialogMessageClass}>
            {t('resources.orders.checkout.states.success')}
          </div>
        </Grid>
        <Grid item>
          <Button
            onClick={toggleCheckoutModal}
            color="inherit"
            size="medium"
            variant="contained"
            label="ra.action.close"
            className={checkoutButtonClass}
          >
            <ClearIcon />
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default SuccessState;
