import { useTranslate } from 'react-admin';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { endOfDay, startOfDay } from 'date-fns';
import { TimeframeAsObject } from '../../common/types';
import { useIntl } from '../../context/intlContext';
import DatePicker from './DatePicker';

const DatePickerContainer = styled('div')({
  margin: '0 32px',
});

const HeaderContainer = styled('div')({
  height: '48px',
  width: '100%',
  display: 'flex',
  borderRadius: '6px',
  backgroundColor: '#7a9bdf14',
  border: 'solid 1px #ebeaef14',
  justifyContent: 'stretch',
  alignItems: 'center',
});

const HeaderLabel = styled('label')({
  fontSize: '11px',
  fontWeight: 'bold',
  color: '#7a9bdf',
  margin: '0 20px',
});

const DateInput = styled('input')({
  fontSize: '14px',
  color: '#3d3a46',
  margin: '0 10px',

  // reset default input styles
  border: 'none',
  backgroundImage: 'none',
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

const DatePickerHeader = ({
  label,
  date,
}: {
  label: string;
  date: Date | null;
}) => {
  const intl = useIntl();
  const uuid = crypto.getRandomValues(new Uint32Array(1))[0];

  return (
    <HeaderContainer>
      <HeaderLabel htmlFor={`date-picker-header-${uuid}`}>{label}</HeaderLabel>
      <DateInput
        id={`date-picker-header-${uuid}`}
        disabled
        value={
          date
            ? intl.formatDate(date, {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
            : '-'
        }
      />
    </HeaderContainer>
  );
};

const DateRangePicker = ({
  onChange,
  timeframeAsObject,
}: {
  onChange: (dateRange: TimeframeAsObject) => void;
  timeframeAsObject: TimeframeAsObject | null;
}) => {
  const t = useTranslate();

  const [startDate, setStartDate] = useState<Date | null>(
    timeframeAsObject ? new Date(timeframeAsObject.start) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    timeframeAsObject ? new Date(timeframeAsObject.end) : null
  );

  const handleStartDateChange = (date: Date | null) => {
    const startOfDayDate = date ? startOfDay(date) : null;

    setStartDate(startOfDayDate);

    if (startOfDayDate && endDate) {
      onChange({
        start: startOfDayDate.toISOString(),
        end: endDate.toISOString(),
      });
    }
  };
  const handleEndDateChange = (date: Date | null) => {
    const endOfDayDate = date ? endOfDay(date) : null;

    setEndDate(endOfDayDate);

    if (startDate && endOfDayDate) {
      onChange({
        start: startDate.toISOString(),
        end: endOfDayDate.toISOString(),
      });
    }
  };

  return (
    <>
      <DatePickerContainer data-testid-date-range-picker-from>
        <DatePickerHeader label={t('charts.filter.from')} date={startDate} />
        <DatePicker
          date={startDate}
          range={endDate}
          isStart
          onChange={handleStartDateChange}
        />
      </DatePickerContainer>
      <DatePickerContainer data-testid-date-range-picker-to>
        <DatePickerHeader label={t('charts.filter.to')} date={endDate} />
        <DatePicker
          date={endDate}
          range={startDate}
          isEnd
          onChange={handleEndDateChange}
        />
      </DatePickerContainer>
    </>
  );
};

export default DateRangePicker;
