import {
  Create,
  ReferenceArrayInput,
  required,
  AutocompleteArrayInput,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { useCallback } from 'react';
import { Box } from '@mui/material';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import CreateViewToolbar from '../CreateViewToolbar';
import {
  TvChannelRecordLocalized,
  TvChannelGenreRecord,
  TvChannelLocaleRecord,
} from '../../common/types';
import ImageUploadInput from './ImageUploadInput';

const TvChannelCreate = (props: any) => {
  const dataProvider = useDataProvider();

  // In order to add Genre and Locale relationships to newly created TvChannel record
  // we MUST to create corresponding TvChannelGenre and TvChannelLocale records, and
  // the TvChannel record updates then itself.
  const afterSave = useCallback(
    async (
      tvChannel: TvChannelRecordLocalized,
      formData: TvChannelRecordLocalized
    ) => {
      if (formData.logo) {
        await dataProvider.logoUpload(
          tvChannel.id,
          tvChannel.title,
          formData.logo
        );
      }

      const tvChannelGenrePromises: Promise<any>[] = formData.genreIds.map(
        (genreId) =>
          dataProvider.create<TvChannelGenreRecord>('tv-channel-genres', {
            data: {
              genreId,
              roomControlTvChannelId: tvChannel.id,
            },
          })
      );
      const tvChannelLocalePromises = formData.localeIds.map((localeId) =>
        dataProvider.create<TvChannelLocaleRecord>('tv-channel-locales', {
          data: {
            localeId,
            roomControlTvChannelId: tvChannel.id,
          },
        })
      );

      await Promise.all([
        ...tvChannelGenrePromises,
        ...tvChannelLocalePromises,
      ]);
    },
    [dataProvider]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Create {...props} redirect="list">
      <SimpleFormWithServerSideFieldValidation
        afterSave={afterSave}
        toolbar={<CreateViewToolbar />}
        type="create"
        defaultValues={{
          title: '',
          description: '',
          abbreviation: '',
          hotelIds: [],
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: { md: 'grid' },
            gridTemplateColumns: '2fr 3fr',
            columnGap: '5em',
          }}
          mt="2em"
          mb="2em"
        >
          <Box>
            <TextInput
              source="title"
              variant="outlined"
              validate={required()}
              fullWidth
            />
            <TextInput source="abbreviation" variant="outlined" fullWidth />
            <ReferenceArrayInput
              label="resources.locales.name"
              source="localeIds"
              reference="locales"
              perPage={60}
              fullWidth
            >
              <AutocompleteArrayInput
                // @ts-ignore
                disableClearable
                validate={required()}
                translateChoice={false}
                optionText="name"
                variant="outlined"
                filterToQuery={(name: string) => ({ name_cont: name })}
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="resources.genres.name"
              source="genreIds"
              reference="genres"
              perPage={60}
            >
              <AutocompleteArrayInput
                // @ts-ignore
                disableClearable
                validate={required()}
                translateChoice={false}
                optionText="name"
                variant="outlined"
                filterToQuery={(name: string) => ({
                  translations_name_cont: name,
                })}
              />
            </ReferenceArrayInput>
          </Box>
          <Box>
            <ImageUploadInput source="logo" />
          </Box>
        </Box>
      </SimpleFormWithServerSideFieldValidation>
    </Create>
  );
};

export default TvChannelCreate;
