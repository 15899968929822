import {
  CreateButton,
  Datagrid,
  EditButton,
  FieldProps,
  FunctionField,
  List,
  ListProps,
  Loading,
  ToolbarProps,
  useTranslate,
  WrapperField,
} from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Typography } from '@mui/material';
import {
  IntegrationRecord,
  IntegrationsConfigurationRecord,
} from '../../common/types';
import { AVAILABLE_INTEGRATIONS } from '../../common/constants';
import { useGlobalContext } from '../../context/globalContext';

const IntegrationsConfigurationListViewTopToolbar = (_props: ToolbarProps) => (
  <CreateButton
    variant="contained"
    size="medium"
    label="resources.integrations-configurations.controls.add-new-integration"
  />
);

const IntegrationsConfigurationField = (
  props: FieldProps & { source: keyof IntegrationRecord }
) => {
  const t = useTranslate();
  const { source } = props;

  return (
    <FunctionField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      render={(record: IntegrationsConfigurationRecord) => {
        const integration = AVAILABLE_INTEGRATIONS.find(
          ({ id }) => id === record.serviceName
        );

        if (!integration) {
          return '';
        }

        if (source === 'logo') {
          return (
            <Box
              component="img"
              sx={{
                display: 'flex',
                height: '70px',
                width: '140px',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              alt={integration.systemName}
              src={integration.logo}
            />
          );
        }

        if (source === 'integrationType') {
          return t(
            `resources.integrations-configurations.integration-type.${integration[source]}`
          );
        }

        return integration[source];
      }}
    />
  );
};

const IntegrationsConfigurationList = (props: ListProps) => {
  const t = useTranslate();
  const [{ hotel }] = useGlobalContext();

  if (!hotel) return <Loading />;

  return (
    <List
      exporter={false}
      pagination={false}
      filter={{ hotel_id_in: hotel.id }}
      empty={false}
      sx={{
        '& .MuiToolbar-root': {
          marginBottom: '10px',
        },
      }}
      actions={<IntegrationsConfigurationListViewTopToolbar />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          '& .column-logo': { padding: '0' },
        }}
      >
        <IntegrationsConfigurationField source="logo" label={false} />
        <IntegrationsConfigurationField
          source="systemName"
          label="resources.integrations.fields.systemName"
          sortable={false}
        />
        <IntegrationsConfigurationField
          source="vendor"
          label="resources.integrations.fields.vendor"
          sortable={false}
        />
        <IntegrationsConfigurationField
          source="integrationType"
          label="resources.integrations.fields.integrationType"
          sortable={false}
        />
        <FunctionField
          source="enabled"
          render={(record: IntegrationsConfigurationRecord) => (
            <Typography
              component="span"
              variant="body2"
              sx={{ color: record.enabled ? '#00a152' : '#ff1744' }}
            >
              {t(
                `resources.integrations-configurations.enabled-field.${record.enabled}`
              )}
            </Typography>
          )}
        />
        <WrapperField label={false}>
          <EditButton
            icon={<SettingsIcon />}
            label="resources.integrations-configurations.controls.configure"
            className="text-list-button"
          />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default IntegrationsConfigurationList;
