import { styled } from '@mui/material/styles';

const ChartContent = styled('div')(({ theme }) => ({
  width: '65%',
  maxWidth: 'calc(100% - 375px)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export default ChartContent;
