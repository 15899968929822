import { useTranslate } from 'react-admin';
import { useEffect, useState } from 'react';
import {
  convertToLocalAdjustedUTC,
  getKeenRequestType,
  PageViewsRequest,
  useKeenQuery,
} from '../../../../utils/keen';
import { useIntl } from '../../../../context/intlContext';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import {
  UsageTotalsChartData,
  UsageTotalsKeenChartProps,
} from '../../UsageTotalsKeenChart';

interface UseOrderTotalsDataProps {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}

const useOrderTotalHourlyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled,
}: UseOrderTotalsDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [totalOrdersData, setTotalOrdersData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: ordersHourly = [], loading: isOrdersHourlyLoading },
    queryOrdersHourly,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    if (isEnabled) {
      queryOrdersHourly({
        analysisType: 'count',
        eventCollection: 'orders',
        groupBy: 'date.hour_of_day',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'test_order',
                operator: 'eq',
                propertyValue: false,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'test_order',
                operator: 'eq',
                propertyValue: false,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.id, isEnabled, queryOrdersHourly, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isOrdersHourlyLoading) {
      const hourToDataMap = ordersHourly.reduce(
        (
          acc: { [key: number]: { dateHourOfDay: number; result: number } },
          val: { dateHourOfDay: number; result: number }
        ) => {
          acc[val.dateHourOfDay] = val;
          return acc;
        },
        {}
      );
      const zeroFilledOrdersHourly = [...Array(24)].map(
        (_, index) =>
          hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
      );
      const data = zeroFilledOrdersHourly.map(({ dateHourOfDay, result }) => ({
        id: formatTime(dateHourOfDay, intl),
        value: result,
        tooltip: {
          title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
          subtitle: intl.formatNumber(result, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        },
      }));

      setTotalOrdersData(data);
    }
  }, [intl, isEnabled, isOrdersHourlyLoading, ordersHourly]);

  return {
    data: totalOrdersData,
    loading: isOrdersHourlyLoading,
  };
};

const useOrderTotalDailyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled,
}: UseOrderTotalsDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [totalOrdersData, setTotalOrdersData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: totalOrders = [], loading: isTotalOrdersLoading },
    queryTotalOrders,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryTotalOrders({
        analysisType: 'count',
        eventCollection: 'orders',
        interval: 'daily',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
            ],
        timeframe:
          typeof timeframe === 'string'
            ? timeframe
            : {
                start: convertToLocalAdjustedUTC(timeframe.start),
                end: convertToLocalAdjustedUTC(timeframe.end),
              },
      });
    }
  }, [hotel.id, isEnabled, queryTotalOrders, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isTotalOrdersLoading) {
      const data = totalOrders.map(({ value, timeframe: orderTimeframe }) => {
        const localAdjustedUTC = convertToLocalAdjustedUTC(
          orderTimeframe.start
        );

        return {
          id: new Date(localAdjustedUTC).getDate(),
          value,
          tooltip: {
            title: intl.formatDate(new Date(localAdjustedUTC), {
              weekday: 'long',
              day: 'numeric',
              year: 'numeric',
              month: 'long',
            }),
            subtitle: intl.formatNumber(value),
          },
        };
      });

      setTotalOrdersData(data);
    }
  }, [intl, isEnabled, isTotalOrdersLoading, totalOrders]);

  return { data: totalOrdersData, loading: isTotalOrdersLoading };
};

const useOrderTotalWeeklyData = ({
  hotel,
  timeframe,
  selectedCategory,
  isEnabled,
}: UseOrderTotalsDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const t = useTranslate();
  const [totalOrdersData, setTotalOrdersData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: totalOrders = [], loading: isTotalOrdersLoading },
    queryTotalOrders,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      queryTotalOrders({
        analysisType: 'count',
        eventCollection: 'orders',
        interval: 'weekly',
        filters: selectedCategory
          ? [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
              {
                propertyName: 'category_id',
                operator: 'eq',
                propertyValue: selectedCategory.id,
              },
            ]
          : [
              {
                propertyName: 'hotel_id',
                operator: 'eq',
                propertyValue: hotel.id,
              },
            ],
        timeframe,
      });
    }
  }, [hotel.id, isEnabled, queryTotalOrders, selectedCategory, timeframe]);

  useEffect(() => {
    if (isEnabled && !isTotalOrdersLoading) {
      const data = totalOrders.map(({ value }, index) => ({
        id: index + 1,
        value,
        tooltip: {
          title: `${t('settings.week')} ${index + 1}`,
          subtitle: intl.formatNumber(value),
        },
      }));
      setTotalOrdersData(data);
    }
  }, [intl, isEnabled, isTotalOrdersLoading, t, totalOrders]);

  return { data: totalOrdersData, loading: isTotalOrdersLoading };
};

const useOrderTotalsData = ({
  hotel,
  timeframe,
  selectedCategory,
}: UseOrderTotalsDataProps) => {
  const t = useTranslate();
  const requestType = getKeenRequestType(timeframe);

  const {
    data: orderTotalHourlyData,
    loading: isOrderTotalHourlyDataLoading,
  } = useOrderTotalHourlyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Hourly,
  });
  const {
    data: orderTotalDailyData,
    loading: isOrderTotalDailyDataLoading,
  } = useOrderTotalDailyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Daily,
  });
  const {
    data: orderTotalWeeklyData,
    loading: isOrderTotalWeeklyDataLoading,
  } = useOrderTotalWeeklyData({
    hotel,
    timeframe,
    selectedCategory,
    isEnabled: requestType === PageViewsRequest.Weekly,
  });

  const requestTypeToDataMap = {
    [PageViewsRequest.Hourly]: orderTotalHourlyData,
    [PageViewsRequest.Daily]: orderTotalDailyData,
    [PageViewsRequest.Weekly]: orderTotalWeeklyData,
  };
  const requestTypeToLoadingMap = {
    [PageViewsRequest.Hourly]: isOrderTotalHourlyDataLoading,
    [PageViewsRequest.Daily]: isOrderTotalDailyDataLoading,
    [PageViewsRequest.Weekly]: isOrderTotalWeeklyDataLoading,
  };
  const requestTypeToLabelMap = {
    [PageViewsRequest.Hourly]: t('charts.usage.legend.hours'),
    [PageViewsRequest.Daily]: t('charts.usage.legend.days'),
    [PageViewsRequest.Weekly]: t('charts.usage.legend.weeks'),
  };

  const data = requestTypeToDataMap[requestType];
  const loading = requestTypeToLoadingMap[requestType];
  const legend = requestTypeToLabelMap[requestType];

  const chartProps: UsageTotalsKeenChartProps = {
    data,
    legend,
    color: '#7fa6db',
  };

  return { loading, chartProps };
};

export default useOrderTotalsData;
