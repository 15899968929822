import { ResourceProps } from 'react-admin';
import FolderIcon from '@mui/icons-material/Folder';
import CategoryList from './CategoryList';
import { DataResourceOptions } from '../../common/types';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

export const resourceProps: ResourceProps = {
  name: 'categories',
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: FolderIcon,
  list: CategoryList,
};

export default {
  name: 'categories',
  singularName: 'category',
  payloadKey: 'categories',
  singularPayloadKey: 'category',
  localized: true,
} as DataResourceOptions;
