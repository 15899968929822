import {
  BooleanInput,
  Button,
  FormDataConsumer,
  Form,
  Loading,
  useGetOne,
  useTranslate,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import ConfirmationMessageField from './shared-components/ConfirmationMessageField';

const ConfirmOrderDialog = (props: any) => {
  const {
    record,
    showConfirmDialog,
    toggleConfirmModal,
    handleConfirmSubmit,
  } = props;
  const t = useTranslate();
  const { isLoading, data } = useGetOne('rooms', {
    id: record?.roomId,
  });
  const roomNumber = data ? data.code : null;

  return (
    <Dialog
      fullWidth
      open={showConfirmDialog}
      onClose={toggleConfirmModal}
      onBackdropClick={toggleConfirmModal}
      aria-labelledby="form-dialog-title"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DialogTitle>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {t('resources.orders.fields.confirmationRecipient')}
            {record.confirmationType === 'push'
              ? ` ${t(
                  'resources.orders.fields.pushConfirmationRecipient'
                )} ${roomNumber}`
              : record.confirmationRecipient}
          </>
        )}
      </DialogTitle>
      <Form>
        <>
          <DialogContent>
            <BooleanInput
              label="resources.orders.notifyGuest"
              source="notifyGuest"
              // eslint-disable-next-line react/jsx-boolean-value
              defaultValue={true}
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.notifyGuest && (
                  <ConfirmationMessageField
                    response="Confirmation"
                    source="confirmationMessage"
                  />
                )
              }
            </FormDataConsumer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={toggleConfirmModal}
              color="inherit"
              size="medium"
              variant="contained"
              label="ra.action.cancel"
            />
            <FormDataConsumer>
              {({ formData }) => (
                <Button
                  sx={{
                    backgroundColor: '#35aa47',
                    '&:hover': {
                      backgroundColor: '#1d943b',
                    },
                  }}
                  onClick={() => handleConfirmSubmit(formData)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  label="ra.action.confirm"
                />
              )}
            </FormDataConsumer>
          </DialogActions>
        </>
      </Form>
    </Dialog>
  );
};

export default ConfirmOrderDialog;
