import { InputProps } from 'react-admin';
import MonacoEditorField from './MonacoEditorField';

const MonacoEditorInput = (props: InputProps) => (
  <MonacoEditorField
    disabled={false}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default MonacoEditorInput;
