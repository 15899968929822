import { useEffect } from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  Loading,
  TextField,
  TextInput,
  useRefresh,
} from 'react-admin';
import { useGlobalContext } from '../../context/globalContext';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';
import CopyCategoryButton from './CopyCategoryButton';

const CategoryFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="title_or_code_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const CategoryList = (props: any) => {
  const [{ hotel, locale }] = useGlobalContext();
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [refresh, locale]);

  if (!hotel) return <Loading />;

  return (
    <List
      exporter={false}
      filter={{ hotel_id_eq: hotel.id }}
      filters={<CategoryFilter />}
      pagination={<ListPagination />}
      perPage={50}
      actions={<ListViewTopToolbar />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <>
          <CopyCategoryButton />
          <EditButton className="text-list-button" />
          <DeleteButton />
        </>
      </Datagrid>
    </List>
  );
};

export default CategoryList;
