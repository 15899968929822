import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'content-groups',
};

export default {
  name: 'content-groups',
  singularName: 'content-group',
  payloadKey: 'content_groups',
  singularPayloadKey: 'content_group',
  localized: false,
} as DataResourceOptions;
