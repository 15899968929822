import { Box } from '@mui/material';
import {
  InputProps,
  required,
  SelectInput,
  TextInput,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import HotelkitReferenceRequestTypesInput from './HotelkitReferenceRequestTypesInput';
import IntegrationInfo from './IntegrationInfo';

const HotelkitInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <SelectInput
        source="currentConfig.service.locale"
        choices={[
          { id: 'en', name: 'en' },
          { id: 'de', name: 'de' },
        ]}
        label="resources.integrations.fields.hotelkit.locale.label"
        helperText="resources.integrations.fields.hotelkit.locale.helperText"
        validate={required(
          'resources.integrations.fields.hotelkit.locale.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.hotelkit.timezone.label"
        helperText="resources.integrations.fields.hotelkit.timezone.helperText"
        validate={required(
          'resources.integrations.fields.hotelkit.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.customerKey"
        label="resources.integrations.fields.hotelkit.customerKey.label"
        helperText="resources.integrations.fields.hotelkit.customerKey.helperText"
        validate={required(
          'resources.integrations.fields.hotelkit.customerKey.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.publicKey"
        label="resources.integrations.fields.hotelkit.publicKey.label"
        helperText="resources.integrations.fields.hotelkit.publicKey.helperText"
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.privateKey"
        label="resources.integrations.fields.hotelkit.privateKey.label"
        helperText="resources.integrations.fields.hotelkit.privateKey.helperText"
        variant="outlined"
        fullWidth
      />
      <HotelkitReferenceRequestTypesInput />
    </Box>
  </WrapperField>
);
export default HotelkitInputs;
