import {
  Loading,
  required,
  TextInput,
  useRecordContext,
  useTranslate,
  useGetOne,
} from 'react-admin';
import { HotelRecord, OrderRecord } from '../../../common/types';

const CheckoutConfirmationMessage = (props: any) => {
  const { openPayments } = props;
  const record: OrderRecord = useRecordContext(props);
  const t = useTranslate();
  const { data, isLoading } = useGetOne<HotelRecord>('hotels', {
    id: record!.hotelId,
  });

  const defaultEmailMessage = () => {
    const checkoutConfirmationDefaultText = () => {
      const customText = data?.options?.checkoutConfirmationDefaultText;
      const defaultText = t(
        'resources.orders.checkout.checkoutConfirmationDefaultText'
      );
      return customText || defaultText;
    };
    const paymentEmailDefaultText = () => {
      const customText = data?.options?.paymentEmailDefaultText;
      const defaultText = t(
        'resources.orders.checkout.paymentEmailDefaultText'
      );
      return customText || defaultText;
    };

    return openPayments
      ? paymentEmailDefaultText()
      : checkoutConfirmationDefaultText();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <TextInput
      sx={{
        '& .MuiInputBase-root': {
          fontSize: '14px',
        },
      }}
      defaultValue={defaultEmailMessage()}
      validate={required()}
      fullWidth
      multiline
      rows={10}
      rowsMax={15}
      variant="outlined"
      label="resources.orders.checkout.emailMessage"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CheckoutConfirmationMessage;
