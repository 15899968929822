import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'tv-channel-genres',
};

export default {
  name: 'tv-channel-genres',
  singularName: 'tv-channel-genre',
  payloadKey: 'room_control/tv_channel_genres',
  singularPayloadKey: 'room_control/tv_channel_genre',
  localized: false,
} as DataResourceOptions;
