import { useState } from 'react';
import {
  Button,
  useNotify,
  useRedirect,
  useTranslate,
  useDelete,
  useDataProvider,
  useGetMany,
  Loading,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { HotelRecord } from '../../common/types';

const DeleteButton = styled(Button)({
  '&.MuiButton-root': {
    color: '#fff',
    backgroundColor: '#d84a38',
    '&:hover': {
      backgroundColor: '#bb2413',
    },
  },
});

const DeleteButtonWithDialog = ({ record }: { record: HotelRecord }) => {
  const dataProvider = useDataProvider();
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const redirect = useRedirect();
  const t = useTranslate();

  const [show, setShowDialog] = useState(false);
  const toggleDialog = () => setShowDialog(!show);

  const [isUsedByAnyHotel, setIsUsedByAnyHotel] = useState(false);
  const [hotelReferences, setHotelReferences] = useState([]);

  const handleClick = async () => {
    const { data } = await dataProvider.resourceUsage('hotels', record?.id);

    setShowDialog(true);
    setIsUsedByAnyHotel(data?.used);
    setHotelReferences(data?.details.references);
  };

  const InstallationUsageList = () => {
    const { data = [], isLoading } = useGetMany<HotelRecord>('hotels', {
      ids: hotelReferences,
    });
    if (isLoading) {
      return <Loading />;
    }
    return (
      <ul
        style={{
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        {data.map((hotel: any) => (
          <li key={hotel.id}>{hotel.name}</li>
        ))}
      </ul>
    );
  };

  const handleDelete = async () => {
    try {
      await deleteOne('hotels', { id: record.id, previousData: record });
      toggleDialog();
      notify('ra.notification.deleted', {
        type: 'info',
        messageArgs: { smart_count: 1 },
      });
      redirect('list', 'hotels');
    } catch (error: any) {
      const errorMessage = error?.message || 'ra.page.error';
      notify(errorMessage, { type: 'warning' });
    }
  };

  return (
    <>
      <DeleteButton
        label="ra.action.delete"
        onClick={handleClick}
        startIcon={<DeleteIcon />}
        variant="contained"
        size="medium"
      />
      <Dialog
        fullWidth
        open={show}
        onClose={toggleDialog}
        onBackdropClick={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {isUsedByAnyHotel ? (
              <>{t('resources.hotels.fields.installationCannotBeDeleted')}</>
            ) : (
              t('ra.message.are_you_sure')
            )}
          </DialogContentText>
          {isUsedByAnyHotel ? <InstallationUsageList /> : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDialog}
            color="inherit"
            size="medium"
            variant="contained"
            label={isUsedByAnyHotel ? 'ra.action.ok' : 'ra.action.cancel'}
          >
            {isUsedByAnyHotel ? <CheckIcon /> : <ClearIcon />}
          </Button>
          {!isUsedByAnyHotel ? (
            <DeleteButton
              onClick={handleDelete}
              size="medium"
              variant="contained"
              label="ra.action.delete"
            >
              <DeleteIcon />
            </DeleteButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteButtonWithDialog;
