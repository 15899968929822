import { useCallback } from 'react';
import {
  Edit,
  EditProps,
  ListButton,
  Loading,
  SaveButton,
  Toolbar,
  useCreate,
  useDataProvider,
  useRecordContext,
  TextField,
  Labeled,
} from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import {
  IntegrationConfigurationRevisionRecord,
  IntegrationsConfigurationRecord,
} from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import ServiceSpecificInputs from './ServiceSpecificInputs';
import DeleteButtonWithConfirmation from './DeleteButtonWithConfirmation';

const IntegrationsConfigurationEditToolbar = () => (
  <Toolbar
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Box>
      {
        // Initially, delete button was enabled for the admin users
        // but it's been decided to disable it completely
        // until backend will support soft integration configuration deletions
        // https://jaws.suitepad.systems/youtrack/issue/kraken-1154
        false && <DeleteButtonWithConfirmation />
      }
    </Box>
    <Box>
      <ListButton
        label="ra.action.cancel"
        icon={<ClearIcon />}
        size="medium"
        variant="contained"
        color="inherit"
      />
      <SaveButton
        sx={{
          marginLeft: '10px',
        }}
        size="medium"
        variant="contained"
        color="primary"
        alwaysEnable={false}
      />
    </Box>
  </Toolbar>
);

const IntegrationsConfigurationsCreateForm = () => {
  const record = useRecordContext();
  const [create] = useCreate<IntegrationConfigurationRevisionRecord>();
  const dataProvider = useDataProvider();

  const createConfigurationRevision = useCallback(
    async (
      configuration: IntegrationsConfigurationRecord,
      data: { currentConfig: object }
    ) => {
      const {
        data: [previousRevision],
      } = await dataProvider.getList<IntegrationConfigurationRevisionRecord>(
        'integrations-configuration-revisions',
        {
          filter: { configuration_id_eq: configuration.id },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'version', order: 'DESC' },
        }
      );

      return create(
        'integrations-configuration-revisions',
        {
          data: {
            integrationsConfigurationId: configuration.id,
            version: previousRevision
              ? Number(previousRevision.version) + 1
              : 1,
            config: data.currentConfig,
          },
        },
        { returnPromise: true }
      );
    },
    [create, dataProvider]
  );

  return (
    <SimpleFormWithServerSideFieldValidation
      sx={{
        '& .MuiFormControl-root': {
          marginBottom: '20px',
        },
      }}
      toolbar={<IntegrationsConfigurationEditToolbar />}
      // This is a workaround for issue with react-admin's ArrayInput component
      // That fails when no value is provided for the array.
      record={{
        ...record,
        currentConfig: {
          ...record?.currentConfig,
          service: {
            ...record?.currentConfig?.service,
            propertyIds:
              record?.currentConfig?.service?.propertyIds || undefined,
            reservationServiceIds:
              record?.currentConfig?.service?.reservationServiceIds ||
              undefined,
            revenueCenterRefs:
              record?.currentConfig?.service?.revenueCenterRefs || undefined,
          },
        },
      }}
      afterSave={createConfigurationRevision}
      defaultValues={{
        currentConfig: {
          cron: [],
          webhooks: [],
        },
      }}
      type="edit"
    >
      <ServiceSpecificInputs />
      <Labeled
        label="resources.integrations.fields.integrationUuid"
        sx={{
          marginTop: '20px',
        }}
      >
        <TextField source="integrationUuid" />
      </Labeled>
    </SimpleFormWithServerSideFieldValidation>
  );
};

const IntegrationsConfigurationEdit = (props: EditProps) => {
  const [{ hotel }] = useGlobalContext();

  if (!hotel) {
    return <Loading />;
  }

  return (
    <Edit
      title="resources.integrations-configurations.controls.edit-title"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <IntegrationsConfigurationsCreateForm />
    </Edit>
  );
};

export default IntegrationsConfigurationEdit;
