import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const StayntouchInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.authEndpointUrl"
        label="resources.integrations.fields.stayntouch.authEndpointUrl.label"
        helperText="resources.integrations.fields.stayntouch.authEndpointUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.stayntouch.authEndpointUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.apiEndpointUrl"
        label="resources.integrations.fields.stayntouch.apiEndpointUrl.label"
        helperText="resources.integrations.fields.stayntouch.apiEndpointUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.stayntouch.apiEndpointUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.clientId"
        label="resources.integrations.fields.stayntouch.clientId.label"
        helperText="resources.integrations.fields.stayntouch.clientId.helperText"
        validate={required(
          'resources.integrations.fields.stayntouch.clientId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.clientSecret"
        label="resources.integrations.fields.stayntouch.clientSecret.label"
        helperText="resources.integrations.fields.stayntouch.clientSecret.helperText"
        validate={required(
          'resources.integrations.fields.stayntouch.clientSecret.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.hotelId"
        label="resources.integrations.fields.stayntouch.hotelId.label"
        helperText="resources.integrations.fields.stayntouch.hotelId.helperText"
        validate={required(
          'resources.integrations.fields.stayntouch.hotelId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.stayntouch.timezone.label"
        helperText="resources.integrations.fields.stayntouch.timezone.helperText"
        validate={required(
          'resources.integrations.fields.stayntouch.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default StayntouchInputs;
