import { ListItem, ListItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<{ clickable?: boolean } & ListItemProps>((props) => ({
  '&.MuiListItem-root': {
    padding: '0',
    height: '32px',
    boxSizing: 'content-box',
    marginBottom: '1px',
    justifyContent: 'space-between',
    fontSize: '13px',
    color: '#3d3a46',
    borderBottom: '1px solid #f2f2f5',
    zIndex: 0,
    cursor: props?.clickable ? 'pointer' : 'auto',
  },
}));

export default ChartListItem;
