import { Box } from '@mui/material';
import {
  FunctionField,
  InputProps,
  Labeled,
  regex,
  required,
  TextInput,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const WebhookUrl = () => {
  const record = useRecordContext();

  if (!record?.id) return null;

  const urlBase = 'https://it3.suitepad.systems/v2/webhooks/sihot/';

  return (
    <Labeled label="resources.integrations.fields.asa.webhookUrl.label">
      <FunctionField render={() => urlBase + record.integrationUuid} />
    </Labeled>
  );
};

const SihotInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiEndpoint"
        label="resources.integrations.fields.sihot.apiEndpoint.label"
        helperText="resources.integrations.fields.sihot.apiEndpoint.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.sihot.apiEndpoint.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.password"
        label="resources.integrations.fields.sihot.password.label"
        helperText="resources.integrations.fields.sihot.password.helperText"
        validate={required(
          'resources.integrations.fields.sihot.password.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.user"
        label="resources.integrations.fields.sihot.user.label"
        helperText="resources.integrations.fields.sihot.user.helperText"
        validate={required(
          'resources.integrations.fields.sihot.user.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.hotelNumber"
        label="resources.integrations.fields.sihot.hotelNumber.label"
        helperText="resources.integrations.fields.sihot.hotelNumber.helperText"
        validate={[
          required(
            'resources.integrations.fields.sihot.hotelNumber.helperText'
          ),
          regex(
            /[1-9][0-9]*/,
            'resources.integrations.fields.sihot.hotelNumber.helperText'
          ),
        ]}
        variant="outlined"
        fullWidth
      />
      <WebhookUrl />
    </Box>
  </WrapperField>
);

export default SihotInputs;
