import { ResourceProps } from 'react-admin';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import DeviceEdit from './DeviceEdit';
import DeviceCreate from './DeviceCreate';
import DeviceList from './DeviceList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'devices',
  create: DeviceCreate,
  edit: DeviceEdit,
  icon: TabletAndroidIcon,
  list: DeviceList,
};

export default {
  name: 'devices',
  singularName: 'device',
  payloadKey: 'devices',
  singularPayloadKey: 'device',
  localized: false,
} as DataResourceOptions;
