import { defaultTheme } from 'react-admin';
import deepmerge from 'deepmerge';
import { Theme as MuiTheme } from '@mui/material';

const Theme = deepmerge(defaultTheme, {
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#5b91f7',
      dark: '#0362fd',
    },
    grey: {
      '300': '#e5e5e5',
      A100: '#d8d8d8',
    },
    error: {
      main: '#d84a38',
      light: '#b94a48',
      dark: '#bb2413',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
  },

  components: {
    RaList: {
      styleOverrides: {
        root: {
          '& .RaList-content': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:last-child': {
            textAlign: 'right',
            '& > .MuiButton-root': {
              marginLeft: '5px',
            },
          },
        },
        head: {
          fontWeight: 600,
        },
      },
    },
    RaDeleteWithUndoButton: {
      styleOverrides: {
        root: ({ theme }: { theme: MuiTheme }) => ({
          '&.MuiButton-contained': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
              backgroundColor: theme.palette.error.dark,
            },
          },
          '&.MuiButton-text': {
            '& > .MuiButton-startIcon': {
              marginRight: '3px',
            },
          },
        }),
      },
    },
    RaDeleteWithConfirmButton: {
      styleOverrides: {
        root: ({ theme }: { theme: MuiTheme }) => ({
          '&.MuiButton-contained': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
              backgroundColor: theme.palette.error.dark,
            },
          },
          '&.MuiButton-text': {
            '& > .MuiButton-startIcon': {
              marginRight: '3px',
            },
          },
        }),
      },
    },
    // This is a hacky way to override the styling of the DeleteButton
    // in the bulk delete view. If you ever need to adjust this further
    // you should probably extend the bulk delete toolbar and style
    // the contained button variant instead.
    RaBulkDeleteWithUndoButton: {
      styleOverrides: {
        root: ({ theme }: { theme: MuiTheme }) => ({
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
          paddingRight: '0.5em',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          '&.MuiButton-containedInherit': {
            color: '#555',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          '&.text-list-button': {
            '&:hover': {
              backgroundColor: 'rgba(91, 145, 247, 0.12)',
            },
            '& > .MuiButton-startIcon': {
              marginRight: '3px',
            },
          },
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar-toolbar': {
            backgroundColor: '#f5f5f5',
            color: '#333',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'block',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
        },
      },
    },
    RaChipField: {
      styleOverrides: {
        chip: {
          fontSize: '.7rem',
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
    },
    RaBooleanField: {
      styleOverrides: {
        root: {
          '& svg[data-testid="true"]': { color: '#00a152' },
          '& svg[data-testid="false"]': { color: '#ff1744' },
        },
      },
    },
  },
});

export default Theme;
