import { List } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartList = styled(List)({
  '&.MuiList-root': {
    height: '100%',
    overflow: 'auto',
  },
  '&.MuiList-padding': {
    paddingTop: '0',
    paddingBottom: '0',
  },
});

export default ChartList;
