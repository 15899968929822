import {
  BooleanInput,
  Create,
  CreateProps,
  FormDataConsumer,
  FormTab,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import { snakeCase } from 'snake-case';
import { useFormContext, useWatch } from 'react-hook-form';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { AVAILABLE_TIMEZONES } from '../../common/constants';
import CreateViewToolbar from '../CreateViewToolbar';
import MonacoEditorInput from '../MonacoEditorInput';
import TabbedFormWithServerSideValidation from '../TabbedFormWithServerSideValidation';

const availableTimezones = AVAILABLE_TIMEZONES.map((timezone) => ({
  id: timezone.key,
  name: timezone.name,
}));

const TransformFormValue = ({
  origin,
  transformer,
  enabled = true,
  ...props
}: {
  origin: string;
  transformer: (value: string) => string;
  enabled: boolean;
  children: ReactElement;
}) => {
  const { children } = props;
  const { props: childrenProps } = children;
  const { source } = childrenProps;

  const { setValue } = useFormContext();
  // @ts-ignore
  const value = useWatch({ name: origin, defaultValue: '' });

  useEffect(() => {
    if (enabled) {
      // @ts-ignore
      setValue(source, transformer(value));
    }
  }, [setValue, value, source, transformer, enabled]);

  return <>{cloneElement(children, { ...props, ...childrenProps })}</>;
};

const HotelCreate = (props: CreateProps) => {
  const [isCodeGenerationEnabled, setCodeGeneration] = useState(true);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Create {...props} redirect="list">
      <TabbedFormWithServerSideValidation
        type="create"
        toolbar={<CreateViewToolbar />}
      >
        <FormTab label="general">
          <BooleanInput
            source="type"
            label="resources.hotels.fields.sharedInstallation"
            defaultValue="Hotel"
            parse={(value) => (value ? 'HotelMixin' : 'Hotel')}
            format={(value) => value === 'HotelMixin'}
          />
          <TextInput source="name" variant="outlined" validate={required()} />
          <TransformFormValue
            origin="name"
            transformer={(name) => snakeCase(name)}
            enabled={isCodeGenerationEnabled}
          >
            <TextInput
              source="code"
              variant="outlined"
              validate={required()}
              onFocus={() => setCodeGeneration(false)}
            />
          </TransformFormValue>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type !== 'HotelMixin' && (
                <SelectInput
                  source="timezone"
                  choices={availableTimezones}
                  defaultValue="Berlin"
                  variant="outlined"
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
        </FormTab>

        <FormTab label="miscellaneous" path="miscellaneous">
          <MonacoEditorInput source="options.tedInitScript" />
        </FormTab>
      </TabbedFormWithServerSideValidation>
    </Create>
  );
};

export default HotelCreate;
