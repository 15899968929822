import { ResourceProps } from 'react-admin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyEdit from './CurrencyEdit';
import CurrencyCreate from './CurrencyCreate';
import CurrencyList from './CurrencyList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'currencies',
  create: CurrencyCreate,
  edit: CurrencyEdit,
  icon: AttachMoneyIcon,
  list: CurrencyList,
};

export default {
  name: 'currencies',
  singularName: 'currency',
  payloadKey: 'currencies',
  singularPayloadKey: 'currency',
  localized: false,
} as DataResourceOptions;
