import { styled } from '@mui/material/styles';
import { TabContext, TabList } from '@mui/lab';
import { ChangeEvent, memo, ReactNode, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { CategoryRecord, HotelRecord, Timeframe } from '../../common/types';
import { useKeenQuery } from '../../utils/keen';
import ChartContainer from './ChartContainer';
import { useIntl } from '../../context/intlContext';
import ThreeDotsProgress from './ThreeDotsProgress';
import UsageTotalsChartPageViews from './UsageTotalsChart/UsageTotalsChartPageViews';
import UsageTotalsChartSessions from './UsageTotalsChart/UsageTotalsChartSessions';
import UsageTotalsChartAverageDuration from './UsageTotalsChart/UsageTotalsChartAverageDuration';
import ChartMainTabPanel from './shared/ChartMainTabPanel';
import ChartTooltip from './shared/ChartTooltip';
import { getMinutesAndSecondsFromSeconds } from '../../utils/intl/relative-date-time';
import ChartMainTab from './shared/ChartMainTab';

const StyledTabList = styled(TabList)({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-evenly',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const TabLabelContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
});

const TabLabelTitle = styled('div')({
  flex: '0 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '20px',
  whiteSpace: 'nowrap',
});

const TabLabelValue = styled('div')({
  flex: '0 0 auto',
  marginLeft: '12px',
  fontSize: '32px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

const TabLabel = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string | ReactNode;
}) => (
  <ChartTooltip title={title}>
    <TabLabelContainer data-testid="tab-label">
      <TabLabelTitle>{title}</TabLabelTitle>
      <TabLabelValue>{subtitle}</TabLabelValue>
    </TabLabelContainer>
  </ChartTooltip>
);

const UsageTotalsChart = ({
  timeframe,
  hotel,
  categories = [],
}: {
  timeframe: Timeframe;
  hotel: HotelRecord;
  categories: CategoryRecord[];
}) => {
  const t = useTranslate();
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState('1');

  const sortedCategories = categories.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const [
    { data: totalPageViews, loading: isTotalPageViewsLoading },
    queryTotalPageViews,
  ] = useKeenQuery<number>();
  const [
    { data: totalSessionCount, loading: isTotalSessionCountLoading },
    queryTotalSessionCount,
  ] = useKeenQuery<number>();
  const [
    {
      data: totalAverageDurationCount,
      loading: isTotalAverageDurationCountLoading,
    },
    queryTotalAverageDurationCount,
  ] = useKeenQuery<number>();

  useEffect(() => {
    queryTotalPageViews({
      analysisType: 'count',
      eventCollection: 'page_views',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryTotalPageViews, timeframe]);
  useEffect(() => {
    queryTotalSessionCount({
      analysisType: 'count',
      eventCollection: 'sessions',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryTotalSessionCount, timeframe]);
  useEffect(() => {
    queryTotalAverageDurationCount({
      analysisType: 'average',
      eventCollection: 'sessions',
      targetProperty: 'duration',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
        {
          propertyName: 'duration',
          operator: 'lte',
          propertyValue: 3600,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryTotalAverageDurationCount, timeframe]);

  const [
    averageDurationTotalMin,
    averageDurationTotalSec,
  ] = isTotalAverageDurationCountLoading
    ? [0, 0]
    : getMinutesAndSecondsFromSeconds(totalAverageDurationCount!);

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <ChartContainer>
      <TabContext value={currentTab}>
        <StyledTabList onChange={handleChange} centered>
          <ChartMainTab
            value="1"
            label={
              <TabLabel
                title={t('charts.usage.page-views.title')}
                subtitle={
                  isTotalPageViewsLoading ? (
                    <ThreeDotsProgress />
                  ) : (
                    intl.formatNumber(totalPageViews!)
                  )
                }
              />
            }
          />
          <ChartMainTab
            value="2"
            label={
              <TabLabel
                title={t('charts.usage.sessions.title')}
                subtitle={
                  isTotalSessionCountLoading ? (
                    <ThreeDotsProgress />
                  ) : (
                    intl.formatNumber(totalSessionCount!)
                  )
                }
              />
            }
          />
          <ChartMainTab
            value="3"
            label={
              <TabLabel
                title={t('charts.usage.average-duration.title')}
                subtitle={
                  isTotalAverageDurationCountLoading ? (
                    <ThreeDotsProgress />
                  ) : (
                    `${averageDurationTotalMin}m ${averageDurationTotalSec}s`
                  )
                }
              />
            }
          />
        </StyledTabList>
        <ChartMainTabPanel value="1">
          <UsageTotalsChartPageViews
            categories={sortedCategories}
            hotel={hotel}
            timeframe={timeframe}
          />
        </ChartMainTabPanel>
        <ChartMainTabPanel value="2">
          <UsageTotalsChartSessions hotel={hotel} timeframe={timeframe} />
        </ChartMainTabPanel>
        <ChartMainTabPanel value="3">
          <UsageTotalsChartAverageDuration
            hotel={hotel}
            timeframe={timeframe}
          />
        </ChartMainTabPanel>
      </TabContext>
    </ChartContainer>
  );
};

export default memo(UsageTotalsChart);
