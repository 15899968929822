import { useInput, useTranslate } from 'react-admin';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { HOTELKIT_REQUEST_TYPES } from '../../../common/constants';

const HotelkitReferenceRequestTypesInput = () => {
  const t = useTranslate();
  const {
    field: { onChange, value: enabledGuestRequestTypes },
  } = useInput({
    source: 'currentConfig.service.enabledGuestRequestTypes',
    defaultValue: [],
  });

  return (
    <>
      <FormLabel
        sx={{
          fontSize: '0.75rem',
        }}
      >
        {t(
          'resources.integrations.fields.hotelkit.enabledGuestRequestTypes.label'
        )}
      </FormLabel>
      <FormGroup row>
        {HOTELKIT_REQUEST_TYPES.map((type: string) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={enabledGuestRequestTypes.includes(type)}
                onChange={(_event, newValue) => {
                  onChange(
                    newValue
                      ? [type, ...enabledGuestRequestTypes]
                      : enabledGuestRequestTypes.filter(
                          (enabledType: string) => enabledType !== type
                        )
                  );
                }}
              />
            }
            label={type}
            key={type}
          />
        ))}
      </FormGroup>
    </>
  );
};
export default HotelkitReferenceRequestTypesInput;
