import { styled } from '@mui/material/styles';
import { ArrayInput } from 'react-admin';

const StyledArrayInput = styled(ArrayInput)({
  marginTop: '35px',
  borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
  borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
  '& > label.MuiInputLabel-root': {
    top: '-10px',
  },
  '& li.RaSimpleFormIterator-line': {
    borderBottom: 'none',
  },
  '& .RaSimpleFormIterator-action': {
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: '15px',
    marginTop: '5px',
    '& > button.button-remove': {
      color: '#D84A38',
    },
    '& > button.button-add:focus::after': {
      opacity: 0,
    },
  },
  '& .MuiFormControl-root': {
    marginBottom: 0,
  },
});

export default StyledArrayInput;
