import { useEffect } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  sanitizeFieldRestProps,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetOne,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import OrderShowActions from './OrderShowActions';

import ColoredStatusField from './shared-components/ColoredStatusField';
import FulfillmentTimeField from './shared-components/FulfillmentTimeField';
import PriceWithCurrency from './shared-components/PriceWithCurrency';
import { getRelativeUnitsFromMilliseconds } from '../../utils/intl';
import { useIntl } from '../../context/intlContext';
import { useGlobalContext } from '../../context/globalContext';
import { OrderRecord } from '../../common/types';

const OrderTitle = () => {
  const record: OrderRecord = useRecordContext();
  const t = useTranslate();
  return (
    <span>
      {t('resources.orders.orderDetails')}{' '}
      {record ? `#${record.orderNumber}` : ''}
    </span>
  );
};

const RespondedAtField = (props: any) => {
  const record = useRecordContext(props);
  return record.respondedAt ? (
    <DateField
      options={{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <>-</>
  );
};

const ConfirmationTypeField = (props: any) => {
  const t = useTranslate();
  const { source } = props;
  const record = useRecordContext(props);
  const translatedConfirmationTypeLabel = (confirmationType: string) =>
    record[source] === confirmationType
      ? t(`resources.orders.humanConfirmationTypes.${confirmationType}`)
      : '';
  return (
    <FunctionField
      render={() => translatedConfirmationTypeLabel(record.confirmationType)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...sanitizeFieldRestProps(props)}
    />
  );
};

const ConditionalConfirmationRecipient = (props: any) => {
  const t = useTranslate();
  const record = useRecordContext(props);
  const { isLoading, data } = useGetOne('rooms', {
    id: record?.roomId,
  });
  const roomNumber = data ? data.code : null;

  if (isLoading) {
    return <Loading />;
  }
  return record.confirmationType === 'push' ? (
    <>
      {t('resources.orders.fields.pushConfirmationRecipient')} {roomNumber}
    </>
  ) : (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const OrderCart = (props: any) => {
  const orderRecord = useRecordContext(props);
  const { isLoading, data } = useGetOne('currencies', {
    id: orderRecord?.currencyId,
  });
  const currencyToShow = data ? data.code : null;

  return (
    <ReferenceArrayField
      label="resources.orders.cart"
      reference="order-items"
      source="orderItemIds"
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="resources.order-items.product"
          source="itemId"
          reference="items"
          link={false}
        >
          <FunctionField
            render={(record: any) => {
              const { title, teaser } = record;
              return title !== null ? (
                <>
                  {title}
                  <br />
                  {teaser}
                </>
              ) : null;
            }}
          />
        </ReferenceField>
        {isLoading ? (
          <Loading />
        ) : (
          <NumberField
            source="value"
            label="resources.order-items.price"
            options={{ style: 'currency', currency: currencyToShow }}
          />
        )}

        <NumberField source="quantity" label="resources.order-items.quantity" />
        {isLoading ? (
          <Loading />
        ) : (
          <NumberField
            source="totalValue"
            label="resources.order-items.total"
            options={{ style: 'currency', currency: currencyToShow }}
          />
        )}
      </Datagrid>
    </ReferenceArrayField>
  );
};

const OrderShow = (props: any) => {
  const [{ hotel, locale }] = useGlobalContext();
  const intl = useIntl();
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [locale, refresh]);

  if (!hotel) {
    return <Loading />;
  }

  return (
    <Show
      actions={<OrderShowActions hotel={hotel} />}
      title={<OrderTitle />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="resources.orders.orderDetails">
          <TextField source="orderNumber" />
          <DateField
            source="createdAt"
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <FulfillmentTimeField
            source="fulfillmentTime"
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <RespondedAtField source="respondedAt" />
          <FunctionField
            render={(record: any) => {
              const respondedAt = record.respondedAt
                ? new Date(record.respondedAt)
                : null;
              const createdAt = record.createdAt
                ? new Date(record.createdAt)
                : null;
              if (respondedAt && createdAt) {
                const [value, unit] = getRelativeUnitsFromMilliseconds(
                  respondedAt.getTime() - createdAt.getTime()
                );
                return intl.formatRelativeTime(value, unit, { style: 'long' });
              }

              return '-';
            }}
            label="resources.orders.fields.res§ponseTime"
          />
          <ColoredStatusField source="state" />
          <ReferenceField
            label="resources.orders.fields.currency"
            source="currencyId"
            reference="currencies"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <PriceWithCurrency source="total" />
        </Tab>
        <Tab label="resources.orders.customerDetails" path="customer-details">
          <ReferenceField
            label="resources.orders.fields.room"
            source="roomId"
            reference="rooms"
            link={false}
          >
            <TextField source="code" />
          </ReferenceField>
          <ReferenceField
            label="resources.orders.fields.device"
            source="deviceId"
            reference="devices"
            link={false}
          >
            <TextField source="uuid" />
          </ReferenceField>
          <ConfirmationTypeField source="confirmationType" />
          <ConditionalConfirmationRecipient source="confirmationRecipient" />
          <TextField component="pre" source="comment" />
        </Tab>
        <Tab label="resources.orders.cart" path="cart">
          <OrderCart
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />

          <PriceWithCurrency source="subTotal" />
          <PriceWithCurrency source="surcharge" />
          <PriceWithCurrency source="total" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrderShow;
