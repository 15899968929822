import { ResourceProps } from 'react-admin';
import DescriptionIcon from '@mui/icons-material/Description';
import ItemList from './ItemList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'item-cgs',
  icon: DescriptionIcon,
  list: ItemList,
};

export default {
  name: 'item-cgs',
  singularName: 'item',
  payloadKey: 'items',
  singularPayloadKey: 'item',
  localized: true,
} as DataResourceOptions;
