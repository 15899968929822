import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartMainTab = styled(Tab)({
  '&.MuiTab-root': {
    width: '100%',
    margin: '0',
    maxWidth: 'none',
    minHeight: '38px',
    flexShrink: 1,
    borderBottom: 'solid 1px #f2f2f5',
    color: '#a09cab',
    fontSize: '16px',
    textTransform: 'none',

    '&:focus::after': {
      opacity: 1,
      background: 'none',
    },
  },
  '&.Mui-selected': {
    borderBottom: 'solid 1px #575462',
    color: '#3d3a46',
  },
});

export default ChartMainTab;
