import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import { INTL_COUNT } from '../../../../common/constants';
import { useIntl } from '../../../../context/intlContext';
import { getDaysFromTimeframe, useKeenQuery } from '../../../../utils/keen';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const usePageViewsWeekdayAverageData = ({
  hotel,
  timeframe,
  selectedCategory,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}) => {
  const intl = useIntl();
  const t = useTranslate();
  const daysInTimeFrame = getDaysFromTimeframe(timeframe);

  const [
    { data: pageViewsWeekday = [], loading: isPageViewsWeekdayLoading },
    queryPageViewsWeekday,
  ] = useKeenQuery<{ dateDayOfWeek: number; result: number }[]>();

  useEffect(() => {
    queryPageViewsWeekday({
      analysisType: 'count',
      eventCollection: 'page_views',
      groupBy: 'date.day_of_week',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_uuid',
              operator: 'eq',
              propertyValue: hotel.uuid,
            },
          ],
      timeframe,
    });
  }, [hotel.uuid, queryPageViewsWeekday, timeframe, selectedCategory]);

  const dayToDataMap = pageViewsWeekday.reduce(
    (
      acc: { [key: number]: { dateDayOfWeek: number; result: number } },
      val: { dateDayOfWeek: number; result: number }
    ) => {
      acc[val.dateDayOfWeek] = val;
      return acc;
    },
    {}
  );
  const zeroFilledPageViewsWeekday = [...Array(7)].map(
    (_, index) => dayToDataMap[index] || { dateDayOfWeek: index, result: 0 }
  );

  const pageViewsWeekdayAverage = zeroFilledPageViewsWeekday.map(
    ({ dateDayOfWeek, result }) => ({
      dateDayOfWeek,
      result: +(result / daysInTimeFrame).toFixed(2),
    })
  );

  const pageViewsWeekdayAverageData = pageViewsWeekdayAverage.map(
    ({ dateDayOfWeek, result }) => ({
      id: t(`settings.day-of-week.${dateDayOfWeek}`, {
        count: INTL_COUNT.OTHER,
      }),
      value: result,
      tooltip: {
        title: t(`settings.day-of-week.${dateDayOfWeek}`, {
          count: INTL_COUNT.OTHER,
        }),
        subtitle: intl.formatNumber(result, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const chartProps: UsageTotalsKeenChartProps = {
    data: pageViewsWeekdayAverageData,
    skipEvenBottomLabels: false,
  };

  return {
    chartProps,
    loading: isPageViewsWeekdayLoading,
  };
};

export default usePageViewsWeekdayAverageData;
