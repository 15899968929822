import { Create, required, TextInput } from 'react-admin';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import CreateViewToolbar from '../CreateViewToolbar';

const CurrencyCreate = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Create {...props} redirect="list">
    <SimpleFormWithServerSideFieldValidation
      toolbar={<CreateViewToolbar />}
      type="create"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput source="code" variant="outlined" validate={required()} />
      <TextInput source="symbol" variant="outlined" validate={required()} />
    </SimpleFormWithServerSideFieldValidation>
  </Create>
);

export default CurrencyCreate;
