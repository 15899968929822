import {
  MenuItem,
  MenuList,
  Popover,
  OutlinedInput,
  FormControl,
  IconProps,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useState, MouseEvent, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import { TimeframeAsString } from '../../common/enums';
import { Timeframe, TimeframeAsObject } from '../../common/types';
import { useIntl } from '../../context/intlContext';
import DateRangePicker from './DateRangePicker';

const StyledMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    '&:first-child': {
      marginTop: '21px',
    },
    '&:last-child': {
      marginBottom: '21px',
    },
    '&:hover': {
      backgroundColor: '#7a9bdf24',
    },
    '&:hover:active': {
      backgroundColor: '#7a9bdf',
    },
    display: 'flex',
    justifyContent: 'center',
    padding: '13px 54px 12px',
    borderRadius: '4px',
    border: 'solid 1px #f2f2f5',
    margin: '3px 21px',
    fontSize: '15px',
  },
  '&.Mui-selected': {
    backgroundColor: '#7a9bdf !important',
    color: '#ffffff !important',
  },
});

const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
});

const StyledOutlinedInput = styled(OutlinedInput)({
  '&.MuiOutlinedInput-input': {
    padding: '10.5px 32px 10.5px 14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '1.1876em',

    cursor: 'pointer',
    minWidth: '16px',
    borderRadius: 0,
  },
});

const StyledArrowDropDown = styled(ArrowDropDown, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean } & IconProps>(({ open }) => ({
  '&.MuiSvgIcon-root': {
    top: 'calc(50% - 12px)',
    color: 'rgba(0, 0, 0, 0.54)',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));

const SelectWithDateRange = ({
  onSelect,
  timeframe,
}: {
  onSelect: (timeframe: Timeframe) => void;
  timeframe: Timeframe;
}) => {
  const intl = useIntl();
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<
    TimeframeAsString | 'date_range'
  >();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  useEffect(() => {
    if (typeof timeframe === 'string') {
      setSelectedOption(timeframe);
    } else {
      setSelectedOption('date_range');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: TimeframeAsString) => {
    if (showDateRangePicker) {
      setShowDateRangePicker(false);
    }

    setSelectedOption(option);
    onSelect(option);
    setAnchorEl(null);
  };

  const handleDateRangeOptionClick = () => {
    setSelectedOption('date_range');
    setShowDateRangePicker(true);
  };

  const handleDateRangeChange = (dateRange: TimeframeAsObject) => {
    onSelect(dateRange);
    setAnchorEl(null);
  };

  const buttonText =
    typeof timeframe === 'string'
      ? t(`charts.timeframe.${timeframe}`)
      : `${intl.formatDate(new Date(timeframe.start), {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })} - ${intl.formatDate(new Date(timeframe.end), {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })}`;

  return (
    <>
      <FormControl size="small">
        <StyledOutlinedInput
          data-testid="timeframe-select-trigger"
          value={buttonText}
          onMouseDown={handleButtonClick}
          style={{
            width: buttonText.length * (25 - buttonText.length * 0.55),
          }}
        />
        <StyledArrowDropDown open={Boolean(anchorEl)} />
      </FormControl>
      <StyledPopover
        id="timeframe-select"
        data-testid="timeframe-select"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        {showDateRangePicker && (
          <aside
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            data-testid="timeframe-select-date-range"
          >
            <DateRangePicker
              onChange={handleDateRangeChange}
              timeframeAsObject={
                typeof timeframe === 'string' ? null : timeframe
              }
            />
          </aside>
        )}
        <MenuList>
          {Object.values(TimeframeAsString).map((option) => (
            <StyledMenuItem
              key={option}
              value={option}
              selected={selectedOption === option}
              onClick={() => handleOptionClick(option)}
              data-testid={`timeframe-select-option-${option}`}
            >
              {t(`charts.timeframe.${option}`)}
            </StyledMenuItem>
          ))}
          <StyledMenuItem
            value="date_range"
            selected={selectedOption === 'date_range'}
            onClick={() => handleDateRangeOptionClick()}
            data-testid="timeframe-select-option-custom-range"
          >
            {t(`charts.filter.custom_range`)}
          </StyledMenuItem>
        </MenuList>
      </StyledPopover>
    </>
  );
};

export default SelectWithDateRange;
