import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AppVersionChannel } from '../common/enums';

const PREFIX = 'StyledReleaseChannelChip';
const classes = {
  [AppVersionChannel.Production]: `${PREFIX}-${AppVersionChannel.Production}`,
  [AppVersionChannel.ReleaseCandidate]: `${PREFIX}-${AppVersionChannel.ReleaseCandidate}`,
  [AppVersionChannel.Beta]: `${PREFIX}-${AppVersionChannel.Beta}`,
};

const StyledReleaseChannelChip = styled(Chip)({
  [`&.${classes[AppVersionChannel.Production]}`]: {
    backgroundColor: '#3cc051',
  },
  [`&.${classes[AppVersionChannel.ReleaseCandidate]}`]: {
    backgroundColor: '#fcb322',
    color: '#fff',
  },
  [`&.${classes[AppVersionChannel.Beta]}`]: { backgroundColor: '#ed4e2a' },
});

const ReleaseChannelChip = (props: { source: string }) => {
  const record = useRecordContext();
  const { source } = props;

  if (!record) {
    throw Error(
      "Error rendering ReleaseChannelChip component! ReleaseChannelChip component must be used within react-admin's Datagrid component."
    );
  }

  const channel: AppVersionChannel = record[source];

  return (
    <StyledReleaseChannelChip
      className={classes[channel]}
      // classes={{ root: classes[channel] }}
      label={channel}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...sanitizeFieldRestProps(props)}
    />
  );
};

export default ReleaseChannelChip;
