import { TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';

const ChartListTabList = styled(TabList)({
  '&.MuiTabs-root': {
    minHeight: '68px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export default ChartListTabList;
