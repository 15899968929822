import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Loading,
  ReferenceInput,
  required,
  TextInput,
  usePermissions,
  SelectInput,
} from 'react-admin';
import { AppVersionChannel, Role } from '../../common/enums';
import { useGlobalContext } from '../../context/globalContext';
import CreateViewToolbar from '../CreateViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import DeviceTypeSelectInput from './DeviceTypeSelectInput';

const channelChoices = Object.values(
  AppVersionChannel
).map((channel, index) => ({ id: index, name: channel }));

const DeviceCreate = (props: any) => {
  const [{ hotel }] = useGlobalContext();

  const { permissions = [], isLoading } = usePermissions();
  const isAdmin = !isLoading && permissions.includes(Role.Admin);

  return hotel ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Create {...props}>
      <SimpleFormWithServerSideFieldValidation
        toolbar={<CreateViewToolbar />}
        type="create"
        defaultValues={{
          hotel_id: hotel.id,
          active: true,
          warningsEnabled: true,
          deviceType: 'tablet',
        }}
      >
        <BooleanInput
          source="active"
          variant="outlined"
          validate={required()}
        />
        {isAdmin && (
          <SelectInput
            source="channelApk"
            variant="outlined"
            optionValue="name"
            defaultValue={AppVersionChannel.Production}
            validate={required()}
            options={{ fullWidth: true }}
            choices={channelChoices}
            translateChoice={false}
          />
        )}
        {isAdmin && (
          <BooleanInput
            source="demoDevice"
            variant="outlined"
            validate={required()}
          />
        )}
        <BooleanInput
          source="warningsEnabled"
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="uuid"
          variant="outlined"
          validate={required()}
          placeholder="4caa16fb3ee5"
        />
        <DeviceTypeSelectInput isAdmin={isAdmin} isNew />
        <ReferenceInput
          label="resources.rooms.name"
          source="roomId"
          reference="rooms"
        >
          <AutocompleteInput
            optionText="code"
            filterToQuery={(text) => ({ code_cont: text })}
          />
        </ReferenceInput>
      </SimpleFormWithServerSideFieldValidation>
    </Create>
  ) : (
    <Loading />
  );
};

export default DeviceCreate;
