import { useTranslate } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

const SendingState = (props: any) => {
  const { dialogContentClass, dialogMessageClass } = props;
  const t = useTranslate();
  return (
    <DialogContent className={dialogContentClass}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress size={40} />
        </Grid>
        <Grid item>
          <div className={dialogMessageClass}>
            {t('resources.orders.checkout.states.sending')}
          </div>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default SendingState;
