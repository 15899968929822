const availableTimezones = [
  {
    key: 'International Date Line West',
    name: '(GMT-11:00) International Date Line West',
    offset: -660,
  },
  { key: 'Midway Island', name: '(GMT-11:00) Midway Island', offset: -660 },
  { key: 'American Samoa', name: '(GMT-11:00) American Samoa', offset: -660 },
  { key: 'Hawaii', name: '(GMT-10:00) Hawaii', offset: -600 },
  { key: 'Alaska', name: '(GMT-09:00) Alaska', offset: -540 },
  {
    key: 'Pacific Time (US & Canada)',
    name: '(GMT-08:00) Pacific Time (US & Canada)',
    offset: -480,
  },
  { key: 'Tijuana', name: '(GMT-08:00) Tijuana', offset: -480 },
  {
    key: 'Mountain Time (US & Canada)',
    name: '(GMT-07:00) Mountain Time (US & Canada)',
    offset: -420,
  },
  { key: 'Arizona', name: '(GMT-07:00) Arizona', offset: -420 },
  { key: 'Chihuahua', name: '(GMT-07:00) Chihuahua', offset: -420 },
  { key: 'Mazatlan', name: '(GMT-07:00) Mazatlan', offset: -420 },
  {
    key: 'Central Time (US & Canada)',
    name: '(GMT-06:00) Central Time (US & Canada)',
    offset: -360,
  },
  { key: 'Saskatchewan', name: '(GMT-06:00) Saskatchewan', offset: -360 },
  { key: 'Guadalajara', name: '(GMT-06:00) Guadalajara', offset: -360 },
  { key: 'Mexico City', name: '(GMT-06:00) Mexico City', offset: -360 },
  { key: 'Monterrey', name: '(GMT-06:00) Monterrey', offset: -360 },
  { key: 'Central America', name: '(GMT-06:00) Central America', offset: -360 },
  {
    key: 'Eastern Time (US & Canada)',
    name: '(GMT-05:00) Eastern Time (US & Canada)',
    offset: -300,
  },
  { key: 'Indiana (East)', name: '(GMT-05:00) Indiana (East)', offset: -300 },
  { key: 'Bogota', name: '(GMT-05:00) Bogota', offset: -300 },
  { key: 'Lima', name: '(GMT-05:00) Lima', offset: -300 },
  { key: 'Quito', name: '(GMT-05:00) Quito', offset: -300 },
  {
    key: 'Atlantic Time (Canada)',
    name: '(GMT-04:00) Atlantic Time (Canada)',
    offset: -240,
  },
  { key: 'Caracas', name: '(GMT-04:30) Caracas', offset: -270 },
  { key: 'La Paz', name: '(GMT-04:00) La Paz', offset: -240 },
  { key: 'Santiago', name: '(GMT-04:00) Santiago', offset: -240 },
  { key: 'Newfoundland', name: '(GMT-03:30) Newfoundland', offset: -210 },
  { key: 'Brasilia', name: '(GMT-03:00) Brasilia', offset: -180 },
  { key: 'Buenos Aires', name: '(GMT-03:00) Buenos Aires', offset: -180 },
  { key: 'Montevideo', name: '(GMT-03:00) Montevideo', offset: -180 },
  { key: 'Georgetown', name: '(GMT-04:00) Georgetown', offset: -240 },
  { key: 'Greenland', name: '(GMT-03:00) Greenland', offset: -180 },
  { key: 'Mid-Atlantic', name: '(GMT-02:00) Mid-Atlantic', offset: -120 },
  { key: 'Azores', name: '(GMT-01:00) Azores', offset: -60 },
  { key: 'Cape Verde Is.', name: '(GMT-01:00) Cape Verde Is.', offset: -60 },
  { key: 'Dublin', name: '(GMT+00:00) Dublin', offset: 0 },
  { key: 'Edinburgh', name: '(GMT+00:00) Edinburgh', offset: 0 },
  { key: 'Lisbon', name: '(GMT+00:00) Lisbon', offset: 0 },
  { key: 'London', name: '(GMT+00:00) London', offset: 0 },
  { key: 'Casablanca', name: '(GMT+00:00) Casablanca', offset: 0 },
  { key: 'Monrovia', name: '(GMT+00:00) Monrovia', offset: 0 },
  { key: 'UTC', name: '(GMT+00:00) UTC', offset: 0 },
  { key: 'Belgrade', name: '(GMT+01:00) Belgrade', offset: 60 },
  { key: 'Bratislava', name: '(GMT+01:00) Bratislava', offset: 60 },
  { key: 'Budapest', name: '(GMT+01:00) Budapest', offset: 60 },
  { key: 'Ljubljana', name: '(GMT+01:00) Ljubljana', offset: 60 },
  { key: 'Prague', name: '(GMT+01:00) Prague', offset: 60 },
  { key: 'Sarajevo', name: '(GMT+01:00) Sarajevo', offset: 60 },
  { key: 'Skopje', name: '(GMT+01:00) Skopje', offset: 60 },
  { key: 'Warsaw', name: '(GMT+01:00) Warsaw', offset: 60 },
  { key: 'Zagreb', name: '(GMT+01:00) Zagreb', offset: 60 },
  { key: 'Brussels', name: '(GMT+01:00) Brussels', offset: 60 },
  { key: 'Copenhagen', name: '(GMT+01:00) Copenhagen', offset: 60 },
  { key: 'Madrid', name: '(GMT+01:00) Madrid', offset: 60 },
  { key: 'Paris', name: '(GMT+01:00) Paris', offset: 60 },
  { key: 'Amsterdam', name: '(GMT+01:00) Amsterdam', offset: 60 },
  { key: 'Berlin', name: '(GMT+01:00) Berlin', offset: 60 },
  { key: 'Bern', name: '(GMT+01:00) Bern', offset: 60 },
  { key: 'Rome', name: '(GMT+01:00) Rome', offset: 60 },
  { key: 'Stockholm', name: '(GMT+01:00) Stockholm', offset: 60 },
  { key: 'Vienna', name: '(GMT+01:00) Vienna', offset: 60 },
  {
    key: 'West Central Africa',
    name: '(GMT+01:00) West Central Africa',
    offset: 60,
  },
  { key: 'Bucharest', name: '(GMT+02:00) Bucharest', offset: 120 },
  { key: 'Cairo', name: '(GMT+02:00) Cairo', offset: 120 },
  { key: 'Helsinki', name: '(GMT+02:00) Helsinki', offset: 120 },
  { key: 'Kyiv', name: '(GMT+02:00) Kyiv', offset: 120 },
  { key: 'Riga', name: '(GMT+02:00) Riga', offset: 120 },
  { key: 'Sofia', name: '(GMT+02:00) Sofia', offset: 120 },
  { key: 'Tallinn', name: '(GMT+02:00) Tallinn', offset: 120 },
  { key: 'Vilnius', name: '(GMT+02:00) Vilnius', offset: 120 },
  { key: 'Athens', name: '(GMT+02:00) Athens', offset: 120 },
  { key: 'Istanbul', name: '(GMT+02:00) Istanbul', offset: 120 },
  { key: 'Minsk', name: '(GMT+03:00) Minsk', offset: 180 },
  { key: 'Jerusalem', name: '(GMT+02:00) Jerusalem', offset: 120 },
  { key: 'Harare', name: '(GMT+02:00) Harare', offset: 120 },
  { key: 'Pretoria', name: '(GMT+02:00) Pretoria', offset: 120 },
  { key: 'Moscow', name: '(GMT+04:00) Moscow', offset: 240 },
  { key: 'St. Petersburg', name: '(GMT+04:00) St. Petersburg', offset: 240 },
  { key: 'Volgograd', name: '(GMT+04:00) Volgograd', offset: 240 },
  { key: 'Kuwait', name: '(GMT+03:00) Kuwait', offset: 180 },
  { key: 'Riyadh', name: '(GMT+03:00) Riyadh', offset: 180 },
  { key: 'Nairobi', name: '(GMT+03:00) Nairobi', offset: 180 },
  { key: 'Baghdad', name: '(GMT+03:00) Baghdad', offset: 180 },
  { key: 'Tehran', name: '(GMT+03:30) Tehran', offset: 210 },
  { key: 'Abu Dhabi', name: '(GMT+04:00) Abu Dhabi', offset: 240 },
  { key: 'Muscat', name: '(GMT+04:00) Muscat', offset: 240 },
  { key: 'Baku', name: '(GMT+04:00) Baku', offset: 240 },
  { key: 'Tbilisi', name: '(GMT+04:00) Tbilisi', offset: 240 },
  { key: 'Yerevan', name: '(GMT+04:00) Yerevan', offset: 240 },
  { key: 'Kabul', name: '(GMT+04:30) Kabul', offset: 270 },
  { key: 'Ekaterinburg', name: '(GMT+06:00) Ekaterinburg', offset: 360 },
  { key: 'Islamabad', name: '(GMT+05:00) Islamabad', offset: 300 },
  { key: 'Karachi', name: '(GMT+05:00) Karachi', offset: 300 },
  { key: 'Tashkent', name: '(GMT+05:00) Tashkent', offset: 300 },
  { key: 'Chennai', name: '(GMT+05:30) Chennai', offset: 330 },
  { key: 'Kolkata', name: '(GMT+05:30) Kolkata', offset: 330 },
  { key: 'Mumbai', name: '(GMT+05:30) Mumbai', offset: 330 },
  { key: 'New Delhi', name: '(GMT+05:30) New Delhi', offset: 330 },
  { key: 'Kathmandu', name: '(GMT+05:45) Kathmandu', offset: 345 },
  { key: 'Astana', name: '(GMT+06:00) Astana', offset: 360 },
  { key: 'Dhaka', name: '(GMT+06:00) Dhaka', offset: 360 },
  {
    key: 'Sri Jayawardenepura',
    name: '(GMT+05:30) Sri Jayawardenepura',
    offset: 330,
  },
  { key: 'Almaty', name: '(GMT+06:00) Almaty', offset: 360 },
  { key: 'Novosibirsk', name: '(GMT+07:00) Novosibirsk', offset: 420 },
  { key: 'Rangoon', name: '(GMT+06:30) Rangoon', offset: 390 },
  { key: 'Bangkok', name: '(GMT+07:00) Bangkok', offset: 420 },
  { key: 'Hanoi', name: '(GMT+07:00) Hanoi', offset: 420 },
  { key: 'Jakarta', name: '(GMT+07:00) Jakarta', offset: 420 },
  { key: 'Krasnoyarsk', name: '(GMT+08:00) Krasnoyarsk', offset: 480 },
  { key: 'Beijing', name: '(GMT+08:00) Beijing', offset: 480 },
  { key: 'Chongqing', name: '(GMT+08:00) Chongqing', offset: 480 },
  { key: 'Hong Kong', name: '(GMT+08:00) Hong Kong', offset: 480 },
  { key: 'Urumqi', name: '(GMT+08:00) Urumqi', offset: 480 },
  { key: 'Kuala Lumpur', name: '(GMT+08:00) Kuala Lumpur', offset: 480 },
  { key: 'Singapore', name: '(GMT+08:00) Singapore', offset: 480 },
  { key: 'Taipei', name: '(GMT+08:00) Taipei', offset: 480 },
  { key: 'Perth', name: '(GMT+08:00) Perth', offset: 480 },
  { key: 'Irkutsk', name: '(GMT+09:00) Irkutsk', offset: 540 },
  { key: 'Ulaanbaatar', name: '(GMT+08:00) Ulaanbaatar', offset: 480 },
  { key: 'Seoul', name: '(GMT+09:00) Seoul', offset: 540 },
  { key: 'Osaka', name: '(GMT+09:00) Osaka', offset: 540 },
  { key: 'Sapporo', name: '(GMT+09:00) Sapporo', offset: 540 },
  { key: 'Tokyo', name: '(GMT+09:00) Tokyo', offset: 540 },
  { key: 'Yakutsk', name: '(GMT+10:00) Yakutsk', offset: 600 },
  { key: 'Darwin', name: '(GMT+09:30) Darwin', offset: 570 },
  { key: 'Adelaide', name: '(GMT+09:30) Adelaide', offset: 570 },
  { key: 'Canberra', name: '(GMT+10:00) Canberra', offset: 600 },
  { key: 'Melbourne', name: '(GMT+10:00) Melbourne', offset: 600 },
  { key: 'Sydney', name: '(GMT+10:00) Sydney', offset: 600 },
  { key: 'Brisbane', name: '(GMT+10:00) Brisbane', offset: 600 },
  { key: 'Hobart', name: '(GMT+10:00) Hobart', offset: 600 },
  { key: 'Vladivostok', name: '(GMT+11:00) Vladivostok', offset: 660 },
  { key: 'Guam', name: '(GMT+10:00) Guam', offset: 600 },
  { key: 'Port Moresby', name: '(GMT+10:00) Port Moresby', offset: 600 },
  { key: 'Magadan', name: '(GMT+12:00) Magadan', offset: 720 },
  { key: 'Solomon Is.', name: '(GMT+11:00) Solomon Is.', offset: 660 },
  { key: 'New Caledonia', name: '(GMT+11:00) New Caledonia', offset: 660 },
  { key: 'Fiji', name: '(GMT+12:00) Fiji', offset: 720 },
  { key: 'Kamchatka', name: '(GMT+12:00) Kamchatka', offset: 720 },
  { key: 'Marshall Is.', name: '(GMT+12:00) Marshall Is.', offset: 720 },
  { key: 'Auckland', name: '(GMT+12:00) Auckland', offset: 720 },
  { key: 'Wellington', name: '(GMT+12:00) Wellington', offset: 720 },
  { key: "Nuku'alofa", name: "(GMT+13:00) Nuku'alofa", offset: 780 },
  { key: 'Tokelau Is.', name: '(GMT+13:00) Tokelau Is.', offset: 780 },
  { key: 'Chatham Is.', name: '(GMT+12:45) Chatham Is.', offset: 765 },
  { key: 'Samoa', name: '(GMT+13:00) Samoa', offset: 780 },
];

export default availableTimezones;
