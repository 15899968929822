import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'page-items',
};

export default {
  name: 'page-items',
  singularName: 'page-item',
  payloadKey: 'page_items',
  singularPayloadKey: 'page_item',
  localized: false,
} as DataResourceOptions;
