import { Box, ListItem, Typography, Button as MuiButton } from '@mui/material';
import { Button } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Integration } from '../../common/enums';
import { IntegrationRecord } from '../../common/types';

const IntegrationsListItem = ({
  integration,
  onSelect,
}: {
  integration: IntegrationRecord;
  onSelect: (value: Integration) => void;
}) => (
  <ListItem
    disablePadding
    sx={{
      width: '100%',
      display: 'flex',
      minHeight: '50px',
      '&:nth-of-type(odd)': {
        background: '#f5f5f5',
      },
      '&:hover .integrations-configurations-add-button': {
        visibility: 'visible',
      },
      '& .integrations-configurations-add-button': {
        visibility: 'hidden',
      },
      '& .integrations-configurations-open-icon': {
        visibility: 'hidden',
      },
      '&:hover .integrations-configurations-open-icon': {
        visibility: 'visible',
      },
    }}
  >
    <Box
      ml={2}
      mr="20px"
      width="80px"
      height="40px"
      display="flex"
      justifyContent="center"
    >
      <Box
        component="img"
        src={integration.logo}
        alt={integration.systemName}
        maxWidth="100%"
        maxHeight="100%"
        sx={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
      />
    </Box>
    <Box flex="1" ml={2}>
      <Typography variant="subtitle2">{integration.systemName}</Typography>
    </Box>
    <Box flex="1" ml={2}>
      <MuiButton
        href={integration.website}
        target="_blank"
        size="small"
        startIcon={
          <OpenInNewIcon className="integrations-configurations-open-icon" />
        }
        sx={{
          color: 'inherit',
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
          },
        }}
      >
        {integration.vendor}
      </MuiButton>
    </Box>
    <Box flex="1" ml={2} display="flex" justifyContent="end">
      <Button
        className="integrations-configurations-add-button"
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        label="resources.integrations-configurations.controls.add-integration"
        onClick={() => {
          onSelect(integration.id);
        }}
        sx={{ marginRight: '16px' }}
      />
    </Box>
  </ListItem>
);

export default IntegrationsListItem;
