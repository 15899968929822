import { useEffect } from 'react';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { useKeenQuery } from '../../../../utils/keen';

const usePageViewsByCategoryData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const [
    { data: pageViewsByCategory = [], loading: isPageViewsByCategoryLoading },
    queryPageViewsByCategory,
  ] = useKeenQuery<{ categoryId: string; result: number }[]>();

  useEffect(() => {
    queryPageViewsByCategory({
      analysisType: 'count',
      eventCollection: 'page_views',
      groupBy: 'category_id',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryPageViewsByCategory, timeframe]);

  return { data: pageViewsByCategory, loading: isPageViewsByCategoryLoading };
};

export default usePageViewsByCategoryData;
