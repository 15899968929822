import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'pages',
};

export default {
  name: 'pages',
  singularName: 'page',
  payloadKey: 'pages',
  singularPayloadKey: 'page',
  localized: true,
} as DataResourceOptions;
