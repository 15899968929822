import { InputLabel, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import {
  Loading,
  useCheckAuth,
  useGetList,
  useGetOne,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { TimeframeAsString } from '../common/enums';
import { CategoryRecord, CurrencyRecord, Timeframe } from '../common/types';
import { useGlobalContext } from '../context/globalContext';
import OrderTotalsChart from './charts/OrderTotalsChart';
import SelectWithDateRange from './charts/SelectWithDateRange';
import UsageTotalsChart from './charts/UsageTotalsChart';

const ListContainer = styled('ul')({
  width: '100%',
  marginTop: '16px',
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  columnGap: '32px',
  rowGap: '32px',
  listStyleType: 'none',
  padding: 0,
  margin: 0,
});

const ListItem = styled('li')({
  height: '371px',
});

const Dashboard = () => {
  const t = useTranslate();
  const [{ hotel }] = useGlobalContext();
  const {
    data: categories = [],
    isLoading: isCategoryLoading,
  } = useGetList<CategoryRecord>('categories', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
    filter: {
      hotel_id_in: [hotel?.id, ...(hotel?.hotelMixinIds || [])],
    },
  });
  const {
    data: currency,
    isLoading: isCurrencyLoading,
  } = useGetOne<CurrencyRecord>('currencies', {
    id: hotel?.currencyId as number,
  });
  const [timeframe, setTimeframe] = useState<Timeframe>(
    TimeframeAsString.LastThirtyDays
  );

  return (
    <div>
      <Toolbar
        sx={{
          justifyContent: 'flex-end',
          '&.MuiToolbar-gutters': {
            padding: 0,
          },
        }}
      >
        <InputLabel
          id="date-range-select-label"
          sx={{
            marginRight: '15px',
            color: '#a09cab',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          {t('charts.filter.date_range')}
        </InputLabel>
        <SelectWithDateRange onSelect={setTimeframe} timeframe={timeframe} />
      </Toolbar>
      {!hotel || !currency || isCategoryLoading || isCurrencyLoading ? (
        <Loading />
      ) : (
        <ListContainer>
          <ListItem style={{ gridColumn: '1/13' }}>
            <UsageTotalsChart
              timeframe={timeframe}
              hotel={hotel}
              categories={categories}
            />
          </ListItem>
          <ListItem style={{ gridColumn: '1/13' }}>
            <OrderTotalsChart
              timeframe={timeframe}
              hotel={hotel}
              currency={currency}
              categories={categories}
            />
          </ListItem>
        </ListContainer>
      )}
    </div>
  );
};

/**
 * Dashboard is rendered under the `/` route, which doesn't trigger `authProvider.checkAuth` method,
 * since only not root routes check whether user is authenticated before redirecting to the desired route.
 * This ensures that we will try to authenticate user (e.g. with credentials in  query params) before
 * actually trying to render the `/` root route.
 */
const DashboardWithAuth = () => {
  const redirect = useRedirect();
  const checkAuth = useCheckAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkAuth();
        setIsAuthenticated(true);
      } catch {
        redirect('/login');
      }
    };

    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated ? <Dashboard /> : <></>;
};

export default DashboardWithAuth;
