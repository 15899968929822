import { Form, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import InitialState from './InitialState';
import SendingState from './SendingState';
import SuccessState from './SuccessState';
import ErrorState from './ErrorState';

const PREFIX = 'CheckoutDialog';

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogMessage: `${PREFIX}-dialogMessage`,
  checkoutButton: `${PREFIX}-checkoutButton`,
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.dialogTitle}`]: {
    fontSize: '30px',
    fontWeight: 300,
    paddingTop: '25px',
    paddingBottom: '35px',
  },
  [`& .${classes.dialogContent}`]: {
    minHeight: '288px',
    marginTop: '100px',
  },
  [`& .${classes.dialogMessage}`]: {
    marginTop: '15px',
    fontSize: '18px',
    fontWeight: 300,
    marginBottom: '20px',
  },
  [`& .${classes.checkoutButton}`]: {
    marginTop: '15px',
    minWidth: '170px',
  },
});

const CheckoutDialog = (props: any) => {
  const {
    activeStep,
    cancelCheckoutProcess,
    cancelSending,
    handleConfirmCheckoutSubmit,
    handleSendPaymentEmailSubmit,
    openPayments,
    paymentsCheck,
    setOpenPayments,
    setPaymentsCheck,
    showCheckoutDialog,
    toggleCheckoutModal,
  } = props;

  const t = useTranslate();

  const getStepContent = (step: string) => {
    switch (step) {
      case 'initial':
        return (
          <InitialState
            cancelCheckoutProcess={cancelCheckoutProcess}
            handleConfirmCheckoutSubmit={handleConfirmCheckoutSubmit}
            handleSendPaymentEmailSubmit={handleSendPaymentEmailSubmit}
            openPayments={openPayments}
            paymentsCheck={paymentsCheck}
            setOpenPayments={setOpenPayments}
            setPaymentsCheck={setPaymentsCheck}
          />
        );
      case 'sending':
        return (
          <SendingState
            dialogContentClass={classes.dialogContent}
            dialogMessageClass={classes.dialogMessage}
          />
        );
      case 'success':
        return (
          <SuccessState
            checkoutButtonClass={classes.checkoutButton}
            dialogContentClass={classes.dialogContent}
            dialogMessageClass={classes.dialogMessage}
            toggleCheckoutModal={toggleCheckoutModal}
          />
        );
      case 'error':
        return (
          <ErrorState
            cancelSending={cancelSending}
            checkoutButtonClass={classes.checkoutButton}
            dialogContentClass={classes.dialogContent}
            dialogMessageClass={classes.dialogMessage}
            handleConfirmCheckoutSubmit={handleConfirmCheckoutSubmit}
            handleSendPaymentEmailSubmit={handleSendPaymentEmailSubmit}
            openPayments={openPayments}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      open={showCheckoutDialog}
      scroll="body"
      onClose={cancelCheckoutProcess}
      onBackdropClick={cancelCheckoutProcess}
      aria-labelledby="form-dialog-title"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DialogTitle className={classes.dialogTitle}>
        {t('resources.orders.checkout.confirmCheckout')}
      </DialogTitle>
      <Form>{getStepContent(activeStep)}</Form>
    </StyledDialog>
  );
};

export default CheckoutDialog;
