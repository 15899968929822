import { styled } from '@mui/material/styles';

const ChartListItemValue = styled('div')({
  width: '90px',
  minWidth: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  textAlign: 'end',
  marginRight: '5px',
});

export default ChartListItemValue;
