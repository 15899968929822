import { Create, Loading, RecordContextProvider, useCreate } from 'react-admin';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import ItemViewToolbar from './ItemViewToolbar';
import ItemCustomLayout from './ItemCustomLayout';
import { useGlobalContext } from '../../context/globalContext';
import {
  HotelRecord,
  ItemRecordLocalized,
  PriceRecord,
} from '../../common/types';
import { IframeResizerParentMessage } from '../../common/enums';
import { sendMessageToParent } from '../../utils/iframe-resizer';

const ItemCreateForm = ({ hotel }: { hotel: HotelRecord }) => {
  const [create] = useCreate();
  const [prices, setPrices] = useState<Partial<PriceRecord>[]>([]);

  const [searchParams] = useSearchParams();
  /**
   * We use here URLSearchParams coming from LegAP because we need
   * both `category_id` and `page_id` to successfully create an item.
   */
  const categoryId = Number(searchParams.get('category_id'));
  const pageId = Number(searchParams.get('page_id'));

  const afterSave = useCallback(
    async (item: ItemRecordLocalized) => {
      const result = await Promise.all([
        prices.map((price) =>
          create('prices', { data: { ...price, itemId: item.id } })
        ),
        create('page-items', {
          data: { pageId, itemId: item.id },
        }),
      ]);

      sendMessageToParent(IframeResizerParentMessage.ItemCreateSuccess);

      return result;
    },
    [prices, create, pageId]
  );

  return (
    <RecordContextProvider value={{ hotelId: hotel.id, categoryId }}>
      <SimpleFormWithServerSideFieldValidation
        toolbar={
          <ItemViewToolbar
            message={IframeResizerParentMessage.ItemCreateCancel}
          />
        }
        type="create"
        afterSave={afterSave}
      >
        <ItemCustomLayout
          pageId={pageId}
          prices={prices}
          setPrices={setPrices}
        />
      </SimpleFormWithServerSideFieldValidation>
    </RecordContextProvider>
  );
};

const ItemCreate = (props: any) => {
  const [{ hotel }] = useGlobalContext();

  return hotel ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Create {...props}>
      <ItemCreateForm hotel={hotel} />
    </Create>
  ) : (
    <Loading />
  );
};

export default ItemCreate;
