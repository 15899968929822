import {
  Toolbar,
  ListButton,
  SaveButton,
  DeleteButton,
  useEditContext,
} from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteButtonWithDialog from './DeleteButtonWithDialog';
import { HotelRecord } from '../../common/types';

const HotelEditViewToolbar = () => {
  const { record } = useEditContext<HotelRecord>();
  const isSharedInstallation = record!.type === 'HotelMixin';

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {isSharedInstallation ? (
        <DeleteButtonWithDialog record={record as HotelRecord} />
      ) : (
        <DeleteButton size="medium" variant="contained" />
      )}
      <div>
        <ListButton
          label="ra.action.cancel"
          icon={<ClearIcon />}
          size="medium"
          variant="contained"
          color="inherit"
        />
        <SaveButton
          sx={{
            marginLeft: '10px',
          }}
          size="medium"
          variant="contained"
          color="primary"
        />
      </div>
    </Toolbar>
  );
};

export default HotelEditViewToolbar;
