import { styled } from '@mui/material/styles';

const ChartListItemTitle = styled('div')({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginLeft: '8px',
});

export default ChartListItemTitle;
