import { styled } from '@mui/material/styles';

const ListSectionContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export default ListSectionContainer;
