import { Edit, required, TextInput, useRecordContext } from 'react-admin';
import { CurrencyRecord } from '../../common/types';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import EditViewToolbar from '../EditViewToolbar';

// eslint-disable-next-line react/require-default-props
const CurrencyTitle = () => {
  const record: CurrencyRecord = useRecordContext();
  return <span>Currency {record ? `"${record.name}"` : ''}</span>;
};

const CurrencyEdit = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Edit title={<CurrencyTitle />} {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<EditViewToolbar />}
      type="edit"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput source="code" variant="outlined" validate={required()} />
      <TextInput source="symbol" variant="outlined" validate={required()} />
    </SimpleFormWithServerSideFieldValidation>
  </Edit>
);

export default CurrencyEdit;
