import { useEffect } from 'react';
import {
  CategoryRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { useKeenQuery } from '../../../../utils/keen';

type OrderItemKeenRecord = {
  // eslint-disable-next-line camelcase
  total_value: number;
  // eslint-disable-next-line camelcase
  order_id: number;
  value: number;
  // eslint-disable-next-line camelcase
  item_id: number;
  id: number;
  quantity: number;
};
const useRevenueOrderItemsByCategoryData = ({
  hotel,
  timeframe,
  selectedCategory,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
}) => {
  const [
    { data: orderItemsByCategory = [], loading: isOrderItemsByCategoryLoading },
    queryOrderItemsByCategory,
  ] = useKeenQuery<{ orderItems: OrderItemKeenRecord[] }[]>();

  useEffect(() => {
    queryOrderItemsByCategory({
      analysisType: 'extraction',
      eventCollection: 'orders',
      propertyNames: ['order_items'],
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
          ]
        : [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
          ],
      timeframe,
    });
  }, [hotel.id, queryOrderItemsByCategory, selectedCategory, timeframe]);

  return { data: orderItemsByCategory, loading: isOrderItemsByCategoryLoading };
};

export default useRevenueOrderItemsByCategoryData;
