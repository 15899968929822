import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import {
  convertToLocalAdjustedUTC,
  getKeenRequestType,
  PageViewsRequest,
  useKeenQuery,
} from '../../../../utils/keen';
import {
  UsageTotalsChartData,
  UsageTotalsKeenChartProps,
} from '../../UsageTotalsKeenChart';

interface UseSessionCountTotalDataProps {
  hotel: HotelRecord;
  timeframe: Timeframe;
}

const useSessionTotalHourlyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseSessionCountTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [sessionCountData, setSessionCountData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: sessionCount = [], loading: isSessionCountLoading },
    querySessionCount,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    if (isEnabled) {
      querySessionCount({
        analysisType: 'count',
        eventCollection: 'sessions',
        groupBy: 'date.hour_of_day',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
        ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, querySessionCount, timeframe]);

  useEffect(() => {
    if (isEnabled && !isSessionCountLoading) {
      const hourToDataMap = sessionCount.reduce(
        (
          acc: { [key: number]: { dateHourOfDay: number; result: number } },
          val: { dateHourOfDay: number; result: number }
        ) => {
          acc[val.dateHourOfDay] = val;
          return acc;
        },
        {}
      );
      const zeroFilledSessionCountHourly = [...Array(24)].map(
        (_, index) =>
          hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
      );

      const data = zeroFilledSessionCountHourly.map(
        ({ dateHourOfDay, result }) => ({
          id: formatTime(dateHourOfDay, intl),
          value: result,
          tooltip: {
            title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
            subtitle: intl.formatNumber(result, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
        })
      );

      setSessionCountData(data);
    }
  }, [intl, isEnabled, isSessionCountLoading, sessionCount]);

  return { data: sessionCountData, loading: isSessionCountLoading };
};

const useSessionTotalDailyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseSessionCountTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const [sessionCountData, setSessionCountData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: sessionCount = [], loading: isSessionCountLoading },
    querySessionCount,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      querySessionCount({
        analysisType: 'count',
        eventCollection: 'sessions',
        interval: 'daily',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
        ],
        timeframe:
          typeof timeframe === 'string'
            ? timeframe
            : {
                start: convertToLocalAdjustedUTC(timeframe.start),
                end: convertToLocalAdjustedUTC(timeframe.end),
              },
      });
    }
  }, [hotel.uuid, isEnabled, querySessionCount, timeframe]);

  useEffect(() => {
    if (isEnabled && !isSessionCountLoading) {
      const data = sessionCount.map(
        ({ value, timeframe: sessionCountTimeframe }) => {
          const localAdjustedUTC = convertToLocalAdjustedUTC(
            sessionCountTimeframe.start
          );

          return {
            id: new Date(localAdjustedUTC).getDate(),
            value,
            tooltip: {
              title: intl.formatDate(new Date(localAdjustedUTC), {
                weekday: 'long',
                day: 'numeric',
                year: 'numeric',
                month: 'long',
              }),
              subtitle: intl.formatNumber(value as number),
            },
          };
        }
      );

      setSessionCountData(data);
    }
  }, [intl, isEnabled, isSessionCountLoading, sessionCount]);

  return { data: sessionCountData, loading: isSessionCountLoading };
};

const useSessionTotalWeeklyData = ({
  hotel,
  timeframe,
  isEnabled = false,
}: UseSessionCountTotalDataProps & { isEnabled: boolean }) => {
  const intl = useIntl();
  const t = useTranslate();
  const [sessionCountData, setSessionCountData] = useState<
    UsageTotalsChartData[]
  >([]);

  const [
    { data: sessionCount = [], loading: isSessionCountLoading },
    querySessionCount,
  ] = useKeenQuery<
    {
      value: number;
      timeframe: { start: string; end: string };
    }[]
  >();

  useEffect(() => {
    if (isEnabled) {
      querySessionCount({
        analysisType: 'count',
        eventCollection: 'sessions',
        interval: 'weekly',
        filters: [
          {
            propertyName: 'hotel_uuid',
            operator: 'eq',
            propertyValue: hotel.uuid,
          },
        ],
        timeframe,
      });
    }
  }, [hotel.uuid, isEnabled, querySessionCount, timeframe]);

  useEffect(() => {
    if (isEnabled && !isSessionCountLoading) {
      const data = sessionCount.map(({ value }, index) => ({
        id: index + 1,
        value,
        tooltip: {
          title: `${t('settings.week')} ${index + 1}`,
          subtitle: intl.formatNumber(value as number),
        },
      }));

      setSessionCountData(data);
    }
  }, [intl, isEnabled, isSessionCountLoading, sessionCount, t]);

  return { data: sessionCountData, loading: isSessionCountLoading };
};

const useSessionCountTotalData = ({
  hotel,
  timeframe,
}: UseSessionCountTotalDataProps) => {
  const t = useTranslate();
  const requestType = getKeenRequestType(timeframe);

  const {
    data: sessionTotalHourlyData,
    loading: isSessionTotalHourlyDataLoading,
  } = useSessionTotalHourlyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Hourly,
  });
  const {
    data: sessionTotalDailyData,
    loading: isSessionTotalDailyDataLoading,
  } = useSessionTotalDailyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Daily,
  });
  const {
    data: sessionTotalWeeklyData,
    loading: isSessionTotalWeeklyDataLoading,
  } = useSessionTotalWeeklyData({
    hotel,
    timeframe,
    isEnabled: requestType === PageViewsRequest.Weekly,
  });

  const requestTypeToDataMap = {
    [PageViewsRequest.Hourly]: sessionTotalHourlyData,
    [PageViewsRequest.Daily]: sessionTotalDailyData,
    [PageViewsRequest.Weekly]: sessionTotalWeeklyData,
  };
  const requestTypeToLoadingMap = {
    [PageViewsRequest.Hourly]: isSessionTotalHourlyDataLoading,
    [PageViewsRequest.Daily]: isSessionTotalDailyDataLoading,
    [PageViewsRequest.Weekly]: isSessionTotalWeeklyDataLoading,
  };
  const requestTypeToLabelMap = {
    [PageViewsRequest.Hourly]: t('charts.usage.legend.hours'),
    [PageViewsRequest.Daily]: t('charts.usage.legend.days'),
    [PageViewsRequest.Weekly]: t('charts.usage.legend.weeks'),
  };

  const data = requestTypeToDataMap[requestType];
  const loading = requestTypeToLoadingMap[requestType];
  const legend = requestTypeToLabelMap[requestType];

  const chartProps: UsageTotalsKeenChartProps = {
    data,
    legend,
  };

  return { loading, chartProps };
};

export default useSessionCountTotalData;
