import {
  sanitizeFieldRestProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import { Chip } from '@mui/material';

const PREFIX = 'ColoredStatusField';

const classes = {
  default: `${PREFIX}-default`,
  confirmed: `${PREFIX}-confirmed`,
  declined: `${PREFIX}-declined`,
};

const StyledChip = styled(Chip)({
  [`&.${classes.default}`]: { backgroundColor: '#fcb322', color: '#fff' },
  [`&.${classes.confirmed}`]: { backgroundColor: '#3cc051' },
  [`&.${classes.declined}`]: { backgroundColor: '#ed4e2a' },
});

const ColoredStatusField = (props: any) => {
  const { source } = props;

  if (!source) {
    throw new Error(
      'Error rendering ColoredStatusField component! You must provide `source` prop.'
    );
  }

  const t = useTranslate();
  const record = useRecordContext(props);
  const translatedStatusLabel = (status: string) =>
    record[source] === status ? t(`resources.orders.states.${status}`) : '';

  return (
    <div>
      <StyledChip
        className={classnames({
          [classes.default]: record[source],
          [classes.confirmed]: record[source] === 'confirmed',
          [classes.declined]: record[source] === 'declined',
        })}
        label={translatedStatusLabel(record.state)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sanitizeFieldRestProps(props)}
      />
    </div>
  );
};

export default ColoredStatusField;
