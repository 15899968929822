import { Toolbar, ListButton, SaveButton } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import { sendMessageToParent } from '../../utils/iframe-resizer';
import { IframeResizerParentMessage } from '../../common/enums';

const ItemViewToolbar = ({
  message,
  alwaysEnableSaveButton = false,
}: {
  message: IframeResizerParentMessage;
  alwaysEnableSaveButton?: boolean;
}) => {
  const handleClick = () => {
    sendMessageToParent(message);
  };

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <ListButton
        label="ra.action.cancel"
        icon={<ClearIcon />}
        size="medium"
        variant="contained"
        color="inherit"
        onClick={handleClick}
      />
      <SaveButton
        sx={{
          marginLeft: '10px',
        }}
        size="medium"
        variant="contained"
        color="primary"
        alwaysEnable={alwaysEnableSaveButton}
      />
    </Toolbar>
  );
};

export default ItemViewToolbar;
