import { camelCase } from 'camel-case';
import { snakeCase } from 'snake-case';

export const recursivelyTransformObjectKeys = (
  object: any,
  transformer: Function
): any =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      transformer(key),
      !Array.isArray(value) && typeof value === 'object' && value !== null
        ? recursivelyTransformObjectKeys(value, transformer)
        : value,
    ])
  );

export const camelizeObjectKeys = (object: any): any =>
  recursivelyTransformObjectKeys(object, camelCase);

export const decamelizeObjectKeys = (object: any): any =>
  recursivelyTransformObjectKeys(object, snakeCase);
