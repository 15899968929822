import { Box } from '@mui/material';
import {
  InputProps,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';
import StyledArrayInput from './StyledArrayInput';

const ApaleoInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.clientId"
        label="resources.integrations.fields.apaleo.clientId.label"
        helperText="resources.integrations.fields.apaleo.clientId.helperText"
        validate={required(
          'resources.integrations.fields.apaleo.clientId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.clientSecret"
        label="resources.integrations.fields.apaleo.clientSecret.label"
        helperText="resources.integrations.fields.apaleo.clientSecret.helperText"
        validate={required(
          'resources.integrations.fields.apaleo.clientSecret.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <StyledArrayInput
        source="currentConfig.service.propertyIds"
        label="resources.integrations.fields.apaleo.propertyIds.label"
        helperText="resources.integrations.fields.apaleo.propertyIds.helperText"
        variant="outlined"
        fullWidth
      >
        <SimpleFormIterator disableReordering>
          <TextInput
            source=""
            label="resources.integrations.fields.apaleo.propertyId.label"
            helperText="resources.integrations.fields.apaleo.propertyId.helperText"
            variant="outlined"
            fullWidth
          />
        </SimpleFormIterator>
      </StyledArrayInput>
      <SelectInput
        source="currentConfig.service.checkInEvent"
        choices={[{ id: 'unit-assigned', name: 'unit-assigned' }]}
        label="resources.integrations.fields.apaleo.checkInEvent.label"
        helperText="resources.integrations.fields.apaleo.checkInEvent.helperText"
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default ApaleoInputs;
