import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'integrations-configuration-revisions',
};

export default {
  name: 'integrations-configuration-revisions',
  singularName: 'integrations-configuration-revision',
  payloadKey: 'integrations/configuration_revisions',
  singularPayloadKey: 'integrations/configuration_revision',
  localized: false,
} as DataResourceOptions;
