import { ResourceProps } from 'react-admin';
import TvIcon from '@mui/icons-material/Tv';
import TvChannelEdit from './TvChannelEdit';
import TvChannelCreate from './TvChannelCreate';
import TvChannelList from './TvChannelList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'tv-channels',
  create: TvChannelCreate,
  edit: TvChannelEdit,
  icon: TvIcon,
  list: TvChannelList,
};

export default {
  name: 'tv-channels',
  singularName: 'tv-channel',
  payloadKey: 'room_control/tv_channels',
  singularPayloadKey: 'room_control/tv_channel',
  localized: true,
} as DataResourceOptions;
