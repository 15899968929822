import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ListButton,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import BuildIcon from '@mui/icons-material/Build';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';
import { AppRecord } from '../../common/types';

const AppFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="name_or_package_name_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const AppListRowControls = (props: any) => {
  const record: AppRecord = useRecordContext(props);

  return (
    <>
      <EditButton
        className="text-list-button"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <ListButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        to={{
          pathname: '/app-versions',
          search: `filter=${JSON.stringify({ app_id_in: record.id })}`,
        }}
        onClick={(e) => e.stopPropagation()}
        icon={<BuildIcon />}
        label="resources.app-versions.name"
        color="secondary"
        className="text-list-button"
      />
    </>
  );
};

const AppList = (props: any) => (
  <List
    exporter={false}
    filters={<AppFilter />}
    pagination={<ListPagination />}
    perPage={50}
    actions={<ListViewTopToolbar />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="packageName" />
      <BooleanField source="mandatory" />
      <BooleanField source="cacheEnabled" />
      <AppListRowControls />
    </Datagrid>
  </List>
);

export default AppList;
