import { Box } from '@mui/material';
import { useCallback } from 'react';
import {
  Edit,
  EditProps,
  required,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useDataProvider,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import {
  ItemRecordLocalized,
  TvChannelGenreRecord,
  TvChannelLocaleRecord,
  TvChannelRecordLocalized,
} from '../../common/types';
import EditViewToolbar from '../EditViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import ImageUploadInput from './ImageUploadInput';

// eslint-disable-next-line react/require-default-props
const TvChannelTitle = () => {
  const record: ItemRecordLocalized = useRecordContext();
  return <span>Tv Channel {record ? `"${record.title}"` : ''}</span>;
};

const TvChannelEdit = (props: EditProps) => {
  const dataProvider = useDataProvider();

  // In order to add Genre and Locale relationships to newly created TvChannel record
  // we MUST to create corresponding TvChannelGenre and TvChannelLocale records, and
  // the TvChannel record updates then itself.
  const afterSave = useCallback(
    async (
      tvChannel: TvChannelRecordLocalized,
      formData: TvChannelRecordLocalized
    ) => {
      if (formData.logo) {
        await dataProvider.logoUpload(
          tvChannel.id,
          tvChannel.title,
          formData.logo
        );
      }

      const {
        data: existingTvChannelGenres,
      } = await dataProvider.getList<TvChannelGenreRecord>(
        'tv-channel-genres',
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            tv_channel_id_eq: tvChannel.id,
            genre_id_in: tvChannel.genreIds,
          },
        }
      );
      const {
        data: existingTvChannelLocales,
      } = await dataProvider.getList<TvChannelLocaleRecord>(
        'tv-channel-locales',
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            tv_channel_id_eq: tvChannel.id,
            locale_id_in: tvChannel.localeIds,
          },
        }
      );

      const tvChannelGenreCreatePromises: Promise<any>[] = formData.genreIds.map(
        (genreId) => {
          if (
            existingTvChannelGenres.find(
              ({ genreId: existingGenreId }) => existingGenreId === genreId
            )
          ) {
            return Promise.resolve();
          }

          return dataProvider.create<TvChannelGenreRecord>(
            'tv-channel-genres',
            {
              data: {
                genreId,
                roomControlTvChannelId: tvChannel.id,
              },
            }
          );
        }
      );
      const tvChannelLocaleCreatePromises = formData.localeIds.map(
        (localeId) => {
          if (
            existingTvChannelLocales.find(
              ({ localeId: existingLocaleId }) => existingLocaleId === localeId
            )
          ) {
            return Promise.resolve();
          }

          return dataProvider.create<TvChannelLocaleRecord>(
            'tv-channel-locales',
            {
              data: {
                localeId,
                roomControlTvChannelId: tvChannel.id,
              },
            }
          );
        }
      );

      const tvChannelGenreDeletePromises: Promise<any>[] = tvChannel.genreIds.map(
        (genreId) => {
          if (formData.genreIds.includes(genreId)) {
            return Promise.resolve();
          }

          const relatedTvChannelGenreRecord = existingTvChannelGenres.find(
            ({ genreId: existingGenreId }) => existingGenreId === genreId
          );

          if (!relatedTvChannelGenreRecord) {
            return Promise.reject(
              new Error(
                `Unable to find tv_channel_genre record with id ${genreId}`
              )
            );
          }

          return dataProvider.delete<TvChannelGenreRecord>(
            'tv-channel-genres',
            {
              id: relatedTvChannelGenreRecord.id,
              previousData: relatedTvChannelGenreRecord,
            }
          );
        }
      );

      const tvChannelLocaleDeletePromises: Promise<any>[] = tvChannel.localeIds.map(
        (localeId) => {
          if (formData.localeIds.includes(localeId)) {
            return Promise.resolve();
          }

          const relatedTvChannelLocaleRecord = existingTvChannelLocales.find(
            ({ localeId: existingLocaleId }) => existingLocaleId === localeId
          );

          if (!relatedTvChannelLocaleRecord) {
            return Promise.reject(
              new Error(
                `Unable to find tv_channel_locale record with id ${localeId}`
              )
            );
          }

          return dataProvider.delete<TvChannelLocaleRecord>(
            'tv-channel-locales',
            {
              id: relatedTvChannelLocaleRecord.id,
              previousData: relatedTvChannelLocaleRecord,
            }
          );
        }
      );

      await Promise.all([
        ...tvChannelGenreCreatePromises,
        ...tvChannelLocaleCreatePromises,
        ...tvChannelGenreDeletePromises,
        ...tvChannelLocaleDeletePromises,
      ]);
    },
    [dataProvider]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Edit {...props} title={<TvChannelTitle />} redirect="list">
      <SimpleFormWithServerSideFieldValidation
        afterSave={afterSave}
        toolbar={<EditViewToolbar alwaysEnableSaveButton />}
        type="edit"
      >
        <Box
          sx={{
            width: '100%',
            display: { md: 'grid' },
            gridTemplateColumns: '2fr 3fr',
            columnGap: '5em',
          }}
          mt="2em"
          mb="2em"
        >
          <Box>
            <TextInput
              source="title"
              variant="outlined"
              validate={required()}
              fullWidth
            />
            <TextInput source="abbreviation" variant="outlined" fullWidth />
            <FunctionField
              render={(record: TvChannelRecordLocalized) =>
                record.logoRef && (
                  <TextInput
                    label="resources.tv-channels.fields.imageName"
                    source="logoRef"
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                )
              }
            />
            <ReferenceArrayInput
              label="resources.locales.name"
              source="localeIds"
              reference="locales"
              perPage={60}
              fullWidth
            >
              <AutocompleteArrayInput
                // @ts-ignore
                disableClearable
                validate={required()}
                translateChoice={false}
                optionText="name"
                variant="outlined"
                filterToQuery={(name: string) => ({ name_cont: name })}
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label="resources.genres.name"
              source="genreIds"
              reference="genres"
              perPage={60}
            >
              <AutocompleteArrayInput
                // @ts-ignore
                disableClearable
                validate={required()}
                translateChoice={false}
                optionText="name"
                variant="outlined"
                filterToQuery={(name: string) => ({
                  translations_name_cont: name,
                })}
              />
            </ReferenceArrayInput>
          </Box>
          <Box>
            <ImageUploadInput source="logo" />
          </Box>
        </Box>
      </SimpleFormWithServerSideFieldValidation>
    </Edit>
  );
};

export default TvChannelEdit;
