type NestedStructure<T = string> = {
  [Key in string | number]?: T | NestedStructure<T>;
};

const flatten = <T>(
  nestedMessages: NestedStructure<T>,
  prefix = ''
): Record<string, string> =>
  Object.entries(nestedMessages).reduce(
    (
      messages: Record<string, string>,
      [key, value]: [key: string, value: any]
    ) => {
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      const hasNestedProperties = typeof value === 'object' && value;

      if (hasNestedProperties) {
        Object.assign(messages, flatten(value, prefixedKey));
      } else {
        // eslint-disable-next-line no-param-reassign
        messages[prefixedKey] = value;
      }

      return messages;
    },
    {}
  );

export default flatten;
