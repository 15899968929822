import { IntlShape } from 'react-intl';

export const formatTime = (hour: number, intl: IntlShape) => {
  const hourContainer = new Date(0, 0, 0, hour);

  return intl.formatTime(hourContainer, { hour: 'numeric', minute: undefined });
};

export const formatTimeRange = (
  fromHour: number,
  toHour: number,
  intl: IntlShape
) => `${formatTime(fromHour, intl)} – ${formatTime(toHour, intl)}`;

export default {
  formatTime,
  formatTimeRange,
};
