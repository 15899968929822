import { Create, required, SelectInput, TextInput } from 'react-admin';
import { LanguageDirection } from '../../common/enums';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import CreateViewToolbar from '../CreateViewToolbar';

const directionChoices = [
  { id: LanguageDirection.LTR, name: 'Left-to-Right' },
  { id: LanguageDirection.RTL, name: 'Right-to-Left' },
];

const LocaleCreate = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Create {...props} redirect="list">
    <SimpleFormWithServerSideFieldValidation
      toolbar={<CreateViewToolbar />}
      type="create"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput source="key" variant="outlined" validate={required()} />
      <SelectInput
        source="direction"
        choices={directionChoices}
        defaultValue={LanguageDirection.LTR}
        variant="outlined"
        validate={required()}
      />
    </SimpleFormWithServerSideFieldValidation>
  </Create>
);

export default LocaleCreate;
