import { ResourceProps } from 'react-admin';
import BuildIcon from '@mui/icons-material/Build';
import AppVersionEdit from './AppVersionEdit';
import AppVersionCreate from './AppVersionCreate';
import AppVersionList from './AppVersionList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'app-versions',
  create: AppVersionCreate,
  edit: AppVersionEdit,
  icon: BuildIcon,
  list: AppVersionList,
};

export default {
  name: 'app-versions',
  singularName: 'app-version',
  payloadKey: 'app_versions',
  singularPayloadKey: 'app_version',
  localized: false,
} as DataResourceOptions;
