import { createIntl, createIntlCache, IntlCache, IntlShape } from 'react-intl';
import { OptionalIntlConfig } from 'react-intl/src/components/provider';
import { DEFAULT_LANGUAGE, DEFAULT_MESSAGES } from '../../common/constants';
import { Language } from '../../common/enums';
import flatten from './flatten';

const DEFAULT_INTL_CONFIG: OptionalIntlConfig = {
  locale: DEFAULT_LANGUAGE,
  messages: flatten(DEFAULT_MESSAGES),
  defaultLocale: DEFAULT_LANGUAGE,
};

const RA_SPECIFIC_TRANSLATIONS = ['ra.page.list', 'ra.navigation.skip_nav'];

export default class IntlProvider {
  intlCache!: IntlCache;

  intl!: IntlShape;

  constructor() {
    this.intlCache = createIntlCache();
    this.intl = createIntl(DEFAULT_INTL_CONFIG, this.intlCache);
  }

  translate(key: string, options?: any) {
    if (RA_SPECIFIC_TRANSLATIONS.includes(key)) {
      return options?.name || key;
    }

    const raDefaultMessage = options?.['_'] || ''; // eslint-disable-line dot-notation
    const { messages: defaultIntlMessages } = DEFAULT_INTL_CONFIG;

    return key
      ? this.intl.formatMessage(
          {
            id: key,
            defaultMessage: raDefaultMessage || defaultIntlMessages![key],
          },
          options
        )
      : '';
  }

  async changeLocale(locale: Language) {
    if (this.intl.locale !== locale) {
      const messages = await import(`../../translations/${locale}.json`);

      this.intl = createIntl(
        { locale, messages: flatten(messages) },
        this.intlCache
      );
    }

    localStorage.setItem('selectedLanguage', locale);

    return Promise.resolve();
  }

  getLocale() {
    return this.intl.locale;
  }
}
