import { Dispatch, SetStateAction } from 'react';
import { useGetMany, Loading, useTranslate } from 'react-admin';
import { Chip, Paper } from '@mui/material';
import { HotelRecord } from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';

const InstallationTags = ({
  setFilterTag,
  installationTags,
  setInstallationTags,
}: {
  setFilterTag: (filterTag: string) => void;
  installationTags: Number[];
  setInstallationTags: Dispatch<SetStateAction<Number[]>>;
}) => {
  const [{ hotel }] = useGlobalContext();
  const t = useTranslate();

  const { isLoading, data: installations = [] } = useGetMany('hotels', {
    ids: hotel?.hotelMixinIds,
  });

  const allInstallations = [
    hotel,
    ...installations.sort((a, b) => b.name - a.name),
  ];

  const handleTagsSetting = (installation: HotelRecord) => {
    if (installationTags.includes(installation.id)) {
      setInstallationTags(
        installationTags.filter((id) => id !== installation.id)
      );
    } else {
      setInstallationTags([...installationTags, installation.id]);
    }
    setFilterTag('');
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Paper
        component="ul"
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
          listStyle: 'none',
          padding: 0.5,
          marginTop: 0,
          marginBottom: 0,
          boxShadow: 'none',
        }}
      >
        {allInstallations.map((installation) => (
          <li key={installation.id}>
            <Chip
              sx={{
                margin: 0.5,
              }}
              label={
                installation.id === hotel?.id
                  ? t('resources.items.image-modal.local-images')
                  : installation.name
              }
              color={
                installationTags.includes(installation.id)
                  ? 'primary'
                  : 'default'
              }
              onClick={() => handleTagsSetting(installation)}
            />
          </li>
        ))}
      </Paper>
    </>
  );
};

export default InstallationTags;
