import appResource, { resourceProps as appResourceProps } from './app';
import appVersionResource, {
  resourceProps as appVersionResourceProps,
} from './app-version';
import categoryResource, {
  resourceProps as categoryResourceProps,
} from './category';
import currencyResource, {
  resourceProps as currencyResourceProps,
} from './currency';
import deviceResource, { resourceProps as deviceResourceProps } from './device';
import genreResource, { resourceProps as genreResourceProps } from './genre';
import hotelResource, { resourceProps as hotelResourceProps } from './hotel';
import imageResource, { resourceProps as imageResourceProps } from './image';
import integrationsConfigurationResource, {
  resourceProps as integrationsConfigurationResourceProps,
} from './integrations-configuration';
import integrationsRemoteReferenceResource, {
  resourceProps as integrationsRemoteReferenceResourceProps,
} from './integrations-remote-reference';
import integrationsConfigurationRevisionResource, {
  resourceProps as integrationsConfigurationRevisionResourceProps,
} from './integrations-configuration-revision';
import itemResource, { resourceProps as itemResourceProps } from './item';
import pageItemResource, {
  resourceProps as pageItemResourceProps,
} from './page-item';
import localeResource, { resourceProps as localeResourceProps } from './locale';
import orderResource, { resourceProps as orderResourceProps } from './order';
import orderItemResource, {
  resourceProps as orderItemResourceProps,
} from './order-item';
import pageResource, { resourceProps as pageResourceProps } from './page';
import pageCgResource, {
  resourceProps as pageCgResourceProps,
} from './page-cg';
import itemCgResource, {
  resourceProps as itemCgResourceProps,
} from './item-cg';
import priceResource, { resourceProps as priceResourceProps } from './price';
import roomResource, { resourceProps as roomResourceProps } from './room';
import tvChannelResource, {
  resourceProps as tvChannelResourceProps,
} from './tv-channel';
import tvChannelGenreResource, {
  resourceProps as tvChannelGenreResourceProps,
} from './tv-channel-genre';
import tvChannelLocaleResource, {
  resourceProps as tvChannelLocaleResourceProps,
} from './tv-channel-locale';
import contentGroupResource, {
  resourceProps as contentGroupResourceProps,
} from './content-group';

export const resourceProps = {
  appResourceProps,
  appVersionResourceProps,
  contentGroupResourceProps,
  currencyResourceProps,
  deviceResourceProps,
  hotelResourceProps,
  integrationsConfigurationResourceProps,
  integrationsRemoteReferenceResourceProps,
  integrationsConfigurationRevisionResourceProps,
  itemResourceProps,
  pageItemResourceProps,
  itemCgResourceProps,
  localeResourceProps,
  orderResourceProps,
  tvChannelResourceProps,
  categoryResourceProps,
  genreResourceProps,
  imageResourceProps,
  orderItemResourceProps,
  pageResourceProps,
  pageCgResourceProps,
  priceResourceProps,
  roomResourceProps,
  tvChannelGenreResourceProps,
  tvChannelLocaleResourceProps,
};

export const resourceConfig = {
  appResource,
  appVersionResource,
  categoryResource,
  contentGroupResource,
  currencyResource,
  deviceResource,
  genreResource,
  hotelResource,
  imageResource,
  integrationsConfigurationResource,
  integrationsRemoteReferenceResource,
  integrationsConfigurationRevisionResource,
  itemResource,
  pageItemResource,
  itemCgResource,
  localeResource,
  orderResource,
  orderItemResource,
  pageResource,
  pageCgResource,
  priceResource,
  roomResource,
  tvChannelResource,
  tvChannelGenreResource,
  tvChannelLocaleResource,
};
