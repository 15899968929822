import { ResourceProps } from 'react-admin';
import PagesIcon from '@mui/icons-material/Pages';
import { DataResourceOptions } from '../../common/types';
import PageList from './PageList';

export const resourceProps: ResourceProps = {
  name: 'page-cgs',
  icon: PagesIcon,
  list: PageList,
};

export default {
  name: 'page-cgs',
  singularName: 'page',
  payloadKey: 'pages',
  singularPayloadKey: 'page',
  localized: true,
} as DataResourceOptions;
