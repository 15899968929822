import { useState } from 'react';
import {
  Button,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useDataProvider,
  ToolbarProps,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

import DeclineOrderDialog from './DeclineOrderDialog';
import ConfirmOrderDialog from './ConfirmOrderDialog';
import CheckoutDialog from './checkout/CheckoutDialog';

import { HotelRecord, OrderRecord } from '../../common/types';

const OrderShowActions = ({
  hotel,
  ...props
}: ToolbarProps & { hotel: HotelRecord }) => {
  const record: OrderRecord = useRecordContext(props);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const customOrderRedirect = `/orders${location.search}`;

  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const toggleDeclineModal = () => setShowDeclineDialog(!showDeclineDialog);

  const handleDeclineSubmit = async ({
    notifyGuest,
    confirmationMessage,
  }: {
    notifyGuest: boolean;
    confirmationMessage: string;
  }) => {
    try {
      await dataProvider.orderResponse('decline', record.id, {
        order: { confirmationMessage },
        skipGuestNotification: !notifyGuest,
      });
      setShowDeclineDialog(false);
      redirect('list', customOrderRedirect);
    } catch (error) {
      notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
    }
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const toggleConfirmModal = () => setShowConfirmDialog(!showConfirmDialog);

  const handleConfirmSubmit = async ({
    notifyGuest,
    confirmationMessage,
  }: {
    notifyGuest: boolean;
    confirmationMessage: string;
  }) => {
    try {
      await dataProvider.orderResponse('confirm', record.id, {
        order: { confirmationMessage },
        skipGuestNotification: !notifyGuest,
      });
      setShowConfirmDialog(false);
      redirect('list', customOrderRedirect);
    } catch (error) {
      notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
    }
  };

  const [paymentsCheck, setPaymentsCheck] = useState(true);
  const [openPayments, setOpenPayments] = useState(true);

  const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);
  const toggleCheckoutModal = () => setShowCheckoutDialog(!showCheckoutDialog);

  const isConfirmable = ['purchased', 'requested'].includes(record?.state);
  const needsConfirmationMessage = record?.confirmationType !== 'call_to_room';
  const isCheckoutRequest = record?.orderType === 'checkout-request';

  const respondDecline = async () => {
    if (needsConfirmationMessage) {
      setShowDeclineDialog(true);
    } else {
      try {
        await dataProvider.silentOrderResponse('decline', record.id);
        redirect('list', customOrderRedirect);
      } catch (error) {
        notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
      }
    }
  };

  const respondConfirm = async () => {
    if (isCheckoutRequest) {
      setShowCheckoutDialog(true);
    } else if (needsConfirmationMessage) {
      setShowConfirmDialog(true);
    } else {
      try {
        await dataProvider.silentOrderResponse('confirm', record.id);
        redirect('list', customOrderRedirect);
      } catch (error) {
        notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
      }
    }
  };
  const [activeStep, setActiveStep] = useState('initial');

  const cancelSending = () => {
    setActiveStep('initial');
  };

  const cancelCheckoutProcess = () => {
    setShowCheckoutDialog(false);
    setActiveStep('initial');
    setPaymentsCheck(true);
  };

  const handleConfirmCheckoutSubmit = async ({
    confirmationMessage,
    uploadedFiles,
  }: {
    confirmationMessage: string;
    uploadedFiles: [];
  }) => {
    setActiveStep('sending');
    try {
      await dataProvider.confirmCheckoutWithAttachments(
        record.id,
        confirmationMessage,
        uploadedFiles
      );

      setShowCheckoutDialog(false);
      redirect('list', customOrderRedirect);
    } catch (error) {
      setActiveStep('error');
      notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
    }
  };

  const handleSendPaymentEmailSubmit = async ({
    confirmationMessage,
    paymentAmount1,
    paymentAmount2,
    uploadedFiles,
  }: {
    confirmationMessage: string;
    paymentAmount1: number;
    paymentAmount2: number;
    uploadedFiles: [];
  }) => {
    const paymentData = {
      confirmationMessage,
      charges: [{ amount: `${paymentAmount1}`, label: 'room-rate' }],
      attachments: uploadedFiles,
    };
    if (paymentAmount2 && paymentAmount2 !== 0) {
      paymentData.charges.push({
        amount: `${paymentAmount2}`,
        label: 'extras',
      });
    }

    if (paymentAmount1 && paymentAmount1 !== 0) {
      setActiveStep('sending');
      try {
        await dataProvider.sendPaymentEmail(record.id, hotel.id, paymentData);
        setShowCheckoutDialog(false);
        redirect('list', customOrderRedirect);
      } catch (error) {
        setActiveStep('error');
        notify((error as any)?.message || 'ra.page.error', { type: 'warning' });
      }
    } else {
      notify('ra.message.invalid_form', { type: 'warning' });
    }
  };

  return isConfirmable ? (
    <TopToolbar>
      <Button
        sx={{
          color: '#fff',
          backgroundColor: '#d84a38',
          '&:hover': {
            backgroundColor: '#bb2413',
          },
        }}
        color="primary"
        onClick={respondDecline}
        label="ra.action.decline"
      >
        <ClearIcon />
      </Button>
      <DeclineOrderDialog
        record={record}
        showDeclineDialog={showDeclineDialog}
        toggleDeclineModal={toggleDeclineModal}
        handleDeclineSubmit={handleDeclineSubmit}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Button
        sx={{
          color: '#fff',
          backgroundColor: '#35aa47',
          '&:hover': {
            backgroundColor: '#1d943b',
          },
        }}
        color="primary"
        onClick={respondConfirm}
        label="ra.action.confirm"
      >
        <DoneIcon />
      </Button>
      <ConfirmOrderDialog
        record={record}
        showConfirmDialog={showConfirmDialog}
        toggleConfirmModal={toggleConfirmModal}
        handleConfirmSubmit={handleConfirmSubmit}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <CheckoutDialog
        showCheckoutDialog={showCheckoutDialog}
        paymentsCheck={paymentsCheck}
        setPaymentsCheck={setPaymentsCheck}
        openPayments={openPayments}
        setOpenPayments={setOpenPayments}
        cancelCheckoutProcess={cancelCheckoutProcess}
        toggleCheckoutModal={toggleCheckoutModal}
        handleConfirmCheckoutSubmit={handleConfirmCheckoutSubmit}
        handleSendPaymentEmailSubmit={handleSendPaymentEmailSubmit}
        cancelSending={cancelSending}
        activeStep={activeStep}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </TopToolbar>
  ) : (
    <></>
  );
};

export default OrderShowActions;
