import {
  ImageField,
  FunctionField,
  ReferenceField,
  useTranslate,
  Button,
  useGetList,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { MouseEvent, useState } from 'react';
import {
  LinearProgress,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  Paper,
  ImageListItemBar,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ImageRecord } from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import InstallationTags from './InstallationTags';

const { REACT_APP_API_HOST: API_HOST } = process.env;

const ItemImageModalContent = ({
  onImageChange,
}: {
  onImageChange: (record: ImageRecord) => void;
}) => {
  const [{ hotel }] = useGlobalContext();
  const [installationTags, setInstallationTags] = useState<Number[]>([]);

  const [filterTag, setFilterTag] = useState('');
  const { isLoading, data: images = [] } = useGetList<ImageRecord>('images', {
    pagination: { page: 1, perPage: 5000 },
    sort: { field: 'id', order: 'DESC' },
    filter: {
      hotel_id_in: installationTags.length
        ? installationTags
        : [hotel?.hotelMixinIds, hotel?.id],
    },
  });

  const allTags = images.flatMap(({ tags }) => tags);
  const uniqueTags = Array.from(new Set(allTags)).sort();

  const filteredImages = images.filter(({ tags }) =>
    filterTag ? tags.includes(filterTag) : true
  );

  return (
    <>
      <DialogTitle sx={{ paddingTop: 0, paddingBottom: '5px' }}>
        <InstallationTags
          setFilterTag={setFilterTag}
          installationTags={installationTags}
          setInstallationTags={setInstallationTags}
        />
      </DialogTitle>
      <DialogContent dividers sx={{ paddingTop: '5px' }}>
        {isLoading ? null : (
          <Paper
            component="ul"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              listStyle: 'none',
              padding: 0.5,
              margin: 0,
              boxShadow: 'none',
            }}
          >
            {uniqueTags.map((tag) => (
              <li key={tag}>
                <Chip
                  sx={{
                    margin: 0.5,
                  }}
                  label={tag}
                  color={tag === filterTag ? 'primary' : 'default'}
                  onClick={() =>
                    filterTag === tag ? setFilterTag('') : setFilterTag(tag)
                  }
                />
              </li>
            ))}
          </Paper>
        )}
        {isLoading ? (
          <LinearProgress />
        ) : (
          <ImageList
            rowHeight={160}
            style={{
              gridTemplateColumns: 'repeat( auto-fill, minmax(160px, 1fr) )',
              gap: '10px',
            }}
            sx={{
              width: '100%',
              overflow: 'visible',
              justifyContent: 'center',
            }}
          >
            {filteredImages.map((record: ImageRecord) => (
              <ImageListItem
                sx={{
                  '& .MuiImageListItemBar-root': {
                    visibility: 'hidden',
                  },
                  '&:hover .MuiImageListItemBar-root': {
                    visibility: 'initial',
                  },
                }}
                key={record.id}
              >
                <Card>
                  <CardActionArea onClick={() => onImageChange(record)}>
                    <CardMedia
                      sx={{
                        height: '160px',
                        objectFit: 'cover',
                      }}
                      component="img"
                      alt=""
                      image={API_HOST + record.versions.preview}
                    />
                  </CardActionArea>
                </Card>
                <ImageListItemBar
                  sx={{
                    height: '34px',
                    '& .MuiImageListItemBar-title': {
                      fontSize: '0.8rem',
                    },
                    textAlign: 'center',
                  }}
                  title={
                    record.tags.length
                      ? `${record.tags.join(', ')}`
                      : 'Untagged'
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </DialogContent>
    </>
  );
};

const RemoveTitle = () => {
  const t = useTranslate();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }}
    >
      <CloseIcon fontSize="small" />
      <span>{t('resources.items.image-modal.remove-image')}</span>
    </div>
  );
};

const ItemImageInput = () => {
  const t = useTranslate();
  const imageId = useWatch({ name: 'imageId' });
  const { setValue, getValues } = useFormContext();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.stopPropagation();

    setShowModal(!showModal);
  };

  const handleImageChange = ({ id }: ImageRecord) => {
    setValue('imageId', id);
    setShowModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <ReferenceField
        source="imageId"
        reference="images"
        link={false}
        key={imageId}
        record={{ id: imageId, ...getValues() }}
      >
        <FunctionField<ImageRecord>
          render={(record) => (
            <ImageListItem
              sx={{
                '& .MuiImageListItemBar-root': {
                  visibility: 'hidden',
                },
                '&:hover .MuiImageListItemBar-root': {
                  visibility: 'initial',
                },
              }}
            >
              <ImageListItemBar
                sx={{
                  height: '25%',
                  cursor: 'pointer',
                  '& .MuiImageListItemBar-title': {
                    fontSize: '.9rem',
                    textAlign: 'center',
                    fontWeight: 600,
                  },
                }}
                title={<RemoveTitle />}
                onClick={() => setValue('imageId', null)}
              />
              <ImageField
                source="preview"
                key={record?.id}
                record={{
                  ...record!,
                  preview:
                    record?.versions?.preview &&
                    API_HOST + record.versions.preview,
                }}
                sx={{
                  '& .RaImageField-image': {
                    width: '250px',
                  },
                }}
              />
            </ImageListItem>
          )}
        />
      </ReferenceField>
      {!getValues('imageId') ? (
        <div>
          <Box
            sx={{
              width: '250px',
              height: '187px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              size="small"
              color="primary"
              label={t('resources.items.image-modal.choose-image')}
              variant="contained"
              onClick={toggleModal}
              sx={{ padding: '6px 20px' }}
            />
          </Box>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={showModal}
            onClose={toggleModal}
            onBackdropClick={toggleModal}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="select-item-image-title"
          >
            <DialogTitle
              id="select-item-image-title"
              sx={{ paddingBottom: '5px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {t('resources.items.image-modal.choose-image')}
                <IconButton onClick={toggleModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <ItemImageModalContent onImageChange={handleImageChange} />
          </Dialog>
        </div>
      ) : null}
    </div>
  );
};

export default ItemImageInput;
