import {
  AdminContext,
  AdminUI,
  CoreAdminContextProps,
  Resource,
} from 'react-admin';
import { useEffect } from 'react';
import { HashRouter, useSearchParams } from 'react-router-dom';
import Theme from './components/Theme';
import Dashboard from './components/Dashboard';
import { resourceProps } from './resources';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import Layout from './components/Layout';
import WithCustomQueryParams from './components/WithCustomQueryParams';
import { GlobalContextProvider } from './context/globalContext';
import MuiPickersUtilsProviderLocalized from './MuiPickersUtilsProviderLocalized';
import Notification from './Notification';
import isInIframe from './utils/is-in-iframe';

const AdminContextWithRouter = (props: CoreAdminContextProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isInIframe() && !document.body.classList.contains('loaded-in-iframe')) {
      document.body.classList.add('loaded-in-iframe');
    }
  }, []);

  return (
    <AdminContext
      authProvider={authProvider({ searchParams, setSearchParams })}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={Theme}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <WithCustomQueryParams>
        <MuiPickersUtilsProviderLocalized>
          <AdminUI
            layout={Layout}
            dashboard={Dashboard}
            notification={Notification}
          >
            {Object.values(resourceProps).map((resourceProp) => (
              <Resource key={resourceProp.name} {...resourceProp} />
            ))}
          </AdminUI>
        </MuiPickersUtilsProviderLocalized>
      </WithCustomQueryParams>
    </AdminContext>
  );
};

const App = () => (
  <GlobalContextProvider>
    <HashRouter>
      <AdminContextWithRouter />
    </HashRouter>
  </GlobalContextProvider>
);

export default App;
