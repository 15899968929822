import { ResourceProps } from 'react-admin';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OrderList from './OrderList';
import OrderShow from './OrderShow';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'orders',
  icon: NotificationsIcon,
  list: OrderList,
  show: OrderShow,
};

export default {
  name: 'orders',
  singularName: 'order',
  payloadKey: 'orders',
  singularPayloadKey: 'order',
  localized: false,
} as DataResourceOptions;
