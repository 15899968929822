import { Language } from '../enums';
import englishMessages from '../../translations/en.json';
import availableTimezones from './timezones';
import availableIntegrations from './integrations';

export const SUPPORTED_LANGUAGES = Object.values(Language);
export const DEFAULT_LANGUAGE = Language.EN;
export const DEFAULT_MESSAGES = englishMessages;
export const AVAILABLE_TIMEZONES = availableTimezones;

/** Used to pass a default value for plural translations with parameters */
export const INTL_COUNT = {
  OTHER: null,
};

export const SECONDS_IN_MINUTE = 60;

// There are several content groups listed in the drop down menu that we do not use since they are group leaders for several sub categories.
// See https://jaws.suitepad.systems/youtrack/issue/apan-293 for reference.
// This can be removed once the ContentGroup model is updated with the proper flags, indicating wether CG has a sub-category.
export const CONTENT_GROUPS_LEADER_IDS = [3, 4, 6, 8];
export const AVAILABLE_INTEGRATIONS = availableIntegrations;

export const HOTELKIT_REQUEST_TYPES = [
  'amenities',
  'bar',
  'bar2',
  'bar3',
  'bar4',
  'beauty',
  'booking',
  'childCare',
  'childItems',
  'childServices',
  'coffeeTeaItems',
  'complaint',
  'concierge',
  'events',
  'extendStay',
  'facility',
  'feedback',
  'fiaker',
  'frontOffice',
  'greenOption',
  'housekeeping',
  'iceBuckets',
  'lateCheckOut',
  'laundry',
  'luggage',
  'massage',
  'message',
  'minibar',
  'nextStay',
  'petItems',
  'petServices',
  'pillowsBlankets',
  'repair',
  'restaurant',
  'restaurant2',
  'restaurant3',
  'restaurant4',
  'roomService',
  'roomServicePickup',
  'shoeShineServices',
  'shop',
  'shop2',
  'shop3',
  'shuttleServices',
  'spa',
  'spaServices',
  'spaShop',
  'specialServices',
  'specialServices2',
  'specialServices3',
  'sweets',
  'taxi',
  'technologyItems',
  'transfer',
  'turndown',
  'wakeUpCall',
];
