import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const GuestlineInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiEndpointUrl"
        label="resources.integrations.fields.guestline.apiEndpointUrl.label"
        helperText="resources.integrations.fields.guestline.apiEndpointUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.guestline.apiEndpointUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.siteId"
        label="resources.integrations.fields.guestline.siteId.label"
        helperText="resources.integrations.fields.guestline.siteId.helperText"
        validate={required(
          'resources.integrations.fields.guestline.siteId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.interfaceId"
        label="resources.integrations.fields.guestline.interfaceId.label"
        helperText="resources.integrations.fields.guestline.interfaceId.helperText"
        validate={required(
          'resources.integrations.fields.guestline.interfaceId.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.operatorCode"
        label="resources.integrations.fields.guestline.operatorCode.label"
        helperText="resources.integrations.fields.guestline.operatorCode.helperText"
        validate={required(
          'resources.integrations.fields.guestline.operatorCode.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.password"
        label="resources.integrations.fields.guestline.password.label"
        helperText="resources.integrations.fields.guestline.password.helperText"
        validate={required(
          'resources.integrations.fields.guestline.password.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.guestline.timezone.label"
        helperText="resources.integrations.fields.guestline.timezone.helperText"
        validate={required(
          'resources.integrations.fields.guestline.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default GuestlineInputs;
