import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const Suite8Inputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.endpoint"
        label="resources.integrations.fields.suite8.endpoint.label"
        helperText="resources.integrations.fields.suite8.endpoint.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.suite8.endpoint.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.suite8.timezone.label"
        helperText="resources.integrations.fields.suite8.timezone.helperText"
        validate={required(
          'resources.integrations.fields.suite8.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.username"
        label="resources.integrations.fields.suite8.username.label"
        helperText="resources.integrations.fields.suite8.username.helperText"
        validate={required(
          'resources.integrations.fields.suite8.username.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.password"
        label="resources.integrations.fields.suite8.password.label"
        helperText="resources.integrations.fields.suite8.password.helperText"
        validate={required(
          'resources.integrations.fields.suite8.password.helperText'
        )}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default Suite8Inputs;
