import { useState } from 'react';
import useInterval from '../use-interval';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = WEEK * 4;
export const QUARTER = MONTH * 3;
export const YEAR = QUARTER * 4;

const unitToMillisecondsMap: {
  [key in Intl.RelativeTimeFormatUnit]: number;
} = {
  second: SECOND,
  seconds: SECOND,
  minute: MINUTE,
  minutes: MINUTE,
  hour: HOUR,
  hours: HOUR,
  day: DAY,
  days: DAY,
  week: WEEK,
  weeks: WEEK,
  month: MONTH,
  months: MONTH,
  quarter: QUARTER,
  quarters: QUARTER,
  year: YEAR,
  years: YEAR,
};

const getUnit = (ms: number): Intl.RelativeTimeFormatUnit => {
  if (ms < MINUTE) return 'second';
  if (ms < HOUR) return 'minute';
  if (ms < DAY) return 'hour';
  if (ms < WEEK) return 'day';
  if (ms < MONTH) return 'week';
  if (ms < QUARTER) return 'month';
  if (ms < YEAR) return 'quarter';

  return 'year';
};

const formatMillisecondsAs = (
  ms: number,
  unit: Intl.RelativeTimeFormatUnit
) => {
  if (!unitToMillisecondsMap[unit]) {
    throw new Error(`Can't format "${ms}" as "${unit}"`);
  }

  return Math.floor(ms / unitToMillisecondsMap[unit]);
};

export const getRelativeUnitsFromMilliseconds = (
  ms: number
): [number, Intl.RelativeTimeFormatUnit] => {
  const unit = getUnit(ms);
  const value = formatMillisecondsAs(ms, unit);

  return [value, unit];
};

const millisecondsNow = () => Date.now();

export const useRelativeIntlUnits = (
  target: Date
): [number, Intl.RelativeTimeFormatUnit] => {
  const unitsThatTick = new Set<Intl.RelativeTimeFormatUnit>([
    'second',
    'minute',
    'hour',
  ]);
  const targetMs = target.getTime();

  const absoluteElapsedMilliseconds = +(millisecondsNow() - targetMs);
  const [initValue, initUnit] = getRelativeUnitsFromMilliseconds(
    absoluteElapsedMilliseconds
  );
  const [value, setValue] = useState(initValue);
  const [unit, setUnit] = useState(initUnit);

  useInterval(
    () => {
      const [currentValue, currentUnit] = getRelativeUnitsFromMilliseconds(
        +(millisecondsNow() - targetMs)
      );

      setValue(currentValue);
      setUnit(currentUnit);
    },
    unitsThatTick.has(unit) ? unitToMillisecondsMap[unit] : null
  );

  return [millisecondsNow() < targetMs ? value : -value, unit];
};

export const getMinutesAndSecondsFromSeconds = (s: number) => {
  const minutes = Math.floor(s / 60);
  const seconds = +(s % 60).toFixed(0);

  return [minutes, seconds];
};
