import { Loading, NumberField, useGetOne, useRecordContext } from 'react-admin';

const PriceWithCurrency = (props: any) => {
  const record = useRecordContext(props);
  const { isLoading, data } = useGetOne('currencies', {
    id: record.currencyId,
  });

  if (isLoading) {
    return <Loading />;
  }
  return data ? (
    <NumberField
      options={{ style: 'currency', currency: data.code }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <NumberField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default PriceWithCurrency;
