import { AuthProvider } from 'react-admin';
import type { URLSearchParamsInit } from 'react-router-dom';
import { camelizeObjectKeys } from '../utils/object';
import { HotelRecord, LocaleRecord } from '../common/types';

const {
  REACT_APP_SENTINEL_HOST: SENTINEL_HOST,
  REACT_APP_SENTINEL_NAMESPACE: SENTINEL_NAMESPACE,
  REACT_APP_API_HOST: API_HOST,
  REACT_APP_API_NAMESPACE: API_NAMESPACE,
} = process.env;

const SENTINEL_URL = `${SENTINEL_HOST}/${SENTINEL_NAMESPACE}`;

class SentinelAdapter {
  url = SENTINEL_URL;

  headers = new Headers({ 'Content-Type': 'application/vnd.api+json' });

  async login({ email, password }: { email: string; password: string }) {
    const {
      auth_token: authToken,
      client_id: clientId,
      user_id: userId,
    } = await this.request(`${SENTINEL_URL}/users/sign_in`, 'POST', {
      user: { email, password },
    });

    this.setAuthHeaders({ authToken, clientId });

    return { authToken, clientId, userId };
  }

  setAuthHeaders({
    authToken,
    clientId,
  }: {
    authToken: string;
    clientId: string;
  }) {
    return this.headers.set(
      'Authorization',
      `Token ${authToken};client_id=${clientId}`
    );
  }

  async request(url = '', method = 'GET', body?: object) {
    const request = new Request(url, {
      headers: this.headers,
      body: body ? JSON.stringify(body) : undefined,
      method,
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return response.json();
  }
}

const parseUserDataFromJsonapiResponseInAVeryUglyWay = ({ data = {} }: any) => {
  const { 'meta-data': metaData, ...attributes } = data.attributes;
  const result = {
    id: data.id,
    ...attributes,
    ...metaData,
  };

  return camelizeObjectKeys(result);
};
const parseUserRolesDataFromJsonapiResponseInAVeryUglyWay = ({
  data = [],
}: any) =>
  data.map((roleData: any) =>
    camelizeObjectKeys({
      id: roleData.id,
      ...roleData.attributes,
    })
  );

const getDefaultHotel = async (adapter: SentinelAdapter) => {
  const [defaultHotelRaw] = (
    await adapter.request(
      `${API_HOST}/${API_NAMESPACE}/hotels?limit=1&offset=0&filter[s]=id`
    )
  ).hotels;

  if (!defaultHotelRaw) {
    throw new Error('errors.empty-default-hotels-response');
  }

  return camelizeObjectKeys(defaultHotelRaw);
};

const getLocale = async (adapter: SentinelAdapter, id: number) => {
  const { locale } = await adapter.request(
    `${API_HOST}/${API_NAMESPACE}/locales/${id}`
  );

  return camelizeObjectKeys(locale);
};

const authProvider: ({
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: {
      replace?: boolean;
      state?: any;
    }
  ) => void;
}) => AuthProvider = ({ searchParams, setSearchParams }) => ({
  adapter: new SentinelAdapter(),

  isLoginThroughQPInProgress: false,

  async login(
    credentials:
      | { username: string; password: string }
      | {
          authToken: string;
          clientId: string;
          userId: string;
          username?: string;
          password?: string;
        }
  ) {
    try {
      const loginWithUsername =
        'username' in credentials && 'password' in credentials;

      const { authToken, clientId, userId } = loginWithUsername
        ? await this.adapter.login({
            email: credentials.username,
            password: credentials.password,
          })
        : credentials;

      if (!loginWithUsername) {
        this.adapter.setAuthHeaders({ authToken, clientId });
      }

      const [userAsJsonapi, rolesAsJsonapi, defaultHotel]: [
        string,
        string,
        HotelRecord
      ] = await Promise.all([
        this.adapter.request(`${SENTINEL_URL}/users/${userId}`),
        this.adapter.request(`${SENTINEL_URL}/users/${userId}/roles`),
        getDefaultHotel(this.adapter),
      ]);

      const defaultLocale: LocaleRecord = await getLocale(
        this.adapter,
        defaultHotel.defaultLocaleId
      );

      const user = parseUserDataFromJsonapiResponseInAVeryUglyWay(
        userAsJsonapi
      );
      const roleRecords = parseUserRolesDataFromJsonapiResponseInAVeryUglyWay(
        rolesAsJsonapi
      );
      const roles = roleRecords.map(({ name }: any) => name);

      localStorage.setItem('authToken', authToken);
      localStorage.setItem('clientId', clientId);

      if (!localStorage.getItem('locale')) {
        localStorage.setItem('locale', defaultLocale.key);
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('roles', JSON.stringify(roles));
      localStorage.setItem('defaultHotel', JSON.stringify(defaultHotel));
    } catch (error: any) {
      throw new Error(error?.message);
    }
  },

  logout: () => {
    try {
      localStorage.clear();
    } catch {
      // ignore error
    }
    return Promise.resolve();
  },

  async loginThroughQP() {
    this.isLoginThroughQPInProgress = true;

    try {
      const authTokenParamIntent = searchParams.get('auth_token');
      const clientIdParamIntent = searchParams.get('client_id');
      const userIdParamIntent = searchParams.get('user_id');

      return await this.login({
        authToken: authTokenParamIntent,
        clientId: clientIdParamIntent,
        userId: userIdParamIntent,
      });
    } catch (error: any) {
      throw new Error(error?.message || error);
    } finally {
      searchParams.delete('auth_token');
      searchParams.delete('client_id');
      searchParams.delete('user_id');

      setSearchParams(searchParams);

      this.isLoginThroughQPInProgress = false;
    }
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  hasAuthQP() {
    return (
      searchParams.has('auth_token') &&
      searchParams.has('client_id') &&
      searchParams.has('user_id')
    );
  },

  async checkAuth() {
    if (this.isLoginThroughQPInProgress) {
      return Promise.resolve();
    }

    if (this.hasAuthQP()) {
      return this.loginThroughQP();
    }

    let isAuthTokenPresent = false;

    try {
      isAuthTokenPresent = !!localStorage.getItem('authToken');
    } catch {
      // ignore error
    }

    return isAuthTokenPresent ? Promise.resolve() : Promise.reject();
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const rolesString = localStorage.getItem('roles');

    return rolesString
      ? Promise.resolve(JSON.parse(rolesString))
      : Promise.resolve([]);
  },
});

export default authProvider;
