import { Button, useTranslate, FormDataConsumer } from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

const ErrorState = (props: any) => {
  const {
    cancelSending,
    checkoutButtonClass,
    dialogContentClass,
    dialogMessageClass,
    handleConfirmCheckoutSubmit,
    handleSendPaymentEmailSubmit,
    openPayments,
  } = props;
  const t = useTranslate();
  return (
    <DialogContent className={dialogContentClass}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <ClearIcon style={{ fontSize: 40 }} />
        </Grid>
        <Grid item>
          <div className={dialogMessageClass}>
            {t('resources.orders.checkout.states.error')}
          </div>
        </Grid>
        <Grid item>
          <FormDataConsumer>
            {({ formData }: any) => (
              <Button
                onClick={() =>
                  openPayments
                    ? handleSendPaymentEmailSubmit(formData)
                    : handleConfirmCheckoutSubmit(formData)
                }
                color="primary"
                size="medium"
                variant="contained"
                label="ra.action.tryAgain"
                className={checkoutButtonClass}
              />
            )}
          </FormDataConsumer>
        </Grid>
        <Grid item>
          <Button
            onClick={cancelSending}
            color="inherit"
            size="medium"
            variant="contained"
            label="ra.action.cancel"
            className={checkoutButtonClass}
          >
            <ClearIcon />
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default ErrorState;
