import { Skeleton } from '@mui/material';
import { lazy, Suspense } from 'react';
import {
  FieldTitle,
  getFieldLabelTranslationArgs,
  InputProps,
  useInput,
  useResourceDefinition,
  useTranslate,
} from 'react-admin';
import Typography from '../components/Typography';

const MonacoEditor = lazy(() => import('@monaco-editor/react'));

const MonacoEditorField = (props: InputProps & { disabled?: boolean }) => {
  const { name: resourceName } = useResourceDefinition();
  const t = useTranslate();

  const { disabled = true, resource, source, label } = props;
  const {
    field: { name, onChange, value },
    isRequired,
  } = useInput(props);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        <FieldTitle
          label={t(
            ...getFieldLabelTranslationArgs({
              label: label as string,
              resource: resourceName,
              source,
            })
          )}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </Typography>
      <Suspense
        fallback={<Skeleton variant="rectangular" height={400} width="100%" />}
      >
        <MonacoEditor
          height={400}
          defaultLanguage="javascript"
          theme="vs-dark"
          loading="Loading Code Editor..."
          defaultValue={value}
          value={value}
          options={{
            readOnly: disabled,
            domReadOnly: disabled,
            renderWhitespace: 'all',
            tabSize: 2,
            padding: {
              top: 20,
            },
            ariaLabel: name,
          }}
          onChange={onChange}
        />
      </Suspense>
    </>
  );
};

export default MonacoEditorField;
