import MaterialStaticDatePicker from '@mui/lab/StaticDatePicker';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import isSameDay from 'date-fns/isSameDay';
import classNames from 'classnames';
import { PickersDay, PickersDayProps } from '@mui/lab';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'PickersDayWrapper';

const classes = {
  highlight: `${PREFIX}-highlight`,
  firstHighlight: `${PREFIX}-first-highlight`,
  lastHighlight: `${PREFIX}-last-highlight`,
  rangeHighlight: `${PREFIX}-range-highlight`,
};

const PickersDayWrapper = styled('div')({
  [`&.${classes.highlight}`]: {
    backgroundColor: '#7a9bdf33',
  },
  [`&.${classes.firstHighlight}`]: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  [`&.${classes.lastHighlight}`]: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  [`&.${classes.rangeHighlight}`]: {
    '& > button': {
      color: 'inherit',
      border: '2px solid #7a9bdf33',
      backgroundColor: 'inherit',
    },
  },
});

const DatePicker = ({
  onChange,
  date,
  range,
  isStart = false,
  isEnd = false,
}: {
  onChange: (date: Date | null) => void;
  date: Date | null;
  range: Date | null;
  isStart?: boolean;
  isEnd?: boolean;
}) => {
  if (!isStart && !isEnd) {
    throw new Error(
      'You must specify if the date picker is start or end of the range'
    );
  }

  // const classes = useDatePickerStyles();

  const start = isStart ? date : range;
  const end = isEnd ? date : range;

  const handleChange = (newDate: Date | null) => {
    const isEndOfRangeClashingWithStart =
      isEnd && start && newDate && newDate < start;
    const isStartOfRangeClashingWithEnd =
      isStart && end && newDate && newDate > end;

    const isOutsideBoundaries =
      isEndOfRangeClashingWithStart || isStartOfRangeClashingWithEnd;

    // ignore attempts to select date range where start > end OR end < start
    if (isOutsideBoundaries) {
      return;
    }

    onChange(newDate ? startOfDay(newDate) : newDate);
  };

  return (
    <MaterialStaticDatePicker<Date>
      showToolbar={false}
      openTo="day"
      value={date}
      onChange={handleChange}
      renderInput={(props) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
      renderDay={(
        day: Date,
        _selectedDate: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>
      ) => {
        const isInRange =
          start &&
          end &&
          range &&
          isWithinInterval(day as Date, { start, end });
        const isFirstDay = start && isSameDay(day as Date, start);
        const isLastDay = end && isSameDay(day as Date, end);
        const isRange = range && isSameDay(day as Date, range);

        return (
          <PickersDayWrapper
            key={pickersDayProps.key}
            className={classNames({
              [classes.highlight]: isInRange,
              [classes.firstHighlight]: isFirstDay,
              [classes.lastHighlight]: isLastDay,
              [classes.rangeHighlight]: isRange,
            })}
          >
            <PickersDay
              sx={{
                backgroundColor: 'transparent',
                '&.MuiPickersDay-today': {
                  border: 'none',
                  color: 'primary.main',
                },
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...pickersDayProps}
            />
          </PickersDayWrapper>
        );
      }}
    />
  );
};

export default DatePicker;
