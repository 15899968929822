import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const InforInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.apiBaseUrl"
        label="resources.integrations.fields.infor.apiBaseUrl.label"
        helperText="resources.integrations.fields.infor.apiBaseUrl.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.infor.apiBaseUrl.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.password"
        label="resources.integrations.fields.infor.password.label"
        helperText="resources.integrations.fields.infor.password.helperText"
        validate={required(
          'resources.integrations.fields.infor.password.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.user"
        label="resources.integrations.fields.infor.user.label"
        helperText="resources.integrations.fields.infor.user.helperText"
        validate={required(
          'resources.integrations.fields.infor.user.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.property"
        label="resources.integrations.fields.infor.property.label"
        helperText="resources.integrations.fields.infor.property.helperText"
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default InforInputs;
