import { BooleanInput, Create, required, TextInput } from 'react-admin';
import CreateViewToolbar from '../CreateViewToolbar';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';

const AppCreate = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Create {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<CreateViewToolbar />}
      type="create"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput
        source="packageName"
        variant="outlined"
        validate={required()}
      />
      <BooleanInput source="cacheEnabled" />
      <BooleanInput source="mandatory" />
      <TextInput
        source="postInstallationScript"
        variant="outlined"
        rows={3}
        multiline
        fullWidth
      />
    </SimpleFormWithServerSideFieldValidation>
  </Create>
);

export default AppCreate;
