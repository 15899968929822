import { Box } from '@mui/material';
import {
  InputProps,
  regex,
  required,
  TextInput,
  WrapperField,
  CheckboxGroupInput,
  Labeled,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';
import StyledBooleanInput from './StyledBooleanInput';

const RoomBillOptionsInput = () => {
  const roomBillOptions = [
    'excludeRoomRate',
    'spaDetailed',
    'extrasDetailed',
    'telDetailed',
    'includeSettled',
  ];

  const formatRoomBillOptions = (record: { [key: string]: boolean }) =>
    record ? Object.keys(record).filter((key) => record[key]) : [];

  const parseRoomBillOptions = (inputValue: string[]) =>
    roomBillOptions.reduce((obj: { [key: string]: boolean }, key: string) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = inputValue.includes(key);
      return obj;
    }, {});

  return (
    <CheckboxGroupInput
      source="currentConfig.service.roomBillOptions"
      label="resources.integrations.fields.asa.roomBillOptions.label"
      choices={roomBillOptions.map((option) => ({
        id: option,
        name: option,
      }))}
      format={formatRoomBillOptions}
      parse={parseRoomBillOptions}
    />
  );
};

const WebhookUrl = () => {
  const record = useRecordContext();

  if (!(record && record.id)) return null;

  const urlBase = 'https://it3.suitepad.systems/v2/webhooks/asa/';
  const webhookId = record.integrationUuid.split('-').slice(1, -1).join('-');

  return (
    <Labeled label="resources.integrations.fields.asa.webhookUrl.label">
      <FunctionField render={() => urlBase + webhookId} />
    </Labeled>
  );
};

const AsaInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.reservationsApiEndpointUrl"
        label="resources.integrations.fields.asa.reservationsApiEndpointUrl.label"
        helperText="resources.integrations.fields.asa.reservationsApiEndpointUrl.helperText"
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.asa.timezone.label"
        helperText="resources.integrations.fields.asa.timezone.helperText"
        validate={required(
          'resources.integrations.fields.asa.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.checkInTime"
        label="resources.integrations.fields.asa.checkInTime.label"
        helperText="resources.integrations.fields.asa.checkInTime.helperText"
        validate={regex(
          /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
          'resources.integrations.fields.asa.checkInTime.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.checkOutTime"
        label="resources.integrations.fields.asa.checkOutTime.label"
        helperText="resources.integrations.fields.asa.checkOutTime.helperText"
        validate={regex(
          /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
          'resources.integrations.fields.asa.checkOutTime.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.defaultGuestLastName"
        label="resources.integrations.fields.asa.defaultGuestLastName.label"
        helperText="resources.integrations.fields.asa.defaultGuestLastName.helperText"
        variant="outlined"
        fullWidth
      />
      <StyledBooleanInput
        source="currentConfig.service.roomBillEnabled"
        label="resources.integrations.fields.asa.roomBillEnabled.label"
        helperText="resources.integrations.fields.asa.roomBillEnabled.helperText"
        variant="outlined"
      />
      <RoomBillOptionsInput />
      <WebhookUrl />
    </Box>
  </WrapperField>
);

export default AsaInputs;
