import { useEffect } from 'react';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { useKeenQuery } from '../../../../utils/keen';

const useAverageRevenueByCategoryData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const [
    {
      data: averageRevenueByCategory = [],
      loading: isAverageRevenueByCategoryLoading,
    },
    queryAverageRevenueByCategory,
  ] = useKeenQuery<{ categoryId: string; result: number }[]>();

  useEffect(() => {
    queryAverageRevenueByCategory({
      analysisType: 'average',
      eventCollection: 'orders',
      targetProperty: 'total',
      groupBy: 'category_id',
      filters: [
        {
          propertyName: 'hotel_id',
          operator: 'eq',
          propertyValue: hotel.id,
        },
        {
          propertyName: 'test_order',
          operator: 'eq',
          propertyValue: false,
        },
      ],
      timeframe,
    });
  }, [hotel.id, queryAverageRevenueByCategory, timeframe]);

  return {
    data: averageRevenueByCategory,
    loading: isAverageRevenueByCategoryLoading,
  };
};

export default useAverageRevenueByCategoryData;
