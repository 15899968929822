import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import { INTL_COUNT } from '../../../../common/constants';
import { useKeenQuery } from '../../../../utils/keen';
import { useIntl } from '../../../../context/intlContext';
import {
  CategoryRecord,
  CurrencyRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useRevenueWeekdayAverageData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
  currency: CurrencyRecord;
}) => {
  const intl = useIntl();
  const t = useTranslate();

  const [
    {
      data: revenueWeekdayAverage = [],
      loading: isRevenueWeekdayAverageLoading,
    },
    queryRevenueWeekdayAverage,
  ] = useKeenQuery<{ dateDayOfWeek: number; result: number }[]>();

  useEffect(() => {
    queryRevenueWeekdayAverage({
      analysisType: 'average',
      eventCollection: 'orders',
      targetProperty: 'total',
      groupBy: 'date.day_of_week',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
          ],
      timeframe,
    });
  }, [hotel.id, queryRevenueWeekdayAverage, timeframe, selectedCategory]);

  const dayToDataMap = revenueWeekdayAverage.reduce(
    (
      acc: { [key: number]: { dateDayOfWeek: number; result: number } },
      val: { dateDayOfWeek: number; result: number }
    ) => {
      acc[val.dateDayOfWeek] = val;
      return acc;
    },
    {}
  );
  const zeroFilledRevenueWeekdayAverage = [...Array(7)].map(
    (_, index) => dayToDataMap[index] || { dateDayOfWeek: index, result: 0 }
  );

  const revenueWeekdayAverageData = zeroFilledRevenueWeekdayAverage.map(
    ({ dateDayOfWeek, result }) => ({
      id: t(`settings.day-of-week.${dateDayOfWeek}`, {
        count: INTL_COUNT.OTHER,
      }),
      value: result,
      tooltip: {
        title: t(`settings.day-of-week.${dateDayOfWeek}`, {
          count: INTL_COUNT.OTHER,
        }),
        subtitle: intl.formatNumber(result, {
          style: 'currency',
          currency: currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const formatAxisRight = (value: number) =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: currency.code,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const chartProps: UsageTotalsKeenChartProps = {
    data: revenueWeekdayAverageData,
    formatAxisRight,
    color: '#7fa6db',
    skipEvenBottomLabels: false,
  };

  return {
    chartProps,
    loading: isRevenueWeekdayAverageLoading,
  };
};

export default useRevenueWeekdayAverageData;
