import { useFormContext, useWatch } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { ConfirmationMethod } from '../../common/enums';

const ConfirmationMethodInput = ({
  checked,
  label,
  onChange,
  value,
}: {
  checked: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} value={value} />}
    label={label}
  />
);

const CategoryConfirmationMethodInputs = () => {
  const t = useTranslate();
  const confirmationMethods = useWatch({ name: 'confirmationMethods' });
  const { setValue } = useFormContext();

  const [parsedConfirmationMethods, setParsedConfirmationMethods] = useState<
    ConfirmationMethod[]
  >([]);

  useEffect(() => {
    setParsedConfirmationMethods(confirmationMethods.split(','));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const method = event.target.value as ConfirmationMethod;

    if (parsedConfirmationMethods.includes(method)) {
      setParsedConfirmationMethods(
        parsedConfirmationMethods.filter((cm) => cm !== method)
      );
    } else {
      setParsedConfirmationMethods([...parsedConfirmationMethods, method]);
    }
  };

  useEffect(() => {
    setValue('confirmationMethods', parsedConfirmationMethods.join(','));
  }, [parsedConfirmationMethods, setValue]);

  return (
    <Grid container>
      <Grid item>
        <ConfirmationMethodInput
          checked={parsedConfirmationMethods.includes(
            ConfirmationMethod.CallToRoom
          )}
          label={t('resources.categories.confirmationMethodTypes.callToRoom')}
          value={ConfirmationMethod.CallToRoom}
          onChange={handleCheckboxChange}
        />
      </Grid>
      <Grid item>
        <ConfirmationMethodInput
          checked={parsedConfirmationMethods.includes(ConfirmationMethod.Sms)}
          label={t('resources.categories.confirmationMethodTypes.sms')}
          value={ConfirmationMethod.Sms}
          onChange={handleCheckboxChange}
        />
      </Grid>
      <Grid item>
        <ConfirmationMethodInput
          checked={parsedConfirmationMethods.includes(ConfirmationMethod.Email)}
          label={t('resources.categories.confirmationMethodTypes.email')}
          value={ConfirmationMethod.Email}
          onChange={handleCheckboxChange}
        />
      </Grid>
      <Grid item>
        <ConfirmationMethodInput
          checked={parsedConfirmationMethods.includes(ConfirmationMethod.Push)}
          label={t('resources.categories.confirmationMethodTypes.push')}
          value={ConfirmationMethod.Push}
          onChange={handleCheckboxChange}
        />
      </Grid>
    </Grid>
  );
};

export default CategoryConfirmationMethodInputs;
