import { useEffect, useRef } from 'react';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: Function, delay: number | null = null) => {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }
    if (delay !== null) {
      const intervalID = setInterval(tick, delay);
      return () => clearInterval(intervalID);
    }

    return () => {};
  }, [delay]);
};

export default useInterval;
