import { useTranslate } from 'react-admin';
import { useEffect } from 'react';
import {
  CategoryRecord,
  CurrencyRecord,
  HotelRecord,
  Timeframe,
} from '../../../../common/types';
import { useIntl } from '../../../../context/intlContext';
import { formatTime, formatTimeRange } from '../../../../utils/intl';
import { useKeenQuery } from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useRevenueHourlyAverageData = ({
  hotel,
  timeframe,
  selectedCategory,
  currency,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
  selectedCategory: CategoryRecord | null;
  currency: CurrencyRecord;
}) => {
  const t = useTranslate();
  const intl = useIntl();

  const [
    { data: revenueHourlyAverage = [], loading: isRevenueHourlyAverageLoading },
    queryRevenueHourlyAverage,
  ] = useKeenQuery<{ dateHourOfDay: number; result: number }[]>();

  useEffect(() => {
    queryRevenueHourlyAverage({
      analysisType: 'average',
      eventCollection: 'orders',
      targetProperty: 'total',
      groupBy: 'date.hour_of_day',
      filters: selectedCategory
        ? [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
            {
              propertyName: 'category_id',
              operator: 'eq',
              propertyValue: selectedCategory.id,
            },
          ]
        : [
            {
              propertyName: 'hotel_id',
              operator: 'eq',
              propertyValue: hotel.id,
            },
            {
              propertyName: 'test_order',
              operator: 'eq',
              propertyValue: false,
            },
          ],
      timeframe,
    });
  }, [hotel.id, queryRevenueHourlyAverage, timeframe, selectedCategory]);

  const hourToDataMap = revenueHourlyAverage.reduce(
    (
      acc: { [key: number]: { dateHourOfDay: number; result: number } },
      val: { dateHourOfDay: number; result: number }
    ) => {
      acc[val.dateHourOfDay] = val;
      return acc;
    },
    {}
  );
  const zeroFilledRevenueHourlyAverage = [...Array(24)].map(
    (_, index) => hourToDataMap[index] || { dateHourOfDay: index, result: 0 }
  );

  const revenueHourlyAverageData = zeroFilledRevenueHourlyAverage.map(
    ({ dateHourOfDay, result }) => ({
      id: formatTime(dateHourOfDay, intl),
      value: result,
      tooltip: {
        title: formatTimeRange(dateHourOfDay, dateHourOfDay + 1, intl),
        subtitle: intl.formatNumber(result, {
          style: 'currency',
          currency: currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
    })
  );

  const formatAxisRight = (value: number) =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: currency.code,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const chartProps: UsageTotalsKeenChartProps = {
    data: revenueHourlyAverageData,
    legend: t('charts.order.legend.hours'),
    formatAxisRight,
    color: '#7fa6db',
  };

  return {
    chartProps,
    loading: isRevenueHourlyAverageLoading,
  };
};

export default useRevenueHourlyAverageData;
