import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartListTab = styled(Tab)({
  '&.MuiTab-root': {
    maxWidth: '100%',
    minWidth: 'auto',
    fontSize: '16px',
    fontWeight: 600,
    color: '#a09cab',
    textTransform: 'none',
    padding: '20px 4px',
    alignItems: 'flex-start',

    '&:focus::after': {
      opacity: 1,
      background: 'none',
    },
  },
  '&.Mui-selected': {
    opacity: 1,
    fontWeight: 600,
    color: '#3d3a46',
  },
});

export default ChartListTab;
