import { TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';

const ChartMainTabPanel = styled(TabPanel)({
  '&.MuiTabPanel-root': {
    padding: 0,
    height: '280px',
    width: '100%',
  },
});

export default ChartMainTabPanel;
