import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'tv-channel-locales',
};

export default {
  name: 'tv-channel-locales',
  singularName: 'tv-channel-locale',
  payloadKey: 'room_control/tv_channel_locales',
  singularPayloadKey: 'room_control/tv_channel_locale',
  localized: false,
} as DataResourceOptions;
