import { Box, Link, List, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { AVAILABLE_INTEGRATIONS } from '../../common/constants';
import { Integration, IntegrationType } from '../../common/enums';
import { IntegrationRecord } from '../../common/types';
import IntegrationsListItem from './IntegrationsListItem';

const ADMIN_ONLY_INTEGRATIONS: Integration[] = [];

const integrationsByType = AVAILABLE_INTEGRATIONS.reduce((acc, val) => {
  if (!acc[val.integrationType]) acc[val.integrationType] = [];

  acc[val.integrationType].push(val);

  return acc;
}, {} as { [key in IntegrationType]: IntegrationRecord[] });

const getIntegrationsFor = (
  type: IntegrationType,
  { isAdmin = false }: { isAdmin?: boolean } = {}
) =>
  integrationsByType[type].filter(
    (integration) =>
      isAdmin || !ADMIN_ONLY_INTEGRATIONS.includes(integration.id)
  );

const AvailableIntegrationsList = ({
  onSelect,
}: {
  onSelect: (value: Integration) => void;
}) => {
  const t = useTranslate();
  const bannerEmail = t(
    'resources.integrations-configurations.list-banner.email'
  );

  return (
    <Box>
      <Typography
        variant="body2"
        ml={2}
        mt={1}
        sx={{
          color: 'rgba(0,0,0,0.6)',
        }}
      >
        {t('resources.integrations-configurations.list-banner.text', {
          email: (
            <Link href={`mailto:${bannerEmail}`} color="inherit">
              {bannerEmail}
            </Link>
          ),
        })}
      </Typography>
      <Typography variant="h5" ml={2} mt={3}>
        {t(
          `resources.integrations-configurations.integration-types.${IntegrationType.PMS}`
        )}
      </Typography>
      <List>
        {getIntegrationsFor(IntegrationType.PMS).map((integration) => (
          <IntegrationsListItem
            key={integration.id}
            integration={integration}
            onSelect={onSelect}
          />
        ))}
      </List>
      <Typography variant="h5" ml={2} mt={3}>
        {t(
          `resources.integrations-configurations.integration-types.${IntegrationType.POS}`
        )}
      </Typography>
      <List>
        {getIntegrationsFor(IntegrationType.POS).map((integration) => (
          <IntegrationsListItem
            key={integration.id}
            integration={integration}
            onSelect={onSelect}
          />
        ))}
      </List>
      <Typography variant="h5" ml={2} mt={3}>
        {t(
          `resources.integrations-configurations.integration-types.${IntegrationType.SRS}`
        )}
      </Typography>
      <List>
        {getIntegrationsFor(IntegrationType.SRS).map((integration) => (
          <IntegrationsListItem
            key={integration.id}
            integration={integration}
            onSelect={onSelect}
          />
        ))}
      </List>
    </Box>
  );
};

export default AvailableIntegrationsList;
