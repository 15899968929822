import { ResourceProps } from 'react-admin';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'images',
};

export default {
  name: 'images',
  singularName: 'image',
  payloadKey: 'images',
  singularPayloadKey: 'image',
  localized: false,
} as DataResourceOptions;
