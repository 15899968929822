import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';

const CurrencyFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="name_or_code_or_symbol_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const CurrencyList = (props: any) => (
  <List
    exporter={false}
    filters={<CurrencyFilter />}
    pagination={<ListPagination />}
    perPage={50}
    actions={<ListViewTopToolbar />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="symbol" />
      <EditButton className="text-list-button" />
    </Datagrid>
  </List>
);

export default CurrencyList;
