import { useState } from 'react';
import useInterval from '../../utils/use-interval';

const ThreeDotsProgress = ({
  dot: dotChar = '.',
  min: minLength = 1,
  max: maxLength = 3,
  interval = 200,
}) => {
  const initDots: () => string[] = () => new Array(minLength).fill(dotChar);

  const [dots, setDots] = useState(initDots());

  useInterval(() => {
    setDots(dots.length < maxLength ? [...dots, dotChar] : initDots());
  }, interval);

  return (
    <span
      data-testid="three-dots-container"
      style={{
        display: 'inline-block',
        width: `${maxLength}ch`,
      }}
    >
      {dots.map((dot) => dot)}
    </span>
  );
};

export default ThreeDotsProgress;
