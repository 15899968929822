import { useCallback, useState } from 'react';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { TempUploadRecord } from '../../../common/types';
import FileInput, { UploadedFile } from '../../FileInput';

const BillsUploader = () => {
  const t = useTranslate();
  const notify = useNotify();
  const { setValue } = useFormContext();
  const dataProvider = useDataProvider();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setIsUploading(true);

        if (!acceptedFiles.length) return;

        const uploadPromises = acceptedFiles.map(async (acceptedFile) => {
          const {
            data: tempFile,
          }: { data: TempUploadRecord } = await dataProvider.tempUpload(
            acceptedFile
          );

          return { ...tempFile, name: acceptedFile.name };
        });

        const tempFiles = await Promise.all(uploadPromises);

        setValue(
          'uploadedFiles',
          tempFiles.map(({ uuid }) => uuid)
        );
        setUploadedFiles(tempFiles);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);

        notify(error?.message || 'ra.page.error', { type: 'warning' });
      } finally {
        setIsUploading(false);
      }
    },
    [setValue, dataProvider, notify]
  );

  const onDelete = (fileToRemove: UploadedFile) => {
    setUploadedFiles(uploadedFiles.filter((file) => file !== fileToRemove));
  };

  const dropLabel = t('resources.orders.checkout.billsUpload.dropHere');
  const uploadedLabel = `${uploadedFiles.length} ${t(
    'resources.orders.checkout.billsUpload.filesNumber'
  )}`;

  return (
    <FileInput
      dropLabel={dropLabel}
      uploadedLabel={uploadedLabel}
      onDrop={onDrop}
      onDelete={onDelete}
      isLoading={isUploading}
      files={uploadedFiles}
    />
  );
};

export default BillsUploader;
