import {
  TabbedForm,
  TabbedFormProps,
  useUpdate,
  useCreate,
  useNotify,
  useRedirect,
  useEditContext,
  useCreateContext,
} from 'react-admin';
import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';

type RawErrorMessage = {
  message: string;
  meta: {
    value: string;
  };
};

type RawErrorsObject = {
  [key: string]: RawErrorMessage[];
};

type ErrorsObject = {
  [key: string]: string;
};

export const tryToParseFieldValidationErrors = (
  errors: RawErrorsObject
): ErrorsObject | null => {
  try {
    return Object.entries(errors).reduce<ErrorsObject>(
      (acc, [fieldName, fieldErrors]) => {
        const {
          message,
          meta: { value },
        } = fieldErrors[0];
        const capitalizedFieldName = fieldName.replace(/^\w/, (c) =>
          c.toUpperCase()
        );

        acc[fieldName] = `${capitalizedFieldName} "${value}" is ${message}.`;

        return acc;
      },
      {}
    );
  } catch {
    return null;
  }
};

const DEFAULT_REDIRECT_PATH = 'edit';

const TabbedFormWithServerSideValidation = (
  props: TabbedFormProps & {
    type: 'edit' | 'create';
  }
) => {
  const editContext = useEditContext();
  const createContext = useCreateContext();
  const [update] = useUpdate();
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const { type } = props;
  const simpleFormTypeToPropsMap = {
    edit: editContext,
    create: createContext,
  };
  const { record, resource } = simpleFormTypeToPropsMap[type];

  const onSubmit = useCallback(
    async (data: FieldValues, redirectTo = DEFAULT_REDIRECT_PATH) => {
      try {
        const simpleFormTypeToMutationTypeMap = { edit: update, create };
        const result = await simpleFormTypeToMutationTypeMap[type](
          resource,
          {
            data,
            id: record?.id,
            previousData: record,
          },
          { returnPromise: true }
        );

        if (type === 'edit') {
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: {
              smart_count: 1,
            },
          });
          redirect(redirectTo, resource, record?.id, record);

          return undefined;
        }

        notify('ra.notification.created', {
          type: 'info',
          messageArgs: {
            smart_count: 1,
          },
        });
        redirect(redirectTo, resource, result.id, result);

        return undefined;
      } catch (error: any) {
        const errorMessage = error?.message;
        const notifyOfError = () =>
          notify(
            typeof error === 'string'
              ? error
              : errorMessage || 'ra.notification.http_error',
            { type: 'warning' }
          );

        if (error?.status === 422 && error?.body?.errors) {
          const serverSideValidationErrors = tryToParseFieldValidationErrors(
            error.body.errors
          );

          if (serverSideValidationErrors) {
            notify('ra.message.invalid_form', { type: 'warning' });
          } else {
            notifyOfError();
          }

          // we have to return the validation errors only in case they exists
          // otherwise the first form field will always display the error, even
          // when the error itself is not related to the field
          return serverSideValidationErrors || undefined;
        }

        notifyOfError();
        return undefined;
      }
    },
    [update, create, type, resource, record, notify, redirect]
  );

  return (
    <TabbedForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onSubmit={onSubmit}
    />
  );
};

export default TabbedFormWithServerSideValidation;
