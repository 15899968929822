import { useCallback, useEffect, useRef, useState } from 'react';
import KeenAnalysis from 'keen-analysis';
import { TimeframeAsString } from '../common/enums';
import { Timeframe, TimeframeAsObject } from '../common/types';
import { camelizeObjectKeys } from './object';
import { SECONDS_IN_MINUTE } from '../common/constants';

type KeenOptions = {
  [key: string]: any;
};

export enum PageViewsRequest {
  Daily = 'daily',
  Weekly = 'weekly',
  Hourly = 'hourly',
}

const {
  REACT_APP_KEEN_HOST: KEEN_HOST,
  REACT_APP_KEEN_API_KEY: KEEN_API_KEY,
  REACT_APP_KEEN_PROJECT_ID: KEEN_PROJECT_ID,
} = process.env;

export const convertToLocalAdjustedUTC = (dateTimeString: string) => {
  const localDate = new Date(dateTimeString);
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());

  return localDate.toISOString();
};

const getDiffInDays = (from: Date, to: Date) => {
  const dayInMs = 1000 * 60 * 60 * 24;
  const diffInMs =
    to.getTime() -
    from.getTime() +
    (from.getTimezoneOffset() - to.getTimezoneOffset()) * 60 * 1000;

  return Math.floor(diffInMs / dayInMs);
};

const client = new KeenAnalysis({
  projectId: KEEN_PROJECT_ID,
  readKey: KEEN_API_KEY,
  host: KEEN_HOST,
});

export const useKeenQuery = <S>() => {
  const isMountedRef = useRef(false);
  const [data, setData] = useState<S>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
  }, [isMountedRef]);

  const query = useCallback(
    async (options: KeenOptions) => {
      try {
        setLoading(true);
        const { result }: { result: any } = await client.query(options);

        const isArray = Array.isArray(result);
        const camelizedData: S = isArray
          ? result.map(camelizeObjectKeys)
          : result;

        if (isMountedRef.current) {
          setData(camelizedData);
        }
      } finally {
        if (isMountedRef.current) {
          setLoading(false);
        }
      }
    },
    [isMountedRef]
  );

  return [{ data, loading }, query] as const;
};

const getDaysFromTimeframeObject = ({ start, end }: TimeframeAsObject) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate.getTime() - endDate.getTime() > 0) {
    throw new Error(
      `You provided invalid timeframe values! Start date "${startDate.toISOString()}" can't be greater than end date "${endDate.toISOString()}"!`
    );
  }

  return getDiffInDays(new Date(start), new Date(end));
};

const getDaysFromTimeframeString = (timeframe: TimeframeAsString) => {
  switch (timeframe) {
    case TimeframeAsString.Today: {
      return 1;
    }

    case TimeframeAsString.Yesterday: {
      return 1;
    }

    case TimeframeAsString.LastSevenDays: {
      return 7;
    }

    case TimeframeAsString.LastThirtyDays: {
      return 30;
    }

    case TimeframeAsString.ThisMonth: {
      return new Date().getDate();
    }

    case TimeframeAsString.LastMonth: {
      const today = new Date();
      const lastDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      return lastDayOfPreviousMonth.getDate();
    }

    case TimeframeAsString.ThisYear: {
      const today = new Date();
      const firstDayOfYear = new Date(today.getFullYear(), 0, 0);
      const diffInDays = getDiffInDays(firstDayOfYear, today);

      return diffInDays;
    }

    case TimeframeAsString.LastYear: {
      const today = new Date();
      const isLastYearALeapYear =
        new Date(today.getFullYear() - 1, 1, 29).getDate() === 29;

      return isLastYearALeapYear ? 366 : 365;
    }

    default: {
      throw new Error(`You provided invalid timeframe value "${timeframe}"!`);
    }
  }
};

export const getDaysFromTimeframe = (timeframe: Timeframe) => {
  if (typeof timeframe === 'string') {
    return getDaysFromTimeframeString(timeframe as TimeframeAsString);
  }

  return getDaysFromTimeframeObject(timeframe as TimeframeAsObject);
};

export const isYearTimeframe = (timeframe: Timeframe) => {
  if (typeof timeframe === 'string') {
    return [TimeframeAsString.LastYear, TimeframeAsString.ThisYear].includes(
      timeframe
    );
  }

  return false;
};

export const getKeenRequestType = (timeframe: Timeframe) => {
  if (isYearTimeframe(timeframe)) {
    return PageViewsRequest.Weekly;
  }

  if (
    typeof timeframe === 'string' &&
    [TimeframeAsString.Today, TimeframeAsString.Yesterday].includes(timeframe)
  ) {
    return PageViewsRequest.Hourly;
  }

  return PageViewsRequest.Daily;
};

const createArrayOfLength = (length: number, step: number, max: number) =>
  Array.from(Array(length), (_val, idx) =>
    max / length < step
      ? idx * step
      : idx * Math.ceil(max / length / SECONDS_IN_MINUTE) * SECONDS_IN_MINUTE
  );

export const getAverageDurationTickValues = (values: number[]) => {
  const minNumberOfTickValues = 4;
  const maxNumberOfTickValues = 10;
  const maxAverageDuration = values.length
    ? Math.max(...values.map((value) => value))
    : 0;
  const numberOfTickValues =
    Math.ceil(maxAverageDuration / SECONDS_IN_MINUTE) + 1;

  return numberOfTickValues <= minNumberOfTickValues
    ? createArrayOfLength(5, SECONDS_IN_MINUTE / 2, maxAverageDuration)
    : createArrayOfLength(
        numberOfTickValues < maxNumberOfTickValues
          ? numberOfTickValues
          : maxNumberOfTickValues,
        SECONDS_IN_MINUTE,
        maxAverageDuration
      );
};
