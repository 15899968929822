import { styled } from '@mui/material/styles';
import { BooleanInput } from 'react-admin';

const StyledBooleanInput = styled(BooleanInput)({
  '& .MuiFormHelperText-root': {
    marginBottom: '20px',
  },
});

export default StyledBooleanInput;
