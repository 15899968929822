import { ResourceProps } from 'react-admin';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AppEdit from './AppEdit';
import AppCreate from './AppCreate';
import AppList from './AppList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'apps',
  create: AppCreate,
  edit: AppEdit,
  icon: SportsEsportsIcon,
  list: AppList,
};

export default {
  name: 'apps',
  singularName: 'app',
  payloadKey: 'apps',
  singularPayloadKey: 'app',
  localized: false,
} as DataResourceOptions;
