import { styled } from '@mui/material/styles';
import {
  AxisTick,
  AxisTickProps,
  GridValues,
  TicksSpec,
  ValueFormatter,
} from '@nivo/axes';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { useTranslate } from 'react-admin';

type UsageTotalsChartTooltipData = {
  title: string | number;
  subtitle: string | number;
};

export type UsageTotalsChartData = {
  id: string | number;
  value: string | number;
  tooltip: UsageTotalsChartTooltipData;
};

const TooltipContainer = styled('div')({
  minWidth: '132px',
  backgroundColor: '#ffffff',
  borderRadius: '2px',
  boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.2);',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
});

const TooltipTitle = styled('div')({
  fontSize: '10px',
});

const TooltipSubtitle = styled('div')({
  marginTop: '5px',
  fontSize: '12px',
  fontWeight: 'bold',
});

export const Tooltip = ({ title, subtitle }: UsageTotalsChartTooltipData) => (
  <TooltipContainer>
    <TooltipTitle>{title}</TooltipTitle>
    <TooltipSubtitle>{subtitle}</TooltipSubtitle>
  </TooltipContainer>
);

export interface UsageTotalsKeenChartProps {
  data: UsageTotalsChartData[];
  color?: string;
  formatAxisRight?: string | ValueFormatter<any>;
  tickValuesAxisRight?: TicksSpec<any>;
  legend?: string;
  skipEvenBottomLabels?: boolean;
  gridYValues?: GridValues<string | number>;
}

const UsageTotalsKeenChart = ({
  data = [],
  color = '#ffa191',
  formatAxisRight,
  tickValuesAxisRight,
  legend,
  skipEvenBottomLabels = true,
  gridYValues,
}: UsageTotalsKeenChartProps) => {
  const t = useTranslate();
  const axisBottomLegend = legend || t('charts.usage.legend.days');

  return (
    <ResponsiveBar
      data={(data as unknown) as BarDatum[]}
      indexScale={{ type: 'band', round: false }}
      enableLabel={false}
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: '',
            },
            text: {
              fill: '#6a6a6a',
              opacity: '0.5',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          legend: {
            text: {
              fill: '#6a6a6a',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        grid: {
          line: {
            stroke: '#a2a2a233',
            strokeWidth: 0.5,
          },
        },
      }}
      // I didn't figure out how to pass types to the tooltip in a proper way
      // So this should work for now
      // @ts-ignore
      tooltip={({
        data: {
          tooltip: { title, subtitle },
        },
      }: {
        data: UsageTotalsChartData;
      }) => <Tooltip title={title} subtitle={subtitle} />}
      padding={0.6}
      margin={{ top: 10, right: 35, bottom: 40, left: 8 }}
      colors={[color]}
      gridYValues={gridYValues}
      axisRight={{
        tickPadding: 25,
        tickRotation: 0,
        legend: '',
        legendOffset: 0,
        tickValues: tickValuesAxisRight,
        format: formatAxisRight,
        renderTick: (axisProps: AxisTickProps<any>) => {
          const shouldRenderTickLabel =
            axisProps.tickIndex && !(axisProps.tickIndex % 2);

          return shouldRenderTickLabel ? (
            // @ts-ignore
            <AxisTick
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                ...axisProps,
                textAnchor: 'end',
              }}
            />
          ) : (
            <span />
          );
        },
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 0,
        tickRotation: 0,
        legend: axisBottomLegend,
        legendPosition: 'middle',
        legendOffset: 36,
        renderTick: (axisProps: AxisTickProps<any>) => {
          const shouldRenderTickLabel =
            !skipEvenBottomLabels || !(axisProps.tickIndex % 2);

          return shouldRenderTickLabel ? (
            // @ts-ignore
            <AxisTick
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...axisProps}
            />
          ) : (
            <span />
          );
        },
      }}
    />
  );
};

export default UsageTotalsKeenChart;
