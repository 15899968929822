import { ResourceProps } from 'react-admin';
import LanguageIcon from '@mui/icons-material/Language';
import LocaleEdit from './LocaleEdit';
import LocaleCreate from './LocaleCreate';
import LocaleList from './LocaleList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'locales',
  create: LocaleCreate,
  edit: LocaleEdit,
  icon: LanguageIcon,
  list: LocaleList,
};

export default {
  name: 'locales',
  singularName: 'locale',
  payloadKey: 'locales',
  singularPayloadKey: 'locale',
  localized: false,
} as DataResourceOptions;
