import { Grid } from '@mui/material';
import { useEffect } from 'react';
import {
  BulkActionProps,
  BulkDeleteButton,
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useDataProvider,
  useGetList,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  ContentGroupRecord,
  DeviceRecord,
  HotelRecord,
  ItemRecordLocalized,
} from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import ListPagination from '../ListPagination';
import BulkAssignContentGroupsInput from '../BulkAssignContentGroupsInput';
import ContentGroupEditableField from '../ContentGroupEditableField';
import ExternalLinkField from '../ExternalLinkField';
import ContentGroupListActions from '../ContentGroupListActions';
import { CONTENT_GROUPS_LEADER_IDS } from '../../common/constants';

const {
  REACT_APP_LEGACY_ADMIN_PANEL_HOST: LEGACY_ADMIN_PANEL_HOST,
  REACT_APP_LEGACY_FE_HOST: LEGACY_FE_HOST,
  REACT_APP_TED_HOST: TED_HOST,
} = process.env;

const ITEM_CG_CONTENT_GROUP_FILTER_KEY = 'ITEM_CG_CONTENT_GROUP_FILTER';

const PostBulkActionButtons = (
  props: BulkActionProps & { contentGroups: ContentGroupRecord[] }
) => {
  const { contentGroups, ...rest } = props;
  const { selectedIds } = rest;

  return (
    <Grid
      container
      spacing={2}
      style={{ marginBottom: 0 }}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <BulkAssignContentGroupsInput
          contentGroups={contentGroups}
          selectedIds={selectedIds}
          translationKey="resources.item-cgs"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </Grid>
      <Grid item>
        <BulkDeleteButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

const ItemList = (props: any) => {
  const t = useTranslate();
  const [{ locale }] = useGlobalContext();
  const refresh = useRefresh();
  const {
    data: contentGroupsArray = [],
    isLoading,
  } = useGetList<ContentGroupRecord>('content-groups', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
    filter: {
      id_not_in: CONTENT_GROUPS_LEADER_IDS,
    },
  });

  const dataProvider = useDataProvider();

  useEffect(() => {
    refresh();
  }, [refresh, locale]);

  const getApUrl = (record: ItemRecordLocalized) =>
    `${LEGACY_ADMIN_PANEL_HOST}/#/admin/${record.hotelId}/content/categories/${record.categoryId}/pages/${record.pageIds[0]}/items/edit/${record.id}`;

  const getFeUrl = async (record: ItemRecordLocalized) => {
    // eslint-disable-next-line prefer-const
    let [{ data: hotel }, { data: devices }] = await Promise.all([
      dataProvider.getOne<HotelRecord>('hotels', {
        id: record.hotelId,
      }),
      dataProvider.getList<DeviceRecord>('devices', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          hotel_id_in: record.hotelId,
          uuid_cont: 'SUITEPAD_TESTING',
          uuid_not_cont: 'BYOD',
        },
      }),
    ]);

    if (!devices?.length) {
      const { data: anyDevices } = await dataProvider.getList<DeviceRecord>(
        'devices',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            hotel_id_in: hotel.id,
            uuid_not_cont: 'BYOD',
          },
        }
      );

      devices = anyDevices;
    }

    if (!devices?.length) {
      return '';
    }

    const { uuid } = devices[0];

    if (hotel.frontendVersion === 'v2') {
      const [pageId] = record.pageIds;

      return pageId
        ? `${TED_HOST}/#/${locale}/category/${record.categoryId}/page/${record.pageIds[0]}?uuid=${uuid}`
        : `${TED_HOST}/#/?uuid=${uuid}`;
    }

    return `${LEGACY_FE_HOST}/${hotel.theme}/?${uuid}#`;
  };

  const maybeStoredItemCgContentFilterValue = localStorage.getItem(
    ITEM_CG_CONTENT_GROUP_FILTER_KEY
  );
  const filterDefaultValues = maybeStoredItemCgContentFilterValue
    ? JSON.parse(maybeStoredItemCgContentFilterValue)
    : undefined;

  return (
    <List
      exporter={false}
      actions={
        <ContentGroupListActions
          localStorageKey={ITEM_CG_CONTENT_GROUP_FILTER_KEY}
          contentGroups={contentGroupsArray}
          translationKey="resources.item-cgs"
        />
      }
      filter={{ purchasable: true }}
      filterDefaultValues={filterDefaultValues}
      pagination={<ListPagination />}
      bulkActionButtons={
        <PostBulkActionButtons contentGroups={contentGroupsArray} />
      }
      perPage={50}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField
          label="resources.item-cgs.fields.category"
          source="categoryId"
          reference="categories"
          link={false}
          sortable
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" sortable />
        <ContentGroupEditableField
          label="resources.item-cgs.fields.contentGroup"
          loading={isLoading}
          options={contentGroupsArray}
          resourceName="item-cgs"
          translationKey="resources.item-cgs"
          source="contentGroupId"
          sortable
        />
        <ReferenceField
          label="resources.item-cgs.fields.hotel"
          source="hotelId"
          reference="hotels"
          link={false}
          sortable
        >
          <TextField source="name" />
        </ReferenceField>
        <ExternalLinkField<ItemRecordLocalized>
          getUrl={getApUrl}
          linkText={t('resources.item-cgs.external-link.admin-panel.name')}
        />
        <ExternalLinkField<ItemRecordLocalized>
          getUrl={getFeUrl}
          linkText={t('resources.item-cgs.external-link.front-end.name')}
        />
      </Datagrid>
    </List>
  );
};

export default ItemList;
