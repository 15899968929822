import { styled } from '@mui/material/styles';
import { TabContext } from '@mui/lab';
import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'react-admin';
import { HotelRecord, Timeframe } from '../../../common/types';
import ChartsCircularProgress from '../shared/ChartsCircularProgress';
import {
  useSessionCountHourlyAverageData,
  useSessionCountTotalData,
  useSessionCountWeekdayAverageData,
} from '../data-hooks';
import ChartTab from '../shared/ChartTab';
import ChartTabPanel from '../shared/ChartTabPanel';
import UsageTotalsKeenChart from '../UsageTotalsKeenChart';
import ChartMainTabList from '../shared/ChartMainTabList';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
});

const Chart = styled('div')({
  width: '100%',
});

const UsageTotalsChartSessions = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const t = useTranslate();
  const [currentChartTab, setCurrentChartTab] = useState('1');

  const {
    loading: isSessionCountTotalDataLoading,
    chartProps: sessionCountTotalDataChartProps,
  } = useSessionCountTotalData({ hotel, timeframe });
  const {
    chartProps: sessionCountWeekdayAverageDataChartProps,
    loading: isSessionCountWeekdayAverageDataLoading,
  } = useSessionCountWeekdayAverageData({ hotel, timeframe });
  const {
    chartProps: sessionCountHourlyAverageDataChartProps,
    loading: isSessionCountHourlyAverageDataLoading,
  } = useSessionCountHourlyAverageData({ hotel, timeframe });

  const onCurrentChartTabChange = (
    _event: ChangeEvent<{}>,
    newValue: string
  ) => {
    setCurrentChartTab(newValue);
  };

  return (
    <Container>
      <Chart>
        <TabContext value={currentChartTab}>
          <ChartMainTabList onChange={onCurrentChartTabChange}>
            <ChartTab value="1" label={t('charts.usage.chart-types.totals')} />
            <ChartTab
              value="2"
              label={t('charts.usage.chart-types.weekday-average')}
            />
            <ChartTab
              value="3"
              label={t('charts.usage.chart-types.hourly-average')}
            />
          </ChartMainTabList>
          <ChartTabPanel value="1">
            {isSessionCountTotalDataLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...sessionCountTotalDataChartProps}
              />
            )}
          </ChartTabPanel>
          <ChartTabPanel value="2">
            {isSessionCountWeekdayAverageDataLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...sessionCountWeekdayAverageDataChartProps}
              />
            )}
          </ChartTabPanel>
          <ChartTabPanel value="3">
            {isSessionCountHourlyAverageDataLoading ? (
              <ChartsCircularProgress />
            ) : (
              <UsageTotalsKeenChart
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...sessionCountHourlyAverageDataChartProps}
              />
            )}
          </ChartTabPanel>
        </TabContext>
      </Chart>
    </Container>
  );
};

export default UsageTotalsChartSessions;
