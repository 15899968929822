import { Tooltip, TooltipProps } from '@mui/material';
import { ReactElement } from 'react';

const ChartTooltip = ({
  enterDelay = 1000,
  enterNextDelay = 1000,
  placement = 'top',
  children,
  ...rest
}: TooltipProps & { children: ReactElement }) => (
  <Tooltip
    enterDelay={enterDelay}
    enterNextDelay={enterNextDelay}
    placement={placement}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </Tooltip>
);

export default ChartTooltip;
