import { FieldProps, RaRecord, useNotify, useRecordContext } from 'react-admin';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, CircularProgress } from '@mui/material';
import { MouseEvent, useState } from 'react';

const ExternalLinkField = <T extends RaRecord>(
  props: FieldProps & {
    getUrl: (record: T) => string | Promise<string>;
    linkText: string;
  }
) => {
  const { getUrl, linkText } = props;
  const record = useRecordContext<T>();

  const notify = useNotify();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState<string | undefined>();

  const openInNewTab = (urlToOpen: string) =>
    window.open(urlToOpen, '_blank')?.focus();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const generatedUrl = await getUrl(record);

      setUrl(generatedUrl);

      openInNewTab(generatedUrl);
    } catch (_error: any) {
      notify(`Failed to generate FE link for ${record.title}`, {
        type: 'warning',
      });
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  };

  const handleClick = (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.preventDefault();

    if (!url && !isLoaded) {
      fetchData();
    } else if (url) {
      openInNewTab(url);
    }
  };

  return (
    <Button
      size="small"
      color="primary"
      className="text-list-button"
      onClick={handleClick}
      href={url}
      startIcon={
        !isLoaded && isLoading ? <CircularProgress size={18} /> : <LaunchIcon />
      }
    >
      {linkText}
    </Button>
  );
};

export default ExternalLinkField;
