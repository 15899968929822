import { ResourceProps } from 'react-admin';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HotelEdit from './HotelEdit';
import HotelCreate from './HotelCreate';
import HotelList from './HotelList';
import { DataResourceOptions } from '../../common/types';

export const resourceProps: ResourceProps = {
  name: 'hotels',
  create: HotelCreate,
  edit: HotelEdit,
  icon: ApartmentIcon,
  list: HotelList,
};

export default {
  name: 'hotels',
  singularName: 'hotel',
  payloadKey: 'hotels',
  singularPayloadKey: 'hotel',
  localized: false,
} as DataResourceOptions;
