import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartTab = styled(Tab)({
  '&.MuiTab-root': {
    fontSize: '12px',
    fontWeight: 600,
    minWidth: 0,
    minHeight: 0,
    color: '#787582',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '3px 10px',
    margin: '23px 4px',
    border: '1px solid #f2f2f5',
    alignItems: 'flex-start',

    '&:focus::after': {
      opacity: 1,
      background: 'none',
    },
  },
  '&.Mui-selected': {
    color: '#3d3a46',
    backgroundColor: '#ebeaef',
    border: '1px solid transparent',
  },
});

export default ChartTab;
