import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { regex, required, TextInput, useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

const ItemLinkInput = () => {
  const t = useTranslate();
  const linkUrl = useWatch({ name: 'linkUrl' });
  const linkText = useWatch({ name: 'linkText' });
  const { setValue, getValues } = useFormContext();

  const [showLinkInputs, setShowLinkInputs] = useState(false);

  // This is needed to show the inputs if linkUrl and linkText are
  // present on existing record.
  // The default value for use state doesn't work because form values are empty
  // on initial render and `showLinkInputs` always initialized with `false` value
  useEffect(() => {
    if (linkUrl && linkText) {
      setShowLinkInputs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = () => {
    setValue('linkUrl', null);
    setValue('linkText', null);
    setShowLinkInputs(!showLinkInputs);
  };

  return (
    <Box display="block" width="100%" mt="1em">
      {showLinkInputs ? (
        <Box
          sx={{
            width: '100%',
            display: { md: 'grid' },
            gridTemplateColumns: 'repeat(2, 1fr) 200px',
            columnGap: '2em',
            alignItems: 'center',
          }}
        >
          <TextInput
            source="linkUrl"
            label="resources.items.fields.linkUrl"
            placeholder="http://www.spiegel.de"
            validate={regex(
              /((http|https|ted):\/\/)|((mailto|tel):)/,
              t('ra.message.invalid_url')
            )}
            fullWidth
          />
          <TextInput
            source="linkText"
            label="resources.items.fields.linkText"
            placeholder="Spiegel Online"
            validate={getValues('linkUrl') ? required() : undefined}
            fullWidth
          />
          <Box>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              startIcon={<CloseIcon />}
              onClick={handleOnChange}
              sx={{ padding: '6px 20px', marginBottom: '10px' }}
            >
              {t('resources.items.fields.removeLink')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LinkIcon />}
          onClick={handleOnChange}
          sx={{ padding: '6px 20px', marginTop: '1.8em' }}
        >
          {t('resources.items.fields.linkToExternal')}
        </Button>
      )}
    </Box>
  );
};

export default ItemLinkInput;
