import {
  Button,
  FormDataConsumer,
  Loading,
  useRecordContext,
  useGetOne,
  NumberInput,
  required,
} from 'react-admin';
import { styled } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import { HotelRecord, OrderRecord } from '../../../common/types';

import GuestInfo from './GuestInfo';
import CheckoutConfirmationMessage from './CheckoutConfirmationMessage';
import BillsUploader from './BillsUploader';

const PREFIX = 'InitialState';

const classes = {
  checkoutFontSize: `${PREFIX}-checkoutFontSize`,
  paymentCheck: `${PREFIX}-paymentCheck`,
  paymentCheckButton: `${PREFIX}-paymentCheckButton`,
  currencyIcon: `${PREFIX}-currencyIcon`,
  paymentAmountHelpBlock: `${PREFIX}-paymentAmountHelpBlock`,
};

const Root = styled('div')({
  [`& .${classes.checkoutFontSize}`]: {
    '& .MuiInputBase-root': {
      fontSize: '14px',
    },
  },
  [`& .${classes.paymentCheck}`]: {
    marginTop: '100px',
    marginBottom: '150px',
  },
  [`& .${classes.paymentCheckButton}`]: {
    minWidth: '300px',
    margin: '20px',
    padding: '10px',
    display: 'block',
    color: '#fff',
    backgroundColor: '#555',
    '&:hover': {
      backgroundColor: '#222',
    },
  },
  [`& .${classes.currencyIcon}`]: {
    paddingRight: '15px',
    color: '#737373',
  },
  [`& .${classes.paymentAmountHelpBlock}`]: {
    marginLeft: '25px',
    marginRight: '15px',
    color: '#737373',
    fontSize: '13px',
    minWidth: '100px',
  },
});

const CurrencyIcon = () => {
  const record: HotelRecord = useRecordContext();
  const { isLoading, data } = useGetOne('currencies', {
    id: record.currencyId,
  });

  if (isLoading) {
    return <Loading />;
  }
  return <span className={classes.currencyIcon}>{data.code}</span>;
};

const FormattedAmount = (props: any) => {
  const record: HotelRecord = useRecordContext();
  const { isLoading, data } = useGetOne('currencies', {
    id: record.currencyId,
  });

  const { value } = props;

  if (isLoading) {
    return <Loading />;
  }
  return (
    <span className={classes.paymentAmountHelpBlock}>
      {value
        ? new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: data.code,
          }).format(value)
        : '-'}
    </span>
  );
};

const InitialState = (props: any) => {
  const record: OrderRecord = useRecordContext(props);
  const {
    cancelCheckoutProcess,
    handleConfirmCheckoutSubmit,
    handleSendPaymentEmailSubmit,
    openPayments,
    paymentsCheck,
    setOpenPayments,
    setPaymentsCheck,
  } = props;

  const confirmNoOpenPayments = () => {
    setPaymentsCheck(false);
    setOpenPayments(false);
  };
  const confirmOpenPayments = () => {
    setPaymentsCheck(false);
    setOpenPayments(true);
  };

  const comment = record?.comment || '';
  const regex = /(?:### Guest Name\n\n)(?<guestName>.*)(?:\n\n\n### Split Bill\n\n)(?<splitBill>.*)(?:\n\n\n### Minibar\n\n)(?<minibar>.*)/gi;
  const matches = comment.matchAll(regex);
  const match = Array.from(matches)[0];
  const { guestName = '', splitBill = '', minibar = '' } = match?.groups || {};

  return (
    <Root>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            <GuestInfo
              name="resources.orders.checkout.guestName"
              value={guestName}
            />
            <GuestInfo
              name="resources.orders.checkout.emailAddress"
              value={record.confirmationRecipient}
            />
            <GuestInfo
              name="resources.orders.checkout.billSplittingMethod"
              value={splitBill}
            />
            <GuestInfo
              name="resources.orders.checkout.minibarItems"
              value={minibar}
            />
          </Grid>
          <Grid item xs={7} container direction="column">
            {paymentsCheck ? (
              <Grid item xs>
                <div className={classes.paymentCheck}>
                  <Button
                    onClick={confirmNoOpenPayments}
                    color="inherit"
                    size="medium"
                    variant="contained"
                    label="resources.orders.checkout.paymentsSettled"
                    className={classes.paymentCheckButton}
                  />
                  <Button
                    onClick={confirmOpenPayments}
                    color="inherit"
                    size="medium"
                    variant="contained"
                    label="resources.orders.checkout.openPayments"
                    className={classes.paymentCheckButton}
                  />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs>
                  <CheckoutConfirmationMessage
                    source="confirmationMessage"
                    openPayments={openPayments}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  {openPayments && (
                    <>
                      <Grid
                        item
                        xs
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                      >
                        <CurrencyIcon />
                        <NumberInput
                          source="paymentAmount1"
                          label="resources.orders.checkout.paymentAmount.firstAmount"
                          validate={required()}
                          variant="outlined"
                          min={0}
                          className={classes.checkoutFontSize}
                        />
                        <FormDataConsumer
                          className={classes.paymentAmountHelpBlock}
                        >
                          {({ formData }) => (
                            <FormattedAmount value={formData.paymentAmount1} />
                          )}
                        </FormDataConsumer>
                      </Grid>
                      <Grid
                        item
                        xs
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                      >
                        <CurrencyIcon />
                        <NumberInput
                          source="paymentAmount2"
                          label="resources.orders.checkout.paymentAmount.secondAmount"
                          variant="outlined"
                          min={0}
                          className={classes.checkoutFontSize}
                        />
                        <FormDataConsumer>
                          {({ formData }) => (
                            <FormattedAmount value={formData.paymentAmount2} />
                          )}
                        </FormDataConsumer>
                      </Grid>
                    </>
                  )}
                  <Grid item xs>
                    <BillsUploader />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancelCheckoutProcess}
          color="inherit"
          size="medium"
          variant="contained"
          label="ra.action.cancel"
        >
          <ClearIcon />
        </Button>
        {!paymentsCheck && (
          <FormDataConsumer>
            {({ formData }: any) =>
              openPayments ? (
                <Button
                  onClick={(e) => {
                    // This is a temporary working solution in order to validate
                    // the payment amount against false values. Unfortunately,
                    // something is missing here and 0 (also false) needs to be
                    // handled separately to avoid additional errors.
                    // We will address this issue at a later point in time when we
                    // investigate the react-admin forms more deeply.
                    if (
                      formData.paymentAmount1 ||
                      formData.paymentAmount1 === 0
                    ) {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSendPaymentEmailSubmit(formData);
                    }
                  }}
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  label="resources.orders.checkout.sendPaymentEmail"
                />
              ) : (
                <Button
                  onClick={() => handleConfirmCheckoutSubmit(formData)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  label="resources.orders.checkout.confirmCheckout"
                />
              )
            }
          </FormDataConsumer>
        )}
      </DialogActions>
    </Root>
  );
};
export default InitialState;
