import { NumberInput, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { WorkflowCode } from '../../common/enums';
import FulfillmentTimeSelector from './FulfillmentTimeSelector';

const ItemWorkflowOptionsInput = (props: any) => {
  const { isShopPage } = props;
  const { getValues } = useFormContext();
  const t = useTranslate();

  const isRequestItem = getValues('workflow') === WorkflowCode.RequestItem;
  const isReservationItem =
    getValues('workflow') === WorkflowCode.ReservationItem;

  return (
    <Box width="100%" mt="2em" mb="2em">
      {isShopPage && (
        <NumberInput
          source="workflowOptions.maxQuantity"
          label={t('resources.items.workflow-options.maxQuantity')}
          step={1}
          min={1}
          fullWidth
          onKeyDown={(e) => ['.', ','].includes(e.key) && e.preventDefault()}
          parse={(v) => v || ''}
        />
      )}
      {!isShopPage && isRequestItem && <FulfillmentTimeSelector />}
      {!isShopPage && isReservationItem && (
        <>
          <FulfillmentTimeSelector />
          <Box
            sx={{
              width: '100%',
              display: { md: 'grid' },
              gridTemplateColumns: 'repeat(2, 1fr)',
              columnGap: '5em',
            }}
            mt="1em"
          >
            <NumberInput
              source="workflowOptions.maxPeopleCount"
              label={t('resources.items.workflow-options.maxPeopleCount')}
              placeholder={t(
                'resources.items.workflow-options.placeholders.maxPeopleCount'
              )}
              step={1}
              min={1}
              onKeyDown={(e) =>
                ['.', ','].includes(e.key) && e.preventDefault()
              }
              parse={(v) => v || ''}
              fullWidth
            />
            <NumberInput
              source="workflowOptions.minPeopleCount"
              label={t('resources.items.workflow-options.minPeopleCount')}
              placeholder={t(
                'resources.items.workflow-options.placeholders.minPeopleCount'
              )}
              step={1}
              min={1}
              onKeyDown={(e) =>
                ['.', ','].includes(e.key) && e.preventDefault()
              }
              parse={(v) => v || ''}
              fullWidth
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ItemWorkflowOptionsInput;
