import {
  Edit,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { LanguageDirection } from '../../common/enums';
import { LocaleRecord } from '../../common/types';
import SimpleFormWithServerSideFieldValidation from '../SimpleFormWithServerSideFieldValidation';
import EditViewToolbar from '../EditViewToolbar';

const directionChoices = [
  { id: LanguageDirection.LTR, name: 'Left-to-Right' },
  { id: LanguageDirection.RTL, name: 'Right-to-Left' },
];

// eslint-disable-next-line react/require-default-props
const LocaleTitle = () => {
  const record: LocaleRecord = useRecordContext();
  return <span>Language {record ? `"${record.name}"` : ''}</span>;
};

const LocaleEdit = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Edit title={<LocaleTitle />} {...props}>
    <SimpleFormWithServerSideFieldValidation
      toolbar={<EditViewToolbar />}
      type="edit"
    >
      <TextInput source="name" variant="outlined" validate={required()} />
      <TextInput source="key" variant="outlined" validate={required()} />
      <SelectInput
        source="direction"
        choices={directionChoices}
        defaultValue={LanguageDirection.LTR}
        variant="outlined"
        validate={required()}
      />
    </SimpleFormWithServerSideFieldValidation>
  </Edit>
);

export default LocaleEdit;
