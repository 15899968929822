import { HotelRecord } from '../common/types';

export type GlobalState = {
  hotel: HotelRecord | null;
  locale: string | null;
};

export enum GlobalActionType {
  SetHotel = 'SET_HOTEL',
  SetLocale = 'SET_LOCALE',
}

export type GlobalAction = {
  type: GlobalActionType;
  payload: any;
};

const Reducer = (state: GlobalState, action: GlobalAction): GlobalState => {
  const { type, payload } = action;

  switch (type) {
    case GlobalActionType.SetHotel:
      return {
        ...state,
        hotel: payload,
      };

    case GlobalActionType.SetLocale:
      return {
        ...state,
        locale: payload,
      };

    default:
      return state;
  }
};

export default Reducer;
