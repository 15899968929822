import {
  Edit,
  required,
  SelectInput,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  FormTab,
  EditProps,
  useRecordContext,
} from 'react-admin';
import { HotelRecord } from '../../common/types';
import { AVAILABLE_TIMEZONES } from '../../common/constants';
import EditViewToolbar from './HotelEditViewToolbar';
import MonacoEditorInput from '../MonacoEditorInput';
import TabbedFormWithServerSideValidation from '../TabbedFormWithServerSideValidation';

// eslint-disable-next-line react/require-default-props
const HotelTitle = () => {
  const record: HotelRecord = useRecordContext();
  return <span>Hotel {record ? `"${record.name}"` : ''}</span>;
};

const availableTimezones = AVAILABLE_TIMEZONES.map((timezone) => ({
  id: timezone.key,
  name: timezone.name,
}));

const HotelEdit = (props: EditProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Edit title={<HotelTitle />} {...props}>
    <TabbedFormWithServerSideValidation
      type="edit"
      toolbar={<EditViewToolbar />}
    >
      <FormTab label="general">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.type === 'HotelMixin' && (
                <BooleanInput
                  source="type"
                  label="resources.hotels.fields.sharedInstallation"
                  defaultValue={false}
                  format={(value) => value === 'HotelMixin'}
                  disabled
                />
              )}
              <TextInput
                source="name"
                variant="outlined"
                validate={required()}
              />
              <TextInput
                source="code"
                variant="outlined"
                validate={required()}
              />
              {formData.type !== 'HotelMixin' && (
                <>
                  <TextInput
                    source="theme"
                    variant="outlined"
                    validate={required()}
                  />
                  <TextInput source="pin" variant="outlined" />
                  <SelectInput
                    source="timezone"
                    choices={availableTimezones}
                    variant="outlined"
                    validate={required()}
                  />
                </>
              )}
            </>
          )}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="miscellaneous" path="miscellaneous">
        <MonacoEditorInput source="options.tedInitScript" />
      </FormTab>
    </TabbedFormWithServerSideValidation>
  </Edit>
);

export default HotelEdit;
