import { Notification as RaNotification, NotificationProps } from 'react-admin';
import isInIframe from './utils/is-in-iframe';

const Notification = (props: NotificationProps) =>
  isInIframe() ? (
    <RaNotification
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    />
  ) : (
    <RaNotification
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
export default Notification;
