import { Autocomplete, Box, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, useState } from 'react';
import { Form, useListContext, useTranslate } from 'react-admin';
import { ContentGroupRecord } from '../common/types';

const StyledAutocomplete = styled(Autocomplete)({
  '&.MuiAutocomplete-popper': { width: 'fit-content !important' },
}) as typeof Autocomplete;

const getDefaultContentGroupFilterValue = (
  filterObject: {
    [key: string]: any;
  },
  contentGroups: ContentGroupRecord[],
  contentGroupFilterQuery: string,
  contentGroupNullishFilterQuery: string
) => {
  if (contentGroupFilterQuery in filterObject) {
    return contentGroups.find(
      ({ id }) => id === filterObject[contentGroupFilterQuery]
    );
  }

  if (contentGroupNullishFilterQuery in filterObject) {
    return contentGroups.find(({ id }) => id === null);
  }

  return null;
};

const ContentGroupListActions = ({
  contentGroups,
  localStorageKey,
  translationKey = 'resources.page-cgs',
  searchFilterQuery = 'title_cont',
  contentGroupFilterQuery = 'content_group_id_eq',
  contentGroupNullishFilterQuery = 'content_group_id_null',
  ...props
}: any & {
  contentGroups: ContentGroupRecord[];
  localStorageKey: string;
  translationKey?: string;
  searchFilterQuery?: string;
  contentGroupFilterQuery?: string;
  contentGroupNullishFilterQuery?: string;
}) => {
  const t = useTranslate();

  const {
    displayedFilters,
    filterValues,
    setFilters,
    isLoading,
  } = useListContext(props);

  const noContentGroupOption = ({
    id: null,
    name: 'No content group',
  } as unknown) as ContentGroupRecord;

  const options = [noContentGroupOption, ...contentGroups];

  const [defaultContentGroupFilterValue] = useState(
    getDefaultContentGroupFilterValue(
      filterValues,
      options,
      contentGroupFilterQuery,
      contentGroupNullishFilterQuery
    )
  );
  const [defaultSearchFilterValue] = useState(
    filterValues?.[searchFilterQuery] || ''
  );

  const storeAndSetFilters = (filters: any) => {
    localStorage.setItem(localStorageKey, JSON.stringify(filters));

    return setFilters(filters, displayedFilters, true);
  };

  const handleSearchFilterChange = (event: ChangeEvent<{ value: string }>) => {
    const searchFilters = event?.target?.value
      ? {
          id_eq: event.target.value,
          m: 'or',
          [searchFilterQuery]: event.target.value,
        }
      : {
          id_eq: null,
          m: null,
          [searchFilterQuery]: null,
        };

    storeAndSetFilters({
      ...filterValues,
      ...searchFilters,
    });
  };

  const handleContentGroupFilterChange = (
    _event: ChangeEvent<{}>,
    selectedContentGroup: ContentGroupRecord | null
  ) => {
    if (selectedContentGroup && selectedContentGroup !== noContentGroupOption) {
      const updatedFilterValues = {
        ...filterValues,
        [contentGroupFilterQuery]: selectedContentGroup.id,
      };
      delete updatedFilterValues[contentGroupNullishFilterQuery];

      return storeAndSetFilters(updatedFilterValues);
    }

    if (selectedContentGroup && selectedContentGroup === noContentGroupOption) {
      const updatedFilterValues = {
        ...filterValues,
        [contentGroupNullishFilterQuery]: true,
      };
      delete updatedFilterValues[contentGroupFilterQuery];

      return storeAndSetFilters(updatedFilterValues);
    }

    const updatedFilterValues = {
      ...filterValues,
    };
    delete updatedFilterValues[contentGroupNullishFilterQuery];
    delete updatedFilterValues[contentGroupFilterQuery];

    return storeAndSetFilters(updatedFilterValues);
  };

  return (
    <Box width="100%">
      <Form onSubmit={() => {}}>
        <Box display="flex" alignItems="flex-start" mb={1}>
          <Box mr={2}>
            <TextField
              label={t('settings.search')}
              type="search"
              defaultValue={defaultSearchFilterValue}
              onChange={handleSearchFilterChange}
            />
          </Box>
          <Box mr={2}>
            <StyledAutocomplete
              id="content-group-filter-autocomplete"
              defaultValue={defaultContentGroupFilterValue}
              style={{ minWidth: 200 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              options={options}
              loading={isLoading}
              onChange={handleContentGroupFilterChange}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  label={t(`${translationKey}.fields.contentGroup`)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default ContentGroupListActions;
