import { Pagination, PaginationProps } from 'react-admin';

const ListPagination = (props: PaginationProps) => (
  <Pagination
    rowsPerPageOptions={[50, 10, 25, 100]}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default ListPagination;
