import { Box, Link, Typography } from '@mui/material';
import { BooleanInput, usePermissions, useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Role } from '../../../common/enums';
import { IntegrationRecord } from '../../../common/types';

const IntegrationInfo = ({
  integration,
}: {
  integration: IntegrationRecord;
}) => {
  const t = useTranslate();
  const isEnabled = useWatch({ name: 'enabled' });
  const { permissions = [], isLoading } = usePermissions();
  const isAdmin = !isLoading && permissions.includes(Role.Admin);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        mb={
          // Since BooleanInput uses FormHelperText and it's impossible
          // to disable it we need to add a margin only if we're not showing
          // the enabled/disabled switch
          isAdmin ? 2 : 4
        }
      >
        <Box
          component="img"
          src={integration.logo}
          alt={integration.systemName}
          sx={{
            maxWidth: '200px',
            maxHeight: '100px',
            objectFit: 'contain',
            objectPosition: 'left',
            marginBottom: '20px',
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box>
            {t('resources.integrations-configurations.fields.logo-title', {
              systemName: (
                <b style={{ fontWeight: 500, fontSize: '22px' }}>
                  {integration.systemName}
                </b>
              ),
              vendor: (
                <Link
                  href={integration.website}
                  color="inherit"
                  target="_blank"
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {integration.vendor}
                </Link>
              ),
            })}
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
              {t(
                `resources.integrations-configurations.fields.integration-type.${integration.integrationType}`
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isAdmin && (
        <Box>
          <BooleanInput
            source="enabled"
            sx={{
              color: isEnabled ? '#00a152' : '#ff1744',
              '& .Mui-checked': {
                color: '#ffffff !important',
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#ff1744',
              },
              '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#00a152 !important',
              },
            }}
            label={t(
              `resources.integrations-configurations.enabled-field.${isEnabled}`
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default IntegrationInfo;
