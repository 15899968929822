import { Box } from '@mui/material';
import { InputProps, required, TextInput, WrapperField } from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const HotsoftInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <TextInput
        source="currentConfig.service.providerKey"
        label="resources.integrations.fields.hotsoft.providerKey.label"
        helperText="resources.integrations.fields.hotsoft.providerKey.helperText"
        validate={required(
          'resources.integrations.fields.hotsoft.providerKey.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.hotelCode"
        label="resources.integrations.fields.hotsoft.hotelCode.label"
        helperText="resources.integrations.fields.hotsoft.hotelCode.helperText"
        validate={required(
          'resources.integrations.fields.hotsoft.hotelCode.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.endpoint"
        label="resources.integrations.fields.hotsoft.endpoint.label"
        helperText="resources.integrations.fields.hotsoft.endpoint.helperText"
        type="url"
        validate={required(
          'resources.integrations.fields.hotsoft.endpoint.helperText'
        )}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default HotsoftInputs;
