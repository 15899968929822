import {
  Block,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
} from '@mui/icons-material';
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  Loading,
  ReferenceField,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import { Role } from '../../common/enums';
import { DeviceRecord } from '../../common/types';
import { useGlobalContext } from '../../context/globalContext';
import ReleaseChannelChip from '../ReleaseChannelChip';
import RelativeDateTimeField from '../fields/RelativeDateTimeField';
import ListPagination from '../ListPagination';
import ListViewTopToolbar from '../ListViewTopToolbar';
import DeviceListRowMenuButton from './DeviceListRowMenuButton';

const DeviceFilter = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput
      label="settings.search"
      source="uuid_cont"
      alwaysOn
      translate="yes"
    />
  </Filter>
);

const WifiLevel = ({ level }: { level: string | null }) => {
  const isLevelProvided = level !== null;

  return (
    <span
      style={{
        display: 'flex',
      }}
    >
      {isLevelProvided ? (
        `${Math.round(Number(level))}%`
      ) : (
        <Block fontSize="small" />
      )}
    </span>
  );
};

const BatteryTrendIcon = ({ trend }: { trend: string }) => {
  const isBatteryLevelTrendProvided = Number.isInteger(parseInt(trend, 10));

  if (!isBatteryLevelTrendProvided) {
    return <Block fontSize="small" />;
  }

  if (+trend === 0) {
    return <TrendingFlat fontSize="small" />;
  }

  return +trend < 0 ? (
    <TrendingDown fontSize="small" />
  ) : (
    <TrendingUp fontSize="small" />
  );
};

const BatteryTrend = ({
  level,
  trend,
}: {
  level: string | null;
  trend: string;
}) => {
  const textContent =
    level === null ? '' : `${Math.round(Number(level) * 100)}%`;

  return (
    <span style={{ display: 'flex' }}>
      {textContent}
      <BatteryTrendIcon trend={trend} />
    </span>
  );
};

const DeviceListRowControls = ({ isAdmin, ...props }: any) => (
  <>
    <EditButton
      className="text-list-button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="small"
    />
    {isAdmin && (
      <DeleteButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        size="small"
      />
    )}
    <DeviceListRowMenuButton isAdmin={isAdmin} />
  </>
);

const DeviceList = (props: any) => {
  const [{ hotel }] = useGlobalContext();
  const { permissions = [], isLoading } = usePermissions();
  const isAdmin = !isLoading && permissions.includes(Role.Admin);

  return hotel ? (
    <List
      exporter={false}
      filter={{ hotel_id_in: hotel.id }}
      filters={<DeviceFilter />}
      pagination={<ListPagination />}
      perPage={50}
      actions={<ListViewTopToolbar />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="uuid" />
        <BooleanField source="active" />
        {isAdmin && <ReleaseChannelChip source="channelApk" />}
        {isAdmin && <BooleanField source="demoDevice" />}
        <FunctionField<DeviceRecord>
          source="manifestVersion"
          sortBy="manifestVersion"
          render={(record?) => (
            <BooleanField
              source="isUpToDate"
              record={{
                ...record!,
                isUpToDate: hotel.manifestVersion === record!.manifestVersion,
              }}
            />
          )}
        />
        <ReferenceField
          label="resources.rooms.name"
          source="roomId"
          reference="rooms"
          link={false}
          sortable={false}
        >
          <TextField source="code" />
        </ReferenceField>
        <RelativeDateTimeField source="lastPingedAt" />
        <TextField source="appVersionName" />
        <TextField source="wifiSsid" />
        <FunctionField<DeviceRecord>
          source="wifiLevel"
          sortBy="wifiLevel"
          render={(record?) => record && <WifiLevel level={record.wifiLevel} />}
        />
        <FunctionField<DeviceRecord>
          source="batteryLevel"
          sortBy="batteryLevel"
          render={(record?) =>
            record && (
              <BatteryTrend
                trend={record.batteryLevelTrend}
                level={record.batteryLevel}
              />
            )
          }
        />
        {isAdmin && <TextField source="amqpConnections" />}
        <DeviceListRowControls isAdmin={isAdmin} />
      </Datagrid>
    </List>
  ) : (
    <Loading />
  );
};

export default DeviceList;
