import { Box, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import {
  Layout as RaLayout,
  AppBar as RaAppBar,
  useLocaleState as useLanguage,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import isInIframe from '../utils/is-in-iframe';
import LanguageMenuButton from './LanguageMenuButton';
import { Language } from '../common/enums';
import SelectHotelInput from '../resources/SelectHotelInput';

const EmptyElement = () => <></>;

const AppBar = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [language] = useLanguage();

  const handleChange = (event: ChangeEvent) => {
    searchParams.set('lang', (event.target as HTMLInputElement).lang);
    setSearchParams(searchParams);
  };

  return (
    <RaAppBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title" />
      </Box>
      {process.env.NODE_ENV === 'development' && <SelectHotelInput />}
      <LanguageMenuButton
        selectedLanguage={language as Language}
        onChange={handleChange}
      />
    </RaAppBar>
  );
};

const Layout = (props: any) =>
  isInIframe() ? (
    <RaLayout
      sx={{
        minHeight: '500px',
        '.RaLayout-appFrame': {
          marginTop: 0,
        },
      }}
      className="admin-layout admin-layout--without-layout"
      appBar={EmptyElement}
      sidebar={EmptyElement}
      menu={EmptyElement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : (
    <RaLayout
      appBar={AppBar}
      className="admin-layout"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );

export default Layout;
