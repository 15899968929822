import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const {
  REACT_APP_SOCKJS_URL: SOCKJS_URL,
  REACT_APP_SOCKJS_USER: SOCKJS_USER,
  REACT_APP_SOCKJS_PASSWORD: SOCKJS_PASSWORD,
  REACT_APP_SOCKJS_VHOST: SOCKJS_VHOST,
} = process.env;

// eslint-disable-next-line import/prefer-default-export
export const initWebSocketClient = () => {
  const client = new Client();

  client.webSocketFactory = () => new SockJS(SOCKJS_URL);
  client.connectHeaders = {
    login: `${SOCKJS_USER}`,
    passcode: `${SOCKJS_PASSWORD}`,
    host: `${SOCKJS_VHOST}`,
  };
  client.heartbeatIncoming = 20000;

  return client;
};
