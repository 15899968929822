import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { INTL_COUNT, SECONDS_IN_MINUTE } from '../../../../common/constants';
import { HotelRecord, Timeframe } from '../../../../common/types';
import { getMinutesAndSecondsFromSeconds } from '../../../../utils/intl/relative-date-time';
import {
  getAverageDurationTickValues,
  useKeenQuery,
} from '../../../../utils/keen';
import { UsageTotalsKeenChartProps } from '../../UsageTotalsKeenChart';

const useAverageDurationWeekdayAverageData = ({
  hotel,
  timeframe,
}: {
  hotel: HotelRecord;
  timeframe: Timeframe;
}) => {
  const t = useTranslate();

  const [
    {
      data: averageDurationWeekdayAverage = [],
      loading: isAverageDurationWeekdayAverageLoading,
    },
    queryAverageDurationWeekdayAverage,
  ] = useKeenQuery<{ dateDayOfWeek: number; result: number }[]>();

  useEffect(() => {
    queryAverageDurationWeekdayAverage({
      analysisType: 'average',
      eventCollection: 'sessions',
      targetProperty: 'duration',
      groupBy: 'date.day_of_week',
      filters: [
        {
          propertyName: 'hotel_uuid',
          operator: 'eq',
          propertyValue: hotel.uuid,
        },
        {
          propertyName: 'duration',
          operator: 'lte',
          propertyValue: 3600,
        },
      ],
      timeframe,
    });
  }, [hotel.uuid, queryAverageDurationWeekdayAverage, timeframe]);

  const dayToDataMap = averageDurationWeekdayAverage.reduce(
    (
      acc: { [key: number]: { dateDayOfWeek: number; result: number } },
      val: { dateDayOfWeek: number; result: number }
    ) => {
      acc[val.dateDayOfWeek] = val;
      return acc;
    },
    {}
  );
  const zeroFilledAverageDurationWeekday = [...Array(7)].map(
    (_, index) => dayToDataMap[index] || { dateDayOfWeek: index, result: 0 }
  );

  const averageDurationWeekdayAverageData = zeroFilledAverageDurationWeekday.map(
    ({ dateDayOfWeek, result }) => {
      const [min, sec] = getMinutesAndSecondsFromSeconds(result);

      return {
        id: t(`settings.day-of-week.${dateDayOfWeek}`, {
          count: INTL_COUNT.OTHER,
        }),
        value: result,
        tooltip: {
          title: t(`settings.day-of-week.${dateDayOfWeek}`, {
            count: INTL_COUNT.OTHER,
          }),
          subtitle: `${min} min ${sec} sec`,
        },
      };
    }
  );

  const tickValues = getAverageDurationTickValues(
    averageDurationWeekdayAverageData.map(({ value }) => value)
  );

  const formatAxisRight = (value: number) => `${value / SECONDS_IN_MINUTE} min`;

  const chartProps: UsageTotalsKeenChartProps = {
    data: averageDurationWeekdayAverageData,
    tickValuesAxisRight: tickValues,
    gridYValues: tickValues,
    formatAxisRight,
    skipEvenBottomLabels: false,
  };

  return {
    chartProps,
    loading: isAverageDurationWeekdayAverageLoading,
  };
};

export default useAverageDurationWeekdayAverageData;
