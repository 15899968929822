export enum Language {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
}

export enum LanguageDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum DeviceType {
  Tablet = 'tablet',
  LobbyScreen = 'lobbyscreen',
  SuiteCast = 'suitecast',
  PremiumDock = 'premium_dock',
  STVB = 'stvb',
  TestLabTablet = 'test_lab_tablet',
  Projector = 'projector',
}

export enum Role {
  Admin = 'admin',
  Guest = 'guest_data',
}

export enum WorkflowCode {
  ExpressCheckout = 'expressCheckout',
  Email = 'email',
  ByodSplashScreen = 'byodSplashScreen',
  SpFeedback = 'spFeedback',
  RoomBill = 'roomBill',
  ByodShopStandard = 'byodShopStandard',
  AdminNotWorkingOnSgt47 = 'admin_not_working_on_sgt47',
  ExternalURL = 'externalURL',
  QuickFeedback = 'quickFeedback',
  InstantCall = 'instantCall',
  CustomCall = 'customCall',
  ReservationItem = 'reservationItem',
  RequestItem = 'requestItem',
  GreenOption = 'greenOption',
  ReservationItemNoConfirmation = 'reservation-item-no-confirmation',
  InformationItem = 'informationItem',
  GreenOptionV2 = 'green-option-v2',
  BreakfastUpsell = 'breakfast-upsell',
}

export enum AppVersionChannel {
  Production = 'production',
  ReleaseCandidate = 'release_candidates',
  Beta = 'beta',
}

export enum TimeframeAsString {
  Today = 'today',
  Yesterday = 'yesterday',
  LastSevenDays = 'previous_7_days',
  LastThirtyDays = 'previous_30_days',
  ThisMonth = 'this_month',
  LastMonth = 'previous_month',
  ThisYear = 'this_year',
  LastYear = 'previous_year',
}

export enum SubscriptionAction {
  NewOrder = 'new-order-sub',
  OrderConfirmed = 'order-confirmed-sub',
  OrderDeclined = 'order-declined-sub',
}

export enum SubscriptionChannel {
  NewOrder = 'new_order',
  OrderConfirmed = 'order_confirmed',
  OrderDeclined = 'order_declined',
}

export enum ConfirmationMethod {
  CallToRoom = 'call_to_room',
  Sms = 'sms',
  Email = 'email',
  Push = 'push',
}

export enum PageCleanType {
  App = 'app',
  Information = 'information',
  Link = 'link',
  Shop = 'shop',
  Survey = 'survey',
  Weather = 'weather',
}

export enum IframeResizerParentMessage {
  ItemEditCancel = 'itemEditCancel',
  ItemEditSuccess = 'itemEditSuccess',
  ItemCreateCancel = 'itemCreateCancel',
  ItemCreateSuccess = 'itemCreateSuccess',
}

export enum IntegrationType {
  PMS = 'pms',
  POS = 'pos',
  SRS = 'srs',
}

export enum Integration {
  Hotelkit = 'hotelkit',
  Apaleo = 'apaleo',
  Hotsoft = 'hotsoft',
  Stayntouch = 'stayntouch',
  SimphonyGen1 = 'simphony_gen1',
  Suite8 = 'suite8',
  Mews = 'mews',
  Infor = 'infor',
  Sihot = 'sihot',
  Protel = 'protel',
  Guestline = 'guestline',
  Fias = 'fias',
  Asa = 'asa',
}
