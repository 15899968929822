import { Box } from '@mui/material';
import {
  InputProps,
  NumberInput,
  regex,
  required,
  SelectInput,
  TextInput,
  WrapperField,
} from 'react-admin';
import { IntegrationRecord } from '../../../common/types';
import IntegrationInfo from './IntegrationInfo';

const FiasInputs = ({
  integration,
}: Omit<InputProps, 'source'> & { integration: IntegrationRecord }) => (
  <WrapperField>
    <IntegrationInfo integration={integration} />
    <Box width="70%" maxWidth="70%">
      <SelectInput
        source="currentConfig.service.dialect"
        choices={[
          { id: 'oracle_ifc8', name: 'oracle_ifc8' },
          { id: 'protel', name: 'protel' },
        ]}
        label="resources.integrations.fields.fias.dialect.label"
        helperText="resources.integrations.fields.fias.dialect.helperText"
        validate={required(
          'resources.integrations.fields.fias.dialect.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.host"
        label="resources.integrations.fields.fias.host.label"
        helperText="resources.integrations.fields.fias.host.helperText"
        validate={required(
          'resources.integrations.fields.fias.host.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <NumberInput
        source="currentConfig.service.port"
        max={65535}
        min={1}
        label="resources.integrations.fields.fias.port.label"
        helperText="resources.integrations.fields.fias.port.helperText"
        validate={required('resources.integrations.fields.fias.charset.port')}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.charset"
        label="resources.integrations.fields.fias.charset.label"
        helperText="resources.integrations.fields.fias.charset.helperText"
        validate={required(
          'resources.integrations.fields.fias.charset.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.timezone"
        label="resources.integrations.fields.fias.timezone.label"
        helperText="resources.integrations.fields.fias.timezone.helperText"
        validate={required(
          'resources.integrations.fields.fias.timezone.helperText'
        )}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.defaultArrivalTime"
        label="resources.integrations.fields.fias.defaultArrivalTime.label"
        helperText="resources.integrations.fields.fias.defaultArrivalTime.helperText"
        validate={[
          required(
            'resources.integrations.fields.fias.defaultArrivalTime.helperText'
          ),
          regex(
            /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
            'resources.integrations.fields.fias.defaultArrivalTime.helperText'
          ),
        ]}
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="currentConfig.service.defaultDepartureTime"
        label="resources.integrations.fields.fias.defaultDepartureTime.label"
        helperText="resources.integrations.fields.fias.defaultDepartureTime.helperText"
        validate={[
          required(
            'resources.integrations.fields.fias.defaultDepartureTime.helperText'
          ),
          regex(
            /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
            'resources.integrations.fields.fias.defaultDepartureTime.helperText'
          ),
        ]}
        variant="outlined"
        fullWidth
      />
    </Box>
  </WrapperField>
);

export default FiasInputs;
