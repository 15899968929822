import { styled } from '@mui/material/styles';

const ChartListCategoryTitle = styled('div')({
  width: '220px',
  minWidth: '60px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'end',
  whiteSpace: 'nowrap',
  fontSize: '10px',
  color: '#787582',
  fontWeight: 500,
});

export default ChartListCategoryTitle;
